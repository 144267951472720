import React from 'react'

/* The code is defining a functional component called `HeaderButton` in JavaScript React. */
function MCAHeaderButton({ onClick, isSelected, children }) {
    return (
        <button
            className={
                "whitespace-nowrap text-[11pt] leading-[14pt] lg:text-[12px]   rounded-3xl  px-2 py-1.5   block lg:leading-[16px] text-[#FFFFFF] font-sans_medium_body bg-[#BC8B20]"
            }
            onClick={onClick}>
            {children}
        </button>
    )
}

export default MCAHeaderButton