import React, { useState, useMemo, useEffect, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import { RaiseRequestContext } from "../../Contexts/RaiseRequestContext";
import { UserContext } from "../../Contexts/UserContext";
import Paginate from "../Paginate";
import ConfirmPopup from "../../Utils/ConfirmPopup";
import { useSortBy, useTable, usePagination } from "react-table";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import SortIcon from "mdi-react/SortIcon";
import Loader from "../Loader";
import useModule from "../../hooks/useModule";
import Constants from "../../Constants";
import ExcelDownloader from "../shared/ExcelDownloader";
import { directorsExport } from "../../Config/config";
import FormMCSite from "../CFS/DetailsPage/CFSFinancial/FormMCSite";
import HeaderButton from "./FinancialComponents/HeaderButton";
import { X } from "lucide-react";
import MCAHeaderButton from "./FinancialComponents/MCAHeaderButton";
import { TableDataContext } from "../../Contexts/TableDataContext";

const Directors = ({ companyName, data , cin }) => {
    const [directors, setDirectors] = useState([]);
    useEffect(() => {
        if (data?.directorData) {
            setDirectors(data.directorData);
        }
    }, [data]);

    // console.log(data,"ljhkjhkg")
    const [isExport, setIsExport] = useState();
    const [showXlDownload, setshowXlDownload] = useState(false);
    
    const { openRequestPopup } = useContext(RaiseRequestContext);
    const { updatedOn } = useContext(TableDataContext);
    const [isLoading, setIsLoading] = useState(false);
    const { companyId } = useParams();

  
    let companyFundingId = parseInt(companyId);
    const { isTrialExport, getToken, isStudent, isDirectoryUser } = useContext(UserContext);
    const [alert, setalert] = useState({ message: "", show: false });
      const [selected, setSelected] = useState(false);
    const { primaryMasterId } = useModule();

    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" });
    };
  
    const onBlurColumnClick = (cell) => {
        setalert({
            show: true,
            message: Constants.maSubscriptionMsg,
            isAlertOnly: true,
            closeBtnName: "OK",
        });
    };

    const formatDate = (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "-";
    };

    const findMatchingMCARole = (director) => { 
        const directorCIN = cin; 
        if (!director.MCAUserRole || !directorCIN) return null;
    
        return director.MCAUserRole.find(role => 
            role.cin === directorCIN || role.CIN === directorCIN 
        );    
    };
  
    const columns = useMemo(
        () => [
            {
                Header: "Sr.No",
                accessor: (row, index) => index + 1,
                className: "w-[8%] text-center",
                headerClassName: "text-center",
            },
            {
                Header: "DIN/PAN",
                accessor: "din",
                className: "w-[10%] hover:text-[#BC8B20]",
                headerClassName: "",
                Cell: ({ row }) => {
                    return (
                        <NavLink to={`/cfs/company/${companyId}/${row.original.DIN}`} target="_blank">
                            {row.original.DIN || "-"}
                        </NavLink>
                    )
                }
            },
            {
                Header: "Name",
                accessor: "name",
                className: "w-[25%]",
                headerClassName: "",
                Cell: ({ row }) => {
                    const director = row.original;
                    return `${director.FirstName || ''} ${director.MiddleName || ''} ${director.LastName || ''}`.trim() || '-';
                }
            },
            {
                Header: "Designation",
                accessor: "designation",
                className: "w-[15%]",
                headerClassName: "",
                Cell: ({ row }) => {
                    const matchingRole = findMatchingMCARole(row.original);
                    return matchingRole?.designation || "-";
                }
            },
            {
                Header: "Date of Appointment",
                accessor: "dateOfAppointment",
                className: "w-[15%] text-center",
                headerClassName: "text-center",
                Cell: ({ value }) => formatDate(value)
            },
            {
                Header: "Cessation Date",
                accessor: "cessationDate",
                className: "w-[15%] text-center",
                headerClassName: "text-center",
                Cell: ({ row }) => {
                    const matchingRole = findMatchingMCARole(row.original);
                    return formatDate(matchingRole?.cessationDate);
                }
            },
            {
                Header: "Signatory",
                accessor: "signatory",
                className: "w-[12%] text-center",
                headerClassName: "text-center",
                Cell: ({ row }) => {
                    const matchingRole = findMatchingMCARole(row.original);
                    return matchingRole?.signatoryAssociationStatus ? 'Yes' :  "-";
                }
            }
        ],
        [primaryMasterId]
    );
  
    const rowData = useMemo(() => {
        const rows = directors || [];
        setshowXlDownload(rows.length > 0);
        return rows;
    }, [directors]);
  
    const tableInstance = useTable(
        {
            columns,
            data: rowData,
            initialState: {
                pageSize: Constants.noOfRowPerPage,
                pageIndex: 0,
            },
        },
        useSortBy,
        usePagination
    );
  
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize },
    } = tableInstance;

    const NodataComponent = () => {
        return (
            <div className="flex flex-col items-center justify-center h-14">
                <p className="px-5 text-[14px] mb-2">
                    No Directors found for this company.{"  "}
                    <button
                        className="text-[#A5711A] hover:underline"
                        onClick={() => openRequestPopup("director", "director")}
                    >
                        Click Here
                    </button>
                    {" "}to double check with Venture Intelligence on this.
                </p>
            </div>
        );
    };
  
    return (
        <div className="">
        {selected && (
            <div className="fixed inset-0 z-10 ">
            {/* Backdrop with blur effect */}
            <div 
                className="fixed inset-0 bg-black/30 backdrop-blur-sm"
                onClick={() => setSelected(false)}
            />
            
            {/* Modal container with padding for scrolling */}
            <div className="fixed inset-0 flex items-center justify-center p-4">
                {/* Modal content */}
                <div className="relative bg-white rounded-lg overflow-hidden">
                {/* Close button */}
                <div className="absolute top-0 right-0 p-4">
                    <button
                    onClick={() => setSelected(false)}
                    className="rounded-full p-1 hover:bg-gray-100 transition-colors"
                    >
                    <X className="h-5 w-5 text-gray-500" />
                    </button>
                </div>
                
                {/* Form content with scrollable area */}
                <div className="overflow-y-auto max-h-[85vh] mt-6 mr-4">
                    <FormMCSite tab={1} />
                </div>
                </div>
            </div>
            </div>
        )}

        <div className="mx-4">
            <div className="bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="px-5 pt-4">
                    <div className="flex justify-between mb-3 ">
                        <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px]">
                            Directors
                        </h3>
                        <div className="flex items-center justify-between gap-4">
                            {/* <div className="flex items-center space-x-2">
                                <MCAHeaderButton
                                    isSelected={!selected }
                                    onClick={() => setSelected(!selected)}
                                >
                                    From MCA Site
                                </MCAHeaderButton>
                            </div> */}
                            {isTrialExport != "false" &&
                            isStudent == "false" &&
                            showXlDownload && (
                                <span>
                                <ExcelDownloader
                                    url={directorsExport}
                                    fileName={`${companyName}_Directors`}
                                    data={{
                                        "cin": cin,
                                        "is_din":false,
                                    }}
                                />
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                    <div className="space-y-6">
                        <div className="bg-white">
                            <>
                                <div className="overflow-x-auto scrollbar relative">
                                    {rows?.length > 0 ? (
                                        <table className="datatable w-full" {...getTableProps()}>
                                            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-bold py-4 px-0 text-center ">
                                                {headerGroups.map((headerGroup) => (
                                                    <tr
                                                        key={headerGroup.id}
                                                        {...headerGroup.getHeaderGroupProps()}
                                                    >
                                                        {headerGroup.headers.map((column) => (
                                                            <th
                                                                {...column.getHeaderProps([
                                                                    {
                                                                        className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                                        style: column.headerStyle,
                                                                    },
                                                                    column.getSortByToggleProps(),
                                                                ])}
                                                                title=""
                                                            >
                                                                <div className="flex">
                                                                    <span className={`${column.headerspanClassName || ''}`}>
                                                                        {column.render("Header")}
                                                                    </span>
                                                                    {column.disableSortBy ? (
                                                                        ""
                                                                    ) : column.isSorted ? (
                                                                        column.isSortedDesc ? (
                                                                            <SortAscendingIcon
                                                                                size={16}
                                                                                className="mt-[0.15rem] lg:mt-0"
                                                                            />
                                                                        ) : (
                                                                            <SortDescendingIcon
                                                                                size={16}
                                                                                className="mt-[0.15rem] lg:mt-0"
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        <SortIcon
                                                                            size={16}
                                                                            className="mt-[0.15rem] lg:mt-0"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
    
                                            <tbody
                                                {...getTableBodyProps()}
                                                className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left"
                                            >
                                                {page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()} className="border-b">
                                                            {row.cells.map((cell) => {
                                                                return (
                                                                    <td
                                                                        {...cell.getCellProps([
                                                                            {
                                                                                className: `data-td-animate px-5 py-4 ${cell.column.className}`,
                                                                                style: cell.column.style,
                                                                            },
                                                                        ])}
                                                                    >
                                                                        {cell.render("Cell")}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {isLoading ? (
                                    <div className="flex flex-col items-center justify-center h-40">
                                        <Loader />
                                    </div>
                                ) : rows?.length <= 0 ? (
                                    <NodataComponent />
                                ) : (
                                    <></>
                                )}
                                {rows?.length <= Constants.noOfRowPerPage ? (
                                    <></>
                                ) : (
                                    <Paginate
                                        currentPage={pageIndex + 1}
                                        totalCount={rows?.length}
                                        pageSize={pageSize}
                                        onPageChange={(page) => {
                                            gotoPage(page - 1);
                                        }}
                                    />
                                )}
                                {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                            </>
                        </div>
                    </div>

                    <div className="text-xs text-gray-600 m-2 italic">
                        {`* Last Updated on ${new Date(updatedOn).toLocaleString('en-US', {
                            month: 'short',
                            year: 'numeric'
                        })}`}
                    </div>

            </div>
            
        </div>

        
        </div>
    );
};
  
export default Directors;