import { React, useContext, useState, useEffect } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { TableHeaderContext } from "../../Contexts/TableHeaderContext";
import ChargesBasedFilter from "../RefineSearchComponents/ChargeFilter";
import { useLocation, useNavigate } from "react-router";
import { TableDataContext } from '../../Contexts/TableDataContext';
import axios from "axios";
import { filterLocationUrl } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import { toast } from "react-toastify";
import moment from 'moment';
import DefinitionModal from "../TagDefinitionModal";

export default function IOCRefineSearch({ customPathname }) {
    const {
        setSectors,
        onErrorHandler,
        isMobile,
        open,
        setOpen,
        refineSearchFilters,
        setrefineSearchFilters,
        iocPayload,
        setIocPayload 
    } = useContext(PeFilterContext);
    
    const { setCurrentPage } = useContext(TableDataContext);
    const { pathname } = useLocation();

    const [select, setSelected] = useState(0);
    const [isChargeOpen, setIsChargeOpen] = useState(false);
    
    const navigate = useNavigate();
    const { getToken } = useContext(UserContext);
    const location = useLocation();
    
    const isFromGlobalSearch = () => refineSearchFilters[pathname]?.globalSearch?.length > 0;
    const isFromSearchedList = () => refineSearchFilters[customPathname]?.globalSearch?.length > 0;

    // State management
    const [ChargeStateOptions, setChargeStateOptions] = useState([]);
    const [ChargeCityOptions, setChargeCityOptions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [minChargeAmount, setMinChargeAmount] = useState("");
    const [maxChargeAmount, setMaxChargeAmount] = useState("");
    const [ChargeBasedSelected, setChargeBasedSelected] = useState([]);
    const [radioValue, setRadioValue] = useState(iocPayload?.charge_holder_name ? 'charge_holder' : 'company');
    const [showDefinitionModal, setShowDefinitionModal] = useState(false);
    const [defValues, setDefValues] = useState({});

    // Initialize filters from iocPayload
    useEffect(() => {
        if (iocPayload) {
            setRadioValue(iocPayload?.charge_holder_name?.length > 0 ? 'charge_holder' : 'company');
            const initialFilterState = {
                chargeState: iocPayload?.state || [],
                chargeCity: iocPayload?.city || [],
                chargeCompanies: iocPayload?.company || [],
                chargeHolders: radioValue === 'charge_holder' ? iocPayload?.charge_holder_name : [],
                chargeDate: iocPayload?.charge_date?.from_date && iocPayload?.charge_date?.to_date ? [{
                    id: 0,
                    name: `${moment(iocPayload.charge_date.from_date).format('YYYY-MM-DD')} - ${moment(iocPayload.charge_date.to_date).format('YYYY-MM-DD')}`,
                    value: {
                        'from_date': moment(iocPayload.charge_date.from_date).format('YYYY-MM-DD'),
                        'to_date': moment(iocPayload.charge_date.to_date).format('YYYY-MM-DD'),
                    }
                }] : [],
                chargeAmount: iocPayload?.charge_amount?.from_amt && iocPayload?.charge_amount?.to_amt ? [{
                    id: 0,
                    name: `${iocPayload.charge_amount.from_amt} Cr - ${iocPayload.charge_amount.to_amt} Cr`,
                    value: {
                        'from_amt': parseInt(iocPayload.charge_amount.from_amt),
                        'to_amt': parseInt(iocPayload.charge_amount.to_amt)
                    }
                }] : []
            };

            setrefineSearchFilters(prev => ({
                ...prev,
                [customPathname]: initialFilterState
            }));
        }
    }, [iocPayload]);

    // Fetch location data
    useEffect(() => {
        const chargeStateIds = refineSearchFilters[customPathname]?.chargeState?.map(value => value.id) || [];
        const chargeCityIds = refineSearchFilters[customPathname]?.chargeCity?.map(value => value.id) || [];

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],
            type: 5
        }, {
            headers: {
                "Authorization": getToken()
            }
        })
        .then(response => {
            if (response.status === 200) {
                setChargeStateOptions(response.data.iocStates?.map(option => ({
                    ...option,
                    isChecked: chargeStateIds.includes(option.id)
                })) || []);
                setChargeCityOptions(response.data.iocCities?.map(option => ({
                    ...option,
                    isChecked: chargeCityIds.includes(option.id)
                })) || []);
            }
        })
        .catch(onErrorHandler);
    }, [refineSearchFilters]);

    const onChargeStateChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: ids,
            city: [],
            type: 5
        }, {
            headers: {
                "Authorization": getToken()
            }
        })
        .then(response => {
            if (response.status === 200) {
                setChargeCityOptions(response.data?.iocCities || []);
            }
        })
        .catch(onErrorHandler);
    };

    const validateFilters = () => {
        const selectedCharge = ChargeBasedSelected?.map(option => option.name);
        
        if (radioValue && selectedCharge?.length === 0) {
            toast.warning(`Please choose ${radioValue === "company" ? "Company name" : "Charge holder"}`);
            return false;
        }

        if (minChargeAmount !== "" && maxChargeAmount !== "") {
            const minCharge = Number(minChargeAmount);
            const maxCharge = Number(maxChargeAmount);
            
            if (minCharge <= 0) {
                toast.warning('Minimum charge amount must be greater than 0');
                return false;
            }
            
            if (minCharge > maxCharge) {
                toast.warning(`Maximum charge amount must be greater than or equal to ${minCharge}`);
                return false;
            }
        }

        return true;
    };

    const applyFilter = () => {
        const selectedChargeState = ChargeStateOptions?.filter(option => option.isChecked);
        const selectedChargeCity = ChargeCityOptions?.filter(option => option.isChecked);
        
        const isChargeEmpty = minChargeAmount === "" && 
                            maxChargeAmount === "" && 
                            startDate == null && 
                            endDate == null && 
                            radioValue == null && 
                            selectedChargeState.length === 0 && 
                            selectedChargeCity.length === 0;
       
        if (isChargeEmpty) {
            setIsChargeOpen(false);
        }

        const newFilterState = {
            chargeState: selectedChargeState,
            chargeCity: selectedChargeCity,
            chargeCompanies: radioValue === 'company' ? ChargeBasedSelected : [],
            chargeHolders: radioValue === 'charge_holder' ? ChargeBasedSelected : [],
            chargeDate: startDate != null && endDate != null ? [{
                id: 0,
                name: `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`,
                value: {
                    'from_date': startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
                    'to_date': endDate != null ? moment(endDate).format('YYYY-MM-DD') : null,
                }
            }] : [],
            chargeAmount: minChargeAmount !== "" && maxChargeAmount !== "" ? [{
                id: 0,
                name: `${minChargeAmount} Cr - ${maxChargeAmount} Cr`,
                value: {
                    'from_amt': parseInt(minChargeAmount),
                    'to_amt': parseInt(maxChargeAmount)
                }
            }] : []
        };

        // Update both states atomically
        setIocPayload({
            company: radioValue === 'company' ? ChargeBasedSelected : [],
            charge_holder_name: radioValue === 'charge_holder' ? ChargeBasedSelected : [],
            charge_date: newFilterState.chargeDate?.at(0)?.value,
            charge_amount: newFilterState.chargeAmount?.at(0)?.value,
            state: selectedChargeState,
            city: selectedChargeCity,
        });

        setrefineSearchFilters(prev => ({
            ...prev,
            [location.pathname]: newFilterState
        }));
    };

    const refineSearchFilter = (event) => {
        event.preventDefault();
        
        if (isMobile) {
            setOpen(!open);
        }

        if (!validateFilters()) {
            return;
        }

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: { globalSearch: [] }
        }));

        setCurrentPage(1);
        applyFilter();
        setSelected(false);

        if (location.pathname !== customPathname) {
            navigate(customPathname);
        }
    };

    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }));
        setSelected(0);
        setIsChargeOpen(false);
        setIocPayload({  });

        
        if (location.pathname !== customPathname) {
            navigate(customPathname);
        }
    };

    const defCloseHandler = () => {
        setShowDefinitionModal(false);
        setDefValues({});
    };

    return (
        <div className="h-screen">
            <div className={isFromGlobalSearch() ? 
                "flex flex-col overflow-y-auto no-scrollbar h-[80%]" : 
                "flex flex-col overflow-y-auto no-scrollbar lg:h-[80%] xl:h-[80%] 2xl:h-[80%]"
            }>
                <form onSubmit={refineSearchFilter} onReset={onResetClick} className="bg-[#FAF5EA] w-full">
                    <main className="bg-[#fff] w-full">
                        <ul className="transition-max-h overflow-y-auto duration-500 ease-in-out max-h-[1000px] text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]">
                            <ChargesBasedFilter
                                customPathname={customPathname}
                                accordionClick={() => setIsChargeOpen(prev => !prev)}
                                open={isChargeOpen}
                                ChargeStateOptions={ChargeStateOptions}
                                setChargeStateOptions={setChargeStateOptions}
                                ChargeCityOptions={ChargeCityOptions}
                                setChargeCityOptions={setChargeCityOptions}
                                ChargeBasedSelected={ChargeBasedSelected}
                                setChargeBasedSelected={setChargeBasedSelected}
                                minChargeAmount={minChargeAmount}
                                setMinChargeAmount={setMinChargeAmount}
                                maxChargeAmount={maxChargeAmount}
                                setMaxChargeAmount={setMaxChargeAmount}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                radioValue={radioValue}
                                setRadioValue={setRadioValue}
                                onChargeStateChange={onChargeStateChange}
                                menu="ioc"
                            />
                        </ul>
                    </main>
                    <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                        <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                            Reset
                        </button>
                        <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[13px] text-[#FFFFFF] rounded-[3px] font-sans_medium_body leading-[17px]">
                            Filter
                        </button>
                    </div>
                </form>
            </div>
            {showDefinitionModal && <DefinitionModal values={defValues} onClose={defCloseHandler} CFSRefine={true} />}
        </div>
    );
}