import React, { useContext } from "react";
import Loader from "../../Loader";
import linkedin from "../../../Utils/linkedin.png";
import { routeNames } from "../../../routeSegments";
import { TableHeaderContext } from "../../../Contexts/TableHeaderContext";
import { Tooltip } from "antd";

/**
 * The code defines a React functional component called InvestorProfileHighlights that renders a
 * profile highlights section with dynamic data and loading state.
 * @returns The code is returning a JSX element, which represents the structure and content of the
 * InvestorProfileHighlights component.
 */
const InvestorProfileHighlights = ({ data, profile, isLoading }) => {
  const { investorProfileKeys } = useContext(TableHeaderContext);

  const renderBoardInfo = (boardInfo) => {
    if (!boardInfo || boardInfo.length === 0) return null;

    const concatenatedInfo = boardInfo
      .map((info) => `${info.name} (${info.designation})`)
      .join(", ");

    if (concatenatedInfo?.length > 29) {
      return (
        <Tooltip
          color="white"
          title={
            <div style={{ color: "black" }}>
              {boardInfo.map((info, index) => {
                const name = info.name;
                const additionalInfo = [
                  info.designation,
                  info.belongs_to_firm_name,
                ]
                  .filter(Boolean)
                  .join(", ");
                const searchQuery = additionalInfo
                  ? `${name} ${additionalInfo} site:linkedin.com`
                  : `${name} site:linkedin.com`;
                return (
                  <p key={index} className="my-1">
                    <a
                      href={`https://www.google.com/search?q=${encodeURIComponent(
                        searchQuery
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#a07924] hover:text-[#a07924]"
                    >
                      {name}
                    </a>
                    {additionalInfo && `, ${additionalInfo}.`}
                  </p>
                );
              })}
            </div>
          }
          placement="topLeft"
        >
          <a className="text-[#a07924]">{boardInfo[0].name},</a>
          <span>
            {" "}
            {boardInfo[0].designation}
            {concatenatedInfo.length > 23 && "..."}
          </span>
        </Tooltip>
      );
    } else {
      return boardInfo.map((info, index) => {
        const name = info.name;
        const additionalInfo = [info.designation, info.belongs_to_firm_name]
          .filter(Boolean)
          .join(", ");
        const searchQuery = additionalInfo
          ? `${name} ${additionalInfo} site:linkedin.com`
          : `${name} site:linkedin.com`;
        return (
          <p key={index} className="my-1">
            <a
              href={`https://www.google.com/search?q=${encodeURIComponent(
                searchQuery
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#a07924]"
            >
              {name}
            </a>
            {additionalInfo ? ", " + additionalInfo : ""}
          </p>
        );
      });
    }
  };
  
  const getAlreadyInvestedAmount = () => 
    data?.investments_through?.reduce((total, deal) => deal !== null ? total + parseFloat(deal.amount_m || 0) : 0, 0).toFixed(2) || null;
  
  
  const getAlreadyExitedAmount = () => 
    data?.exits_through?.reduce((total, deal) => deal !== null ? total + parseFloat(deal.amount_m || 0) : 0, 0).toFixed(2) || null;

  const getLimitedPartners = (LP) => {
    if (!LP || LP.length === 0) return null;

    return (
      <span>
        {LP.map((partner, index) =>
          partner.id && partner.id !== "" ? (
            <span key={partner.id}>
              <a
                target="_blank"
                href={routeNames.investorsLimitedPartnerById(partner.id)}
                className={"underline text-[#bc8b20]"}
                rel="noopener noreferrer"
              >
                {partner.name}
              </a>
              {index < LP.length - 1 && ", "}
            </span>
          ) : (
            <span key={partner.id} className="cursor-default">
              {partner.name}
              {index < LP.length - 1 && ", "}
            </span>
          )
        )}
      </span>
    );
  };

  return (
    <div>
      <div className="lg:mt-4 px-4 w-[100%]">
        <div className="">
          <div className="lg:bg-white rounded-[6px]" x-show="tab === 1">
            <div className="w-full lg:grid lg:grid-cols-6  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
              <div className=" mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-6 lg:border-r bg-white ">
                <div className="px-5 py-4 ">
                  <p className="flex text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                        Profile 
                        {profile && // Check if Profile exists
                          profile["linkedin"] !== "" ?  // Check if LinkedIn Profile value exists
                          <a href={`${profile["linkedin"]}`} target="_blank" className="mt-[0.20rem] ml-2"><img src={linkedin} width={16} alt="linkedin logo" /></a>
                          : ""
                        }
                    </p>
                  <div className="w-full bg-[#FFFFFF]">
                    {!isLoading ? (
                      <div className="grid grid-cols-2 md:grid-cols-4">
                          {investorProfileKeys?.map((keyObj, index) => {
                            const key = keyObj.accessor;
                            const label = keyObj.label;
                            let valueToRender = null;

                            if (key === "management_info") {
                              const managementInfo = profile[key];
                              if (managementInfo && managementInfo.length > 0) {
                                valueToRender = managementInfo
                                  .map(
                                    (info) =>
                                      `${info.name}, ${info.designation}`
                                  )
                                  .join("; ");
                              } else valueToRender = "--";
                            } else if (key === "board_info") {
                              valueToRender = renderBoardInfo(profile[key]);
                            } else if (key === "dry_powder") {
                              profile.assets_under_mgmt !== "" && getAlreadyInvestedAmount() ? valueToRender = (profile.assets_under_mgmt - getAlreadyInvestedAmount()).toFixed(2) : valueToRender = null;
                            } else if( key === "address"){
                              const add = profile && profile[key]?.trim();
                              if(add !== "" && profile["address2"] !== ""){
                                valueToRender = `${add}, ${profile["address2"]}`;
                              } else if (add !== ""){
                                valueToRender = add;
                              } else valueToRender = null;
                            } else if (key === "already_invested"){
                              valueToRender = getAlreadyInvestedAmount() != 0.00 ? getAlreadyInvestedAmount() : null
                            } else if(key === "limited_partners_fks"){
                              valueToRender = getLimitedPartners(profile[key])
                            } else {
                              valueToRender =
                                profile[key] && profile[key]?.trim() !== "" && profile[key] !== "0.00"
                                  ? profile[key]
                                  : null;
                            }
                            if (valueToRender !== null) {
                              return (
                                <div key={index} className={`profiletable`}>
                                  <>
                                    <div className="leading-[21px] font-sans_book_body mt-1">
                                        <div class=" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem]">
                                            <span
                                            className="py-1 text-[10pt] lg:whitespace-nowrap leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                                            readOnly
                                            >
                                            {label}
                                            </span>
                                        </div>
                                        {label === "Website" ? (
                                            <a
                                            href={valueToRender}
                                            className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold"
                                            target="_blank"
                                            >
                                            {valueToRender}
                                            </a>
                                        ) : (
                                            <p
                                            className="mt-1 mb-2 w-[90%] text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                                            readOnly
                                            >
                                            {valueToRender}
                                            </p>
                                        )}
                                    </div>
                                  </>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </div>
                    ) : (
                      <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center">
                        <Loader />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <!--first half end--> */}

              {/* <!--end--> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorProfileHighlights;
