import React, { useState, useMemo, useEffect, useContext } from "react";
import {  useParams, useLocation, matchPath } from 'react-router-dom';
import axios from 'axios'
import FundingTableInvestment from '../Funding/NewFundingInvestmentTable';
import FundingTableExit from '../Funding/NewExitTable'
import FundingTableAngelInvestment from "../Funding/NewAngelInvestmentTable";
import FundingTableIncubation from "../Funding/NewIncubationTable";
import routePathSegments from "../../routeSegments";
import ExcelDownloader from "../shared/ExcelDownloader";
import { exportExcel, getFundingUrl } from "../../Config/config";
import { FundingContext } from "../../Contexts/FundingDataProvider";
import FundingTableBackedIpo from "../Funding/NewBackedIpoTable"
import Loader from "../Loader";
import { UserContext } from "../../Contexts/UserContext";
import Constants from "../../Constants";
import useModule from "../../hooks/useModule";
import { data } from "jquery";

const Funding = ({menu,companyName, funding}) => {
  const [openTab, setOpenTab] = useState();
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { pevcData, existData, angelData, incubationData, ipoData, setEnableExport, enableExport } = useContext(FundingContext);
  const [xlDownloadRequest, setxlDownloadRequest] = useState({
    "type": 1,
    "export_from": "detail",
    "module_type": "investments",
    "section": "funding",
    "sub_section": "pe_vc_investments",
    "company_id": []
  });
  const [fundingData, setFundingData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {isTrialExport, getToken, isStudent } = useContext(UserContext);

  const { pathname} = useLocation();
  const { companyId } = useParams();
  const {primaryMasterId,isMA} = useModule();
  console.log(funding, "funding")

  const isPeCompanyMatch = matchPath({ path: `${routePathSegments.pe}/*` }, pathname)
  const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, pathname)
  // const isMaCompanyMatch = matchPath({ path: `${routePathSegments.ma}/*` }, pathname)
  const cfsMatch = matchPath({ path: `${routePathSegments.cfs}/*` }, pathname)

  useEffect(() =>{
    if(menu === 'vc'){
      setFundingData({
        pe_investments: funding.pe_investments?.filter(inv => inv.is_vc === true),
        pe_exits: funding.pe_exits?.filter(inv => inv.is_vc === true),
        pe_ipos: funding.pe_ipos?.filter(inv => inv.is_vc === true),
        angel_investments: funding.angel_investments,
        incubation_investments: funding.incubation_investments
      })
    } else if (menu === 'pe' || menu === 'ma'){
      setFundingData({
        pe_investments: funding?.pe_investments,
        pe_exits: funding?.pe_exits,
        pe_ipos: funding?.pe_ipos,
        angel_investments: funding?.angel_investments,
        incubation_investments: funding?.incubation_investments
      }
      )
    }
  }, [])

  useEffect(()=>{
    if(fundingData?.pe_investments?.length >0 ){
      setOpenTab(1)
    }
    else if (fundingData?.pe_exits?.length >0){
      setOpenTab(2)
    }
    else if (fundingData?.pe_vc_ipo?.length >0){
      setOpenTab(3)
    }
    else if (fundingData?.angel_investments?.length >0){
      setOpenTab(4)
    }
    else if (fundingData?.incubation_investments?.length >0){
      setOpenTab(5)
    }
  },[fundingData])


  useEffect(() => {
    let sub_section = "pe_vc_investments";

    if (openTab === 2) {
      sub_section = "exits";
    } else if (openTab === 3) {
      sub_section = "ipos";
    }
    else if (openTab === 4) {
      sub_section = "angel";


    } else if (openTab === 5) {
      sub_section = "incubation";
    }

    if (isVcCompanyMatch != null) {
      setxlDownloadRequest(prev => ({ ...prev, company_id: [companyId], type: Constants.primaryMasterIds.vcId, sub_section: sub_section }))
    } else if (cfsMatch != null) {
      setxlDownloadRequest(prev => ({ ...prev, company_id: [companyId], type: Constants.primaryMasterIds.cfsId, sub_section: sub_section }))
    }
    else {
      setxlDownloadRequest(prev => ({ ...prev, company_id: [companyId], type: Constants.primaryMasterIds.peId, sub_section: sub_section }))
    }

  }, [openTab, companyId])


  useEffect(() => {
    if (openTab === 1) {
      if (pevcData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 2) {
      if (existData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 3) {
      if (ipoData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }
    else if (openTab === 4) {
      if (angelData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    } else if (openTab === 5) {
      if (incubationData != null && enableExport) {
        setshowXlDownload(true);
      } else {
        setshowXlDownload(false);
      }
    }

  }, [openTab, pevcData, existData, incubationData, angelData, ipoData, enableExport])


 /** This code determines the `fileName` based on the conditions provided. It then assigns a value
 to `fileName` based on the value of `openTab` and the `module`. The function returns the final
 value of `fileName`. The dependencies `[cfsMatch, companyName, isVcCompanyMatch, openTab]` are
 provided to ensure that the function is re-evaluated whenever any of these values change. */
  const fileName = useMemo(() => {

    let module = "PE";

    if (isVcCompanyMatch != null) {
      module = "VC";
    } else if (cfsMatch != null) {
      module = "CFS";
    }


    let fileName = Constants.exportNames.funding_investment(companyName, module);


    if (openTab === 2) {
      fileName = Constants.exportNames.funding_exit(companyName, module);
    } else if (openTab === 3) {
      fileName = Constants.exportNames.funding_ipo(companyName, module);
    } else if (openTab === 4) {
      fileName = Constants.exportNames.funding_angel(companyName);
    } else if (openTab === 5) {
      fileName = Constants.exportNames.funding_incubation(companyName);
    }
    return fileName;
  }, [cfsMatch, companyName, isVcCompanyMatch, openTab])


  const allCountZero = !isLoading && fundingData?.pe_investments?.length <=0 && fundingData?.pe_exits?.length <=0 && fundingData?.pe_ipos?.length <=0 && fundingData?.angel_investments?.length <=0 && fundingData?.incubation_investments?.length <=0

  return (
    <>
      <div className="mx-4 ">
        <div className="bg-white border shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] sm:rounded-lg">
          <div className=" pt-4 px-5 ">
            <div className="flex justify-between">
              <p className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">
                Funding
              </p>

              {isTrialExport != "false" &&
                isStudent == "false" &&
                showXlDownload && (
                  <ExcelDownloader
                    fileName={fileName}
                    data={xlDownloadRequest}
                    url={exportExcel}
                  />
                )}
            </div>
          </div>

          {menu == "pe" || menu == "ma" ? (
            <div
              className={`flex flex-row overflow-x-auto space-x-9 scrollbar px-5 scrollbar-remove ${
                allCountZero ? "" : "border-t border-t-[#F1F1F1]"
              }`}
            >
              {fundingData?.pe_investments?.length > 0 && (
                <a
                  className={
                    "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                    (openTab === 1
                      ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                      : " text-[#2B2A29] py-3  ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  PE-VC Investments
                </a>
              )}
              {fundingData?.pe_exits?.length > 0 && (
                <a
                  className={
                    "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                    (openTab === 2
                      ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                      : " text-[#2B2A29] py-3 ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  PE-VC Exits
                </a>
              )}
              {isMA
                ? null
                : fundingData?.pe_ipos?.length > 0 && (
                    <a
                      className={
                        "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                        (openTab === 3
                          ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                          : " text-[#2B2A29] py-3 ")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist"
                    >
                      PE-VC Backed IPOs
                    </a>
                  )}
              {fundingData?.angel_investments?.length > 0 && (
                <a
                  className={
                    "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                    (openTab === 4
                      ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                      : "yellow-500 text-[#2B2A29] py-3  ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(4);
                  }}
                  data-toggle="tab"
                  href="#link4"
                  role="tablist"
                >
                  Angel Investments
                </a>
              )}
              {fundingData?.incubation_investments?.length > 0 && (
                <a
                  className={
                    "whitespace-nowrap  py-3 text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                    (openTab === 5
                      ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                      : "yellow-500 text-[#2B2A29]  py-3  ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(5);
                  }}
                  data-toggle="tab"
                  href="#link5"
                  role="tablist"
                >
                  Incubation
                </a>
              )}
            </div>
          ) : (
            <div
              className={`flex flex-row overflow-x-auto space-x-9 scrollbar px-5 scrollbar-remove ${
                allCountZero ? "" : "border-t border-t-[#F1F1F1]"
              }`}
            >
              {fundingData?.pe_investments?.length > 0 && (
                <a
                  className={
                    "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                    (openTab === 1
                      ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                      : " text-[#2B2A29] py-3  ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  VC Investments
                </a>
              )}
              {fundingData?.pe_exits?.length > 0 && (
                <a
                  className={
                    "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                    (openTab === 2
                      ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                      : " text-[#2B2A29] py-3 ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  VC Exits
                </a>
              )}
              {isMA
                ? null
                : fundingData?.pe_ipos?.length > 0 && (
                    <a
                      className={
                        "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                        (openTab === 3
                          ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                          : " text-[#2B2A29] py-3 ")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist"
                    >
                      VC Backed IPOs
                    </a>
                  )}
              {fundingData?.angel_investments?.length > 0 && (
                <a
                  className={
                    "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                    (openTab === 4
                      ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                      : "yellow-500 text-[#2B2A29] py-3  ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(4);
                  }}
                  data-toggle="tab"
                  href="#link4"
                  role="tablist"
                >
                  Angel Investments
                </a>
              )}
              {fundingData?.incubation_investments?.length > 0 && (
                <a
                  className={
                    "whitespace-nowrap  py-3 text-[12pt]  lg:text-[14px] lg:leading-[15px]  block leading-normal " +
                    (openTab === 5
                      ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                      : "yellow-500 text-[#2B2A29]  py-3  ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(5);
                  }}
                  data-toggle="tab"
                  href="#link5"
                  role="tablist"
                >
                  Incubation
                </a>
              )}
            </div>
          )}

          <div className={isLoading ? "block" : "hidden"}>
            <div className="h-40 flex items-center justify-center">
              <Loader />
            </div>
          </div>
          {allCountZero && (
            <div className="h-[3rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
              No data found
            </div>
          )}

          <div className={isLoading ? "hidden" : "block"}>
            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <FundingTableInvestment
                menu={menu}
                data={fundingData?.pe_investments}
                companyId={companyId}
              />
            </div>
            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
              <FundingTableExit
                menu={menu}
                data={fundingData?.pe_exits}
                companyId={companyId}
              />
            </div>
            <div className={openTab === 3 ? "block" : "hidden"} id="link2">
              <FundingTableBackedIpo
                menu={menu}
                data={fundingData?.pe_ipos}
                companyId={companyId}
              />
            </div>
            <div className={openTab === 4 ? "block" : "hidden"} id="link3">
              <FundingTableAngelInvestment
                menu={menu}
                data={fundingData?.angel_investments}
                companyFundingId={companyId}
              />
            </div>
            <div className={openTab === 5 ? "block" : "hidden"} id="link4">
              <FundingTableIncubation
                menu={menu}
                data={fundingData?.incubation_investments}
                companyFundingId={companyId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Funding