import React, { useState, useMemo, useContext } from "react";
import { Tooltip } from "antd";
import { useTable, useSortBy } from "react-table";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { iocExcelExport } from "../../../Config/config";
import xls from "../../../images/xls/png/Group 47.png";
import { X } from "lucide-react";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import SortIcon from "mdi-react/SortIcon";
import FormMCSite from "../../CFS/DetailsPage/CFSFinancial/FormMCSite";
import MCAHeaderButton from "./MCAHeaderButton";
import { TableDataContext } from "../../../Contexts/TableDataContext";

function Charges({ companyProfileId, fCompanyName }) {
    const [selected, setSelected] = useState(false);
    const { director, updatedOn } = useContext(TableDataContext);

    const parseDate = (dateStr) => {
        if (!dateStr || dateStr === '-') return -1;
        const [month, day, year] = dateStr.split('/');
        return new Date(year, month - 1, day).getTime();
    };

    const formatDate = (dateStr) => {
        if (!dateStr) return '-';
        const [month, day, year] = dateStr.split('/');
        return `${day}/${month}/${year}`;
    };

    const formatAddress = (row) => {
        const parts = [
            row.StreetAddress,
            row.StreetAddress2,
            row.StreetAddress3,
            row.StreetAddress4,
            row.Locality,
            row.City,
            row.State,
            row.PostalCode
        ].filter(Boolean);
        return parts.join(', ');
    };

    const formatAmount = (amount) => {
        const crore = 10000000;
        const inCrore = Number(amount) / crore;
        return inCrore.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const sortedData = useMemo(() => {
        const data = director?.indexChargesData || [];
        return data.sort((a, b) => {
            return parseDate(b.dateOfCreation) - parseDate(a.dateOfCreation);
        });
    }, [director?.indexChargesData]);

    const dataWithIndex = useMemo(() => 
        sortedData.map((item, index) => ({
            ...item,
            srNo: index + 1
        })),
        [sortedData]
    );

    const columns = useMemo(
        () => [
            {
                Header: "Sr.No",
                accessor: "srNo",
                className: "text-center bg-white",
                headerClassName: "bg-[#EDEDED]"
            },
            {
                Header: "SRN",
                accessor: "SRN",
                className: "text-center"
            },
            {
                Header: "Charge ID",
                accessor: "chargeId",
                className: "text-center"
            },
            {
                Header: "Charge Holder Name",
                accessor: row => row?.chName || row?.chargeHolderName,  
                className: "text-center w-[18%]"
            },            
            {
                Header: () => (
                    <div className="flex flex-col items-center">
                        <span>Date of</span>
                        <span>Creation</span>
                    </div>
                ),
                accessor: "dateOfCreation",
                sortType: (rowA, rowB) => {
                    const a = parseDate(rowA.original.dateOfCreation);
                    const b = parseDate(rowB.original.dateOfCreation);
                    return a - b;
                },
                Cell: ({ value }) => formatDate(value),
                className: "text-center"
            },
            {
                Header: () => (
                    <div className="flex flex-col items-center">
                        <span>Date of</span>
                        <span>Modification</span>
                    </div>
                ),
                accessor: "dateOfModification",
                sortType: (rowA, rowB) => {
                    const a = parseDate(rowA.original.dateOfModification);
                    const b = parseDate(rowB.original.dateOfModification);
                    return a - b;
                },
                Cell: ({ value }) => formatDate(value),
                className: "text-center"
            },
            {
                Header: () => (
                    <div className="flex flex-col items-center">
                        <span>Date of</span>
                        <span>Satisfaction</span>
                    </div>
                ),
                accessor: "dateOfSatisfaction",
                sortType: (rowA, rowB) => {
                    const a = parseDate(rowA.original.dateOfSatisfaction);
                    const b = parseDate(rowB.original.dateOfSatisfaction);
                    return a - b;
                },
                Cell: ({ value }) => formatDate(value),
                className: "text-center"
            },
            {
                Header: () => (
                    <div className="flex flex-col items-center">
                        <span>Amount</span>
                        <span>( INR ₹Cr )</span>
                    </div>
                ),
                accessor: "amount",
                sortType: "number",
                Cell: ({ value }) => formatAmount(value),
                className: "text-center"
            },
            {
                Header: "Address",
                accessor: row => formatAddress(row),
                Cell: ({ row }) => {
                    const address = formatAddress(row.original);
                    return (
                        <Tooltip 
                            title={
                                <div style={{ whiteSpace: 'pre-line' }}>
                                    {address}
                                </div>
                            }
                            placement="topRight"
                            color="white"
                            overlayInnerStyle={{
                                color: '#333333',
                                fontWeight: '500',
                                maxWidth: '500px'
                            }}
                            zIndex={99}
                        >
                            <div className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
                                {address}
                            </div>
                        </Tooltip>
                    );
                },
                className: "text-center"
            },
            {
                Header: () => (
                    <Tooltip
                        title="Whether Charge Registered by Other Entity"
                        placement="top"
                        color="white"
                        overlayInnerStyle={{
                            color: '#333333',
                            fontWeight: '500',
                        }}
                    >
                        <div className="max-w-[110px] text-center">
                            <div className="flex flex-col items-center leading-tight">
                                <span className="block w-full text-ellipsis overflow-hidden whitespace-nowrap">
                                    Whether Charge
                                </span>
                                <span className="block w-full text-ellipsis overflow-hidden whitespace-nowrap">
                                    Registered by Other Entity
                                </span>
                            </div>
                        </div>
                    </Tooltip>
                ),
                accessor: "propertyIntUnRegdFlag",
                Cell: ({ value }) => (
                    <Tooltip
                        title={value === "Y" ? "Yes - Registered by Other Entity" : "No - Not Registered by Other Entity"}
                        placement="topRight"
                        color="white"
                        overlayInnerStyle={{
                            color: '#333333',
                            fontWeight: '500',
                            maxWidth: '300px'
                        }}
                    >
                        <div className="max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                            {value === "Y" ? 'Yes' : 'No'}
                        </div>
                    </Tooltip>
                ),
                className: "text-center"
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data: dataWithIndex,
            initialState: {
                sortBy: [{ id: 'dateOfCreation', desc: true }]
            }
        },
        useSortBy
    );

    return (
        <div  className="relative">
            {selected && (
                <div className="fixed inset-0 z-10">
                    <div 
                        className="fixed inset-0 bg-black/30 backdrop-blur-sm"
                        onClick={() => setSelected(false)}
                    />
                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <div className="relative bg-white rounded-lg overflow-hidden">
                            <div className="absolute top-0 right-0 p-4">
                                <button
                                    onClick={() => setSelected(false)}
                                    className="rounded-full p-1 hover:bg-gray-100 transition-colors"
                                >
                                    <X className="h-5 w-5 text-gray-500" />
                                </button>
                            </div>
                            <div className="overflow-y-auto max-h-[85vh] mt-6 mr-4">
                                <FormMCSite tab={1} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex items-center justify-end px-3 md:px-5 py-4 gap-4 mr-2">
                {/* <div>
                    <MCAHeaderButton
                        isSelected={!selected}
                        onClick={() => setSelected(!selected)}
                    >
                        From MCA Site
                    </MCAHeaderButton>
                </div> */}
                <span>
                    <ExcelDownloader
                        fileName={fCompanyName}
                        data={{
                            inv_deal_columns: ["ALL"],
                            companyIds: [companyProfileId],
                            is_detail_page: true,
                        }}
                        url={iocExcelExport}
                    >
                        <img
                            src={xls}
                            className="h-[26px] mt-1 w-[24px] cursor-pointer"
                            alt="Export"
                        />
                    </ExcelDownloader>
                </span>
            </div>

            <div className="overflow-x-auto">
                <table className="w-full" {...getTableProps()}>
                    <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] font-bold text-[#666666] py-4 px-0">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="whitespace-nowrap px-5 py-3.5 text-center"
                                    >
                                        <div className="flex items-center justify-center">
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                    ) : (
                                                        <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                    )
                                                ) : (
                                                    <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="text-[12pt] leading-[15pt] lg:text-[12px] lg:leading-[15px] text-[#666666] py-4 px-0">
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="border-b font-sans_book_body text-[#333333] leading-[24px] text-[14px]">
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className={`md:whitespace-nowrap font-sans_book_body px-5 py-4 ${cell.column.className || ''}`}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {!director?.indexChargesData?.length && (
                    <div className="h-28 w-full">
                        <div className="w-full h-full flex items-center justify-center">
                            <p className="px-5 text-[14px]">
                                No charges data found.
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <div className="text-xs text-gray-600 m-2 italic">
                {`* Last Updated on ${new Date(updatedOn).toLocaleString('en-US', {
                    month: 'short',
                    year: 'numeric'
                })}`}
            </div>

        </div>
    );
}

export default Charges;