import React, { useContext, useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { UserContext } from '../../Contexts/UserContext';
import { chargeBasedSearchApi } from '../../Config/config';

const { Option } = Select;

const TagRender = ({ label, closable, onClose }) => (
  <div className="mx-1 my-1 text-[12px] flex items-stretch justify-between font-bold px-1 py-1 rounded-sm bg-slate-100 text-black border">
    <div>{label}</div>
    {closable && (
      <div onClick={onClose} className='px-1 flex items-center justify-center cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-3 h-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    )}
  </div>
);

const Tags = ({ ChargeBasedSelected, customPathname, setChargeBasedSelected, placeholder, category }) => {
  const { getToken } = useContext(UserContext);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const firstRender = useRef(false);
  const { refineSearchFilters } = useContext(PeFilterContext);
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    setOptions([]);
  }, [category]);

  useEffect(() => {
    let sub;
    if (firstRender.current) {
      sub = setTimeout(() => {
        if (searchText !== "" && searchText?.length > 2) {
          setOptions([]);
          setFetching(true);
          getSearchTags(searchText).then(newOptions => {
            setOptions(newOptions);
            setFetching(false);
          });
        } else {
          setOptions([]);
        }
      }, 500);
    } else {
      firstRender.current = true;
      setOptions(ChargeBasedSelected);
    }
    return () => clearTimeout(sub);
  }, [searchText]);

  const handleChange = (selectedValues, selectedOptions) => {
    if (selectedValues.includes('Select All')) {
      const allOptionsExceptSelectAll = options.map(option => ({
        id: option.id,
        name: option.name
      }));
      setChargeBasedSelected(allOptionsExceptSelectAll);
    } else {
      const selectedItems = selectedOptions.map(option => ({
        id: option.key,
        name: option.value
      }));
      setChargeBasedSelected(selectedItems);
    }

    setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.focus();
      }
    }, 0);
  };

  const getSearchTags = async (searchText) => {
    try {
      const response = await axios.post(chargeBasedSearchApi, {
        "search_key": searchText,
        "category": category,
        "type": 5
      }, {
        headers: {
          "Authorization": getToken()
        }
      });

      if (response.status === 200) {
        return response.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  const clearOptions = () => {
    if (!open) {
      setOptions([]);
      setSearchText("");
    }
  };

  const menuItems = () => {
    const items = [...options];
    if (items.length > 1) {
      items.unshift({
        id: 'select-all',
        name: 'Select All',
        value: 'Select All'
      });
    }
    return items;
  };

  return (
    <Select
      ref={selectRef}
      mode="multiple"
      filterOption={false}
      showSearch
      style={{
        width: '100%',
        maxHeight:'30vh',
        overflowY: "scroll"
      }}
      className="text-[#333333]"
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      placeholder={`Search by ${placeholder}...`}
      bordered={false}
      tagRender={TagRender}
      onSearch={(value) => setSearchText(value)}
      onChange={handleChange}
      value={ChargeBasedSelected?.map(option => option.name)}
      onBlur={clearOptions}
      dropdownStyle={{ position: 'fixed' }}
      listHeight={256}
      menuItemSelectedIcon={null}
      notFoundContent={searchText === "" || searchText?.length < 3 ? null : fetching ? 
        <p className='text-[#333333] text-[13px]'>Searching...</p> : 
        <p className='text-[#333333] text-[13px]'>No data found</p>}
    >
      {menuItems().map(option => (
        <Option key={option.id} value={option.name}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};

export default Tags;

