import { Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import compare from '../../../images/compare.png';
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom'
import axios from 'axios';
import Loader from '../../Loader';
import AlertPopUp from '../../DetailPage/AlertPopUp';
import PENotesPopup from '../../NotesPopup/PENotesPopup'
import AddNote from "../../../Pages/addNote";
import AddNotePopover from '../../shared/AddNotePopover';
import SendDealPopUp from '../../SendDealPopUp';
import PdfDownloader from '../../shared/PdfDownloader';
import NewLogo from '../../NewLogo';
import Constants from '../../../Constants';
import { CompareCompanyContext } from '../../../Contexts/CompareCompanyContext';
import { UserContext } from '../../../Contexts/UserContext';
import useModule from '../../../hooks/useModule';
import { routeNames } from '../../../routeSegments';
import { exportPdf, getInvestorCompanyDetails } from '../../../Config/config';
import InvestorPortfolioCompanies from './NewInvPortfolioCompanies';

const InvPortfolioCompanies = () => {
    const { setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);
    const { isStudent, isDirectoryUser } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    const [showAdd, setShowAdd] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState({});
    const [investmentPortfolio, setInvestmentPortfolio] = useState([])
    const location = useLocation();
    const { investorId } = useParams();
    const { primaryMasterId ,isPE,isVC} = useModule();

    const [URLSearchParams] = useSearchParams({ section: 1 })
    const section = URLSearchParams.get('section');

    const subTypeMasterId =() =>{
        if(isPE){
            if(section == Constants.primaryMasterSubIds.pe_vc_investments_id){
                return Constants.subCategoryIds.pe_investor_investments_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_exits_id){
                return Constants.subCategoryIds.pe_investor_exits_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_ma_id){
                return Constants.subCategoryIds.pe_investor_exitsma_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_pm_id){
                return Constants.subCategoryIds.pe_investor_exitspm_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id){
                return Constants.subCategoryIds.pe_investor_backed_ipos_id
              }
            
        }
        else if(isVC){
            if(section == Constants.primaryMasterSubIds.pe_vc_investments_id){
                return Constants.subCategoryIds.vc_investor_investments_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_exits_id){
                return Constants.subCategoryIds.vc_investor_exits_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_ma_id){
                return Constants.subCategoryIds.vc_investor_exitsma_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_pm_id){
                return Constants.subCategoryIds.vc_investor_exitspm_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id){
                return Constants.subCategoryIds.vc_investor_backed_ipos_id
            }            

        }
    }

    const getInvestorData = () => {
        setIsLoading(true);
        axios.get(`${getInvestorCompanyDetails}${investorId}/`).then(response => {
            if (response.status == 200) {
                setProfile(response.data?.profile)
                setInvestmentPortfolio(response.data?.investments_through);
                
                setIsLoading(false);
            }
        }).catch(error => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getInvestorData()
    }, [investorId])
    document.title = `${profile?.name ? profile.name + " - " : "" }PE-VC Investors - Venture Intelligence`;

  return (
    <div>
        {isLoading ?
            <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center">
                <Loader />
            </div>
            :
            <>
            
        <div className='bg-[#FFFFFF] relative'>
            <nav className="flex p-1 lg:px-1 px-3 border-b  " aria-label="Breadcrumb">
                <ol className="flex items-center space-x-1">
                    <li className='flex items-center'>
                        <div className='flex items-center'>
                            <NavLink to={location.state?.prevPath != null
                                ? location.state?.prevPath
                                : primaryMasterId == Constants.primaryMasterIds.vcId ? routeNames.vc_investors_vc_investments
                                    : routeNames.pe_investors_pe_vc_investments}
                                className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] ">
                                Investor</NavLink>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <span className=" text-[12px] text-[#666666] mr-1">/</span>
                            <span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                aria-current="page">{profile?.name}</span>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <span className=" text-[12px] text-[#666666] mr-1">/</span>
                            <span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                aria-current="page">Portfolio Companies</span>
                        </div>
                    </li>
                </ol>
            </nav>
            <div className="bg-[#FFFFFF] profile-detail">
                <div className="pl-5 pr-3">
                    <div className="lg:hidden mt-3  grid  md:grid-cols-8 grid-cols-10  items-center mb-4">
                        <div className="md:col-span-6 col-span-5" title="Compare Company">
                            <button
                                className={
                                    showModal
                                        ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                        : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                }
                                type="button"
                                onClick={() => {
                                    setShowModal(!showModal)
                                    setCompanyDetail([])
                                    setCurrentPage("investor")
                                }
                                }
                            >
                                <img
                                    className={
                                        showModal ? "pr-1 brightness-0 invert-[1]" : "pr-1"
                                    }
                                    src={compare}
                                    alt=""
                                />
                                <span>Compare</span>
                            </button>
                        </div>
                        <div className="flex md:col-span-2 col-span-5 space-x-3">
                            <div >
                                <AlertPopUp cid={investorId} type_id={2} />
                            </div>
                            <div>
                                <AddNotePopover
                                    title="Save List"
                                    companyId={investorId}
                                    companyName={profile?.investor_name ?? ""}
                                    type_id={Constants.moduleIds.investorsId}
                                    sub_type_master_id={subTypeMasterId()}
                                    IsDetailed={true}
                                />
                            </div>


                            <div className="col-span-1">
                                <PENotesPopup cid={investorId} type_id={2} />
                            </div>
                            <div className="col-span-1">
                                <SendDealPopUp data={profile?.investor_name ?? ""} />
                            </div>
                            {isStudent == 'true' ? <></> :
                                <div className="col-span-1">
                                    <PdfDownloader
                                        url={exportPdf}
                                        fileName={profile?.investor_name?.replaceAll(/\s/g, '')}
                                        data={{
                                            "type_id": 2,
                                            "entity_id": investorId,
                                            "category": "investments",
                                            "type": primaryMasterId
                                        }} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="pb-4 pt-6 bg-[#FFFFFF]">
                        <ul >
                            <li className="w-full flex ">
                                <div className=" rounded-md">
                                    <NewLogo
                                        name={profile.name}
                                        id={investorId}
                                        menu="investor"
                                        page="detail page"
                                    />
                                </div>

                                <div className="ml-3 flex flex-1 flex-col">
                                    <span className="text-[#333333] text-[22px] font-semibold  leading-[24px]" href="#"> {profile?.name} </span>
                                    <span className="text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body  ">{profile?.firm_type ? profile?.firm_type : ""}</span>
                                </div>

                                <div className="hidden lg:grid grid-cols-8 gap-2  h-10">
                                    <div className="col-span-3 mt-2">
                                        <Tooltip title="Compare Investor">
                                            <button

                                                className={
                                                    showModal
                                                        ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                                                        : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                                                }
                                                type="button"
                                                onClick={() => {
                                                    setShowModal(!showModal)
                                                    setCompanyDetail([])
                                                    setCurrentPage("investor")
                                                }
                                                }
                                            >
                                                <img
                                                    className={
                                                        showModal
                                                            ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                                            : "w-[20px] h-[18px] pr-1 blur-btn"
                                                    }
                                                    src={compare}
                                                    alt=""
                                                />
                                                <span>Compare</span>
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className="col-span-5 mt-[5px]">
                                        <div className="grid grid-cols-5 gap-1 flex items-center">
                                            <div className="col-span-1 ">
                                                <AlertPopUp cid={investorId} type_id={Constants.moduleIds.investorsId} page="detailpage" />{" "}
                                            </div>

                                            <div className="col-span-1">
                                                <AddNotePopover
                                                    title="Save List"
                                                    companyId={investorId}
                                                    companyName={profile?.investor_name ?? ""}
                                                    type_id={Constants.moduleIds.investorsId}
                                                    sub_type_master_id={subTypeMasterId()}
                                                    IsDetailed={true} 
                                                />
                                            </div>
                                            <div className="col-span-1 mt-1">
                                                <PENotesPopup cid={investorId} type_id={Constants.moduleIds.investorsId} />
                                            </div>
                                            <div className="col-span-1">
                                                <SendDealPopUp data={profile?.investor_name ?? ""} />
                                            </div>
                                            {isStudent == 'true' ? <></> :
                                                <div className="col-span-1">
                                                    <PdfDownloader
                                                        url={exportPdf}
                                                        fileName={profile?.name?.replaceAll(/\s/g, '')}
                                                        data={{
                                                            "type_id": 2,
                                                            "entity_id": investorId,
                                                            "category": "investments",
                                                            "type": primaryMasterId
                                                        }} 
                                                    />
                                                </div>
                                            }

                                        </div>
                                    </div>

                                </div>
                            </li>
                            {showAdd ? <AddNote id={1} /> : ""}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <InvestorPortfolioCompanies isLoading={isLoading} portfolio={investmentPortfolio} investorName={profile.name} show={"All"}/>
        </div>
        </>
        }
    </div>
  )
}

export default InvPortfolioCompanies