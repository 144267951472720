import React from 'react'
import sorting from '../../images/up-down-arrow.png';

/** The above code is a React component called ShareHolding. It renders a table displaying the
shareholding pattern of a company. The component receives data as props and uses that data to
populate the table rows. If there is no data available, it displays a "No Data Available" message. */
const ShareHolding = (props) => {
    console.log(props,"shareholdingcomponent");
    return (
 
        <div
            className="lg:grid lg:grid-rows-8 gap-6">
                    <div className="w-full lg:grid lg:grid-rows-8 lg:grid-flow-col overflow-x-auto scrollbar ">
                        <div
                            className="row-span-3">
                            <div className=" ">
                                    {props?.data?
                                    props?.data?.length>0 ? 
                                <table
                                    className=" w-full mx-auto mt-2 flex-shrink-0">
                                    <thead
                                        className="px-5 text-[12pt] leading-[15pt] bg-[#F3F3F3] grid grid-cols-4 md:grid-cols-5 lg:text-[12px] lg:leading-[15px] text-[#666666] font-sans_semi_bold text-center whitespace-nowrap">
                                        <th
                                            className=" col-span-3 md:col-span-4 py-2 flex  text-left whitespace-nowrap">
                                            Stakeholder
                                            {/* <img className='h-[15px] pl-1 pt-1 ' src={sorting} /> */}

                                        </th>
                                        <th
                                            className=" col-span-1 whitespace-nowrap py-2 px-3 ">
                                            <p className='flex'>
                                                Stake (%)
                                                {/* <img className='h-[15px] pl-1 pt-1 ' src={sorting} /> */}
                                            </p>
                                        </th>

                                    </thead>
                                    <tbody
                                        className=" text-[12pt] leading-[16pt] lg:text-[14px] lg:leading-[16px]  text-[#333333]   ">

                                    <tr
                                            className="grid grid-cols-10 border-b ">
                                            <td
                                                className="font-semibold px-7 font-sans_book_body col-span-2  py-4 ">
                                                Investor
                                            </td>
                                            <td
                                                className=" col-span-3 px-5 lg:px-3 font-sans_book_body py-4 ">
                                                    
                                                {/* {data?.investor_name==0?"--":data?.investor_name?data?.investor_name:"--"} */}
                                            </td>
                                            <td
                                                className=" col-span-3 py-4 ">
                                            
                                            </td>
                                            <td
                                                className="   col-span-2 font-sans_book_body  py-4 px-6 ">
                                                    {props?.data[0]?.shareholding_top?.pe_shp_investor_total ? props?.data[0]?.shareholding_top?.pe_shp_investor_total:"--"}
                                            
                                            </td>

                                        </tr>

                                {props?.data[0]?.shareholding_investor?.length>0?
                                props?.data[0]?.shareholding_investor?.map((data, i) => {
                                                return( 
        
                                        <tr
                                            className="grid grid-cols-10 border-b">
                                            <td
                                                className=" px-7 font-sans_book_body col-span-2  py-4 ">
                                                
                                            </td>
                                            <td
                                                className=" col-span-3  font-sans_book_body py-4 ">
                                                    
                                                {data?.investor_name?data?.investor_name:"--"}
                                            </td>
                                            <td
                                                className=" col-span-3 py-4 ">

                                            </td>
                                            <td
                                                className="  col-span-2 font-sans_book_body py-4  ">
                                                { data?.stake_held ? data?.stake_held:"--"}
                                            </td>

                                        </tr>
                                        )
                                    })
                                : ""
                                }  
                                        
                                        <tr
                                            className="grid grid-cols-10 border-b ">
                                            <td
                                                className="font-semibold px-7 font-sans_book_body col-span-2  py-4 ">
                                                Promoter
                                            </td>
                                            <td
                                                className=" col-span-3 px-5 lg:px-3 font-sans_book_body py-4 ">
                                                    
                                            </td>
                                            <td
                                                className=" col-span-3 py-4 ">
                                            
                                            </td>
                                            <td
                                                className="  col-span-2 font-sans_book_body  py-4 px-6    ">
                                            
                                            {props?.data[0]?.shareholding_top?.pe_shp_promoters_total ? props?.data[0]?.shareholding_top?.pe_shp_promoters_total:"--"}
                                            </td>

                                        </tr>
                                    
                                
                                        {props?.data[0]?.shareholding_promoter?.length>0?
                                            props?.data[0]?.shareholding_promoter?.map((data, i) => {
                                                return( 
                                        <tr
                                            className="grid grid-cols-10 border-b ">
                                            <td
                                                className=" px-0 lg:px-3 font-sans_book_body col-span-2  py-4 ">
                                                
                                            </td>
                                            <td
                                                className=" col-span-3  font-sans_book_body py-4 ">
                                                    
                                                {data?.promoters_name?data?.promoters_name:"--"}
                                            </td>
                                            <td
                                                className=" col-span-3 py-4 ">
                                            
                                            </td>
                                            <td
                                                className="  col-span-2 font-sans_book_body  py-4  ">
                                                {data?.stake_held?data?.stake_held:"--"}
                                            </td>

                                        </tr>
                                        )
                                    })
                                : " "
                                //   <tr
                                //             className="grid grid-cols-10 border-b ">
                                //             <td
                                //                 className=" px-0 lg:px-3 font-sans_book_body col-span-2  py-4 ">
                                                
                                //             </td>
                                //             <td
                                //                 className=" col-span-3 px-5 lg:px-3 font-sans_book_body py-4 ">
                                //              </td>
                                //             <td
                                //                 className=" col-span-3 py-4 ">
                                                
                                //             </td>
                                //             <td
                                //                 className=" px-6 col-span-2 font-sans_book_body  py-4  ">
                                                

                                //              </td>

                                //         </tr>
                                
                            
                                }

                            {/* // investor */}
                                
        
                                        
                                        <tr
                                            className="grid grid-cols-10 border-b">
                                            <td
                                                className="font-semibold px-7  col-span-2 font-sans_book_body py-4 ">
                                                ESOP
                                            </td>
                                            <td
                                                className=" col-span-3  py-4 ">

                                            </td>
                                            <td
                                                className=" col-span-3 py-4 ">

                                            </td>
                                            <td
                                                className="   col-span-2 font-sans_book_body  py-4 px-6   ">
                                            {props?.data[0]?.shareholding_top?.ESOP ? props?.data[0]?.shareholding_top?.ESOP:"--"}
                                            </td>

                                        </tr>
                                        

                                <tr
                                            className="grid grid-cols-10 border-b ">
                                            <td
                                                className="font-semibold px-7 font-sans_book_body col-span-2  py-4 ">
                                                Others
                                            </td>
                                            <td
                                                className=" col-span-3 px-5 lg:px-3 font-sans_book_body py-4 ">
                                                    
                                                {/* {data?.investor_name==0?"--":data?.investor_name?data?.investor_name:"--"} */}
                                            </td>
                                            <td
                                                className=" col-span-3 py-4 ">
                                            
                                            </td>
                                            <td
                                                className="   col-span-2 font-sans_book_body  py-4 px-6   ">
                                            {props?.data[0]?.shareholding_top?.Others ? props?.data[0]?.shareholding_top?.Others:"--"}
                                            
                                            </td>

                                        </tr>

                                
                                        {props?.data[0]?.shareholding_other?.length>0?
                                            props?.data[0]?.shareholding_other?.map((data, i) => {
                                                return( 
        
                                        <tr
                                            className="grid grid-cols-10 border-b">
                                            <td
                                                className=" px-0 lg:px-3 font-sans_book_body col-span-2  py-4 ">
                                                
                                            </td>
                                            <td
                                                className=" col-span-3  font-sans_book_body py-4 ">
                                                    
                                                {data?.others_name?data?.others_name:"--"}
                                            </td>
                                            <td
                                                className=" col-span-3 py-4 ">

                                            </td>
                                            <td
                                                className="  col-span-2 font-sans_book_body py-4  ">
                                                {data?.stake_held? data?.stake_held:"--"}
                                            </td>

                                        </tr>
                                        )
                                    })
                                : ""
                                //   <tr
                                //             className="grid grid-cols-10 border-b">
                                //             <td
                                //                 className=" px-0 lg:px-3 font-sans_book_body col-span-2  py-4 ">
                                                
                                //             </td>
                                //             <td
                                //                 className=" col-span-3 px-5 lg:px-3 font-sans_book_body py-4 ">
                                                    
                                //              </td>
                                //             <td
                                //                 className=" col-span-3 py-4 ">

                                //             </td>
                                //             <td
                                //                 className=" px-6 col-span-2 font-sans_book_body py-4  ">
        
                                //              </td>

                                //         </tr>
                                }  
                                                            
                                        

                                </tbody>
                                
                                </table>
                                : 
                                <center><div className="p-3 h-[2.5rem] text-[14px] leading-[16px] font-sans_book_body text-[#333333] relative my-[1rem] ">No Data Available</div></center>
                                
                                    :
                                    <div className="w-full bg-[#FFFFFF]">
                                    <center><div role="status" className=" h-[8rem]">
                                    <svg className="relative top-[4rem] left-[1rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg> 
                                    </div></center>
                                    </div>
                                    }

                            </div>
                        </div>

                    </div>
        </div>

    )
}

export default ShareHolding