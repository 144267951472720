import { useState, useMemo, useEffect, useCallback, useContext, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { sortRows, filterRows, paginateRows } from '../helpers'
import Pagination from '../Pagination'
import CompanyLogo from '../CompanyLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../TableView.css'
import { routeNames } from '../../routeSegments';
import RandomLogo from '../RandomLogo';
import OverflowToolTip from '../shared/OverflowToolTip';
import { rowsPerPageData, getIocCompaniesApi } from '../../Config/config'
import { TableDataContext } from '../../Contexts/TableDataContext'
import axios from "axios";
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';
import { PeFilterContext } from '../../Contexts/PeFilterContext';

const Table = ({ columns, orderBy }) => {
  const { 
    rows, setRows, 
    data, setData, 
    isLoad, setIsLoad, 
    isDataLoad, setIsDataLoad, 
    totalNoOfCompanies, setTotalNoOfCompanies, 
    currentPage, setCurrentPage, 
    setCurrentFilter, 
    globalSearchResult 
  } = useContext(TableDataContext);
  
  const { refineSearchFilters, groupId, iocPayload, setIocPayload } = useContext(PeFilterContext);
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: 'asc', orderBy: orderBy })
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  const location = useLocation();

  const transformCompaniesData = (companiesObject) => {
    // Convert companies object to array
    return Object.values(companiesObject);
  };

  const getRequestPayload = (page, column, order) => {
    return {
      "order_by": {
        "column": column,
        "direction": order
      },
      charges:
          iocPayload.company?.length ||
          iocPayload.charge_holder_name?.length ||
          iocPayload.charge_date ||
          iocPayload.charge_amount ||
          iocPayload.state?.length ||
          iocPayload.city?.length
            ? {
              company: iocPayload.company?.map(company => company.id),
              charge_holder_name: iocPayload.charge_holder_name?.map(holder => parseInt(holder.id, 10)),
              charge_date: iocPayload.charge_date,
              charge_amount: iocPayload.charge_amount,
              state: iocPayload.state?.map(item => item.id) || [],
              city: iocPayload.city?.map(item => item.id) || [],
              order_by: {
                column: "company",
                direction: order,
              },
              }
      : {},
      "currency": "INR",
      "page": page,
      "from": null,
      "industry": [],
      "sector": [],
      "company_type": [],
      "transaction_status": [2, 1],
      "region": [],
      "state": [],
      "city": [],
      "auditor_name": [],
      "result_type": "Both",
      "financials": null,
      "ratios": null,
      "growth": null,
      "tag": {
        "operator": 0,
        "data": []
      },
      "group": {
        "group_id": null,
        "sub_type_master_id": null
      }
    };
  };

  const tableRender = useCallback((page, column, order) => {
    const payload = getRequestPayload(page, column, order);
    
    axios(getIocCompaniesApi, {
      method: "POST",
      signal: abortRef.current.signal,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.message === "Success") {
          setCurrentFilter(payload);
          setTotalNoOfCompanies(response?.data?.num_companies);
          setIsLoad(false);
          setIsDataLoad(false);
          
          // Transform companies data from object to array
          const companiesArray = transformCompaniesData(response?.data?.companies || {});
          setRows(companiesArray);
          setData(response?.data);
        }
      })
      .catch((error) => {
         if (!axios.isCancel(error)) {
          console.error("API error:", error);
          setIsLoad(false);
          setIsDataLoad(false);
        }
      });
  }, [iocPayload, getToken, logout, setCurrentFilter, setTotalNoOfCompanies, setRows, setData, refineSearchFilters]);

  const findActivePage = (page) => {
    setCurrentPage(page);
    setIsLoad(true);
    tableRender(page, sort.orderBy, sort.order);
  };

  useEffect(() => {
    const abortController = new AbortController();
    abortRef.current = abortController;
    
    setIsLoad(true);
    setIsDataLoad(true);
    tableRender(currentPage, sort.orderBy, sort.order);

    return () => {
      abortController.abort();
    };
  }, [tableRender, currentPage]);

  const handleSort = (accessor) => {
    setRows([]);
    setIsLoad(true);
    const newOrder = sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ order: newOrder, orderBy: accessor });
    tableRender(currentPage, accessor, newOrder);
  };

  const formatChargeHolders = (holders) => {
    if (!holders || !Array.isArray(holders)) {
      return '--';
    }
    return holders.map(holder => holder?.name || '').filter(name => name).join(', ') || '--';
  };

  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar">
            <div className="overflow-x-auto scrollbar rounded-b-[6px]">
              <table className="w-full p-1 bg-grey-50 divide-y">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[16px] font-semibold text-[#666666]">
                  <tr>
                    {columns.map((column) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />;
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />;
                        }
                        return <SortIcon className="pl-2 block h-[15px]" />;
                      };
                      return (
                        <th className="whitespace-nowrap py-2 px-5" key={column.accessor}>
                          <span className="flex">
                            <a>{column.label}</a>
                            {column.label === 'Company' && 
                              <button onClick={() => handleSort(column.accessor)}>
                                {sortIcon()}
                              </button>
                            }
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {!isLoad && rows && rows.length > 0 && (
                  <tbody className="text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                    {rows.map((row, i) => (
                      <tr key={i} className="group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF] border-b bg-white">
                        <td className="leading-[2rem] px-5 py-3 font-sans_book_body card-animate">
                          <div className="flex items-start lg:items-center">
                            {row?.icon_path === "default.png" || 
                             row?.icon_path === "/default.png" || 
                             !row?.icon_path ? (
                              <RandomLogo name={row?.company_name} />
                            ) : (
                              <CompanyLogo 
                                id={row?.company_id} 
                                name={row.company_name} 
                                page="company page" 
                                logo={row?.icon_path} 
                              />
                            )}
                            <OverflowToolTip 
                              className="w-[10rem] lg:max-w-[100%]" 
                              component={
                                <NavLink 
                                  className="flex text-start lg:inline lg:whitespace-nowrap hover:text-[#BC8B20] font-medium" 
                                  to={routeNames.cfsCompanyPageById(row.company_id)}
                                >
                                  {row?.company_name}
                                </NavLink>
                              } 
                            />
                          </div>
                        </td>
                        <td className="px-5 py-3 font-sans_book_body">
                          {formatChargeHolders(row?.latest_charge_holders)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>

              {!isLoad ? (
                (!rows || rows.length === 0) ? (
                  <div className="pr-2">
                    <div className="w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative">
                      <NoDataPlaceholder />
                    </div>
                  </div>
                ) : null
              ) : (
                <div className="w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {data?.num_companies && rows && rows.length > 0 && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_companies}
          pageSize={20}
          onPageChange={findActivePage}
        />
      )}
    </>
  );
};

export default Table;