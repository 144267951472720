import {
    React,
    useState,
    useMemo,
    useContext,
    useEffect,
  } from "react";
  import { NavLink, useLocation } from "react-router-dom";
  import { useParams } from "react-router-dom";
  import Directors from "../../DetailPage/Directors";
  import {
    exportPdf,
    getCfsCompaniesDetails,
    directorsUrl,
  } from "../../../Config/config";
  import barRaiser from "../../../images/barraiser-logo.png";
  import ComparePopUp from "../../../Pages/ComparePopUp";
  import compare from "../../../images/compare.png";
  import pdf from "../../../images/pdf.png";
  import addNoted from "../../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
  import addNotedActive from "../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png";
  import { CompareCompanyContext } from "../../../Contexts/CompareCompanyContext";
  import AddNote from "../../../Pages/addNote";
  import AlertPopUp from "../../../Components/DetailPage/AlertPopUp";
  import PENotesPopup from "../../../Components/NotesPopup/PENotesPopup";
  import SendDealPopUp from "../../../Components/SendDealPopUp";
  import PdfDownloader from "../../shared/PdfDownloader";
  import XLSPopup from "../../DetailPage/DownloadXLS";
  import Constants from "../../../Constants";
  import { Tooltip } from "antd";
  import axios from "axios";
  import CompanyLogo from "../../NewLogo";
  import { routeNames } from "../../../routeSegments";
  import { UserContext } from "../../../Contexts/UserContext";
  import AddNotePopover from "../../shared/AddNotePopover";
  import Tab from "../../shared/Tab";
  import ContentLoader, { Facebook } from "react-content-loader";
  import "./Profile.css";
  import { debounce, delay } from "lodash";
  import { useInView } from "react-intersection-observer";
  import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
  import { TableDataContext } from "../../../Contexts/TableDataContext";
  import { RaiseRequestContext } from "../../../Contexts/RaiseRequestContext";
  import ViewMoreViewLess from "../ViewMoreViewLess";
import AssociateCompanies from "../../DetailPage/AssociateCompanies";
  
  const DirectorDetailComponents = (props) => {
    
    const {setCname} = useContext(RaiseRequestContext);
    const directors = () => {
      document.getElementById("directors").scrollIntoView({ behavior: "smooth" });
    };

    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const { companyId, din } = useParams();
    let companyProfileId = parseInt(companyId);
    const { companyDetail, setCompanyDetail, setCurrentPage } = useContext(
      CompareCompanyContext
    );
    // const { currencyType, setCurrencyType, currency, setCurrency, isDetailed, setIsDetailed} = useContext(CurrencyContext);
    const [note, setNote] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState();
    const {data, setData, director, setDirectors, setUpdatedon} = useContext(TableDataContext);
    const [profileData, setProfileData] = useState();
    const [newsLetterData, setNewsLetterData] = useState();
    const [topProfile, setTopProfile] = useState([]);
    const location = useLocation();
    const { getToken, isStudent } = useContext(UserContext);
    const [skipScroll, setSkipScroll] = useState(false);

    /* Below code is using the `useInView` hook to track the visibility of an element on the page. */

    const [directorEl, isdirectorVisible, directorEntry] = useInView({
      threshold: 0.5,
      rootMargin: "60px 0px 0px 0px",
      skip: skipScroll,
    });

    const getProfile = async () => {
      try {
        // Reset states
        setData([]);
        setIsLoading(true);
        setIsError(false);
  
        const response = await axios(`${getCfsCompaniesDetails}/${companyId}/`);
        if (response.data) {
          const profile = response?.data?.profile;
          setData(response.data);
          setProfileData(profile);
          !profile?.cin && setIsLoading(false);
    
          if (profile?.cin) {
            await getDirectors(profile.cin);
          }

          setCname(profile?.short_name);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    const getDirectors = async (cin) => {
      try {
        const response = await axios(`${directorsUrl}${cin}`);
        if (response.data) {
          setDirectors(response?.data?.data);
          setUpdatedon(response?.data?.updated_at);
        }
      } catch (error) {
        console.error("Error fetching directors data:", error);
        setIsError(true);
      }
    };

    useEffect(() => {
      getProfile();
    }, [companyProfileId]);

    /**
     * The `onTabClick` function is used to handle tab clicks and perform different actions based on
     * the selected tab index.
     * @param tabIndex - The `tabIndex` parameter represents the index of the tab that was clicked. It
     * is used to determine which tab was clicked and perform the corresponding action.
     */
    const onTabClick = (tabIndex) => {
      setSkipScroll(true);
      delay(() => {
        setSkipScroll(false);
      }, 500);
      if (tabIndex === 1) {
        directors();
      }

      setIsActive(tabIndex);
    };
    const setIsActiveOnScroll = useMemo(() => {
      return debounce((index) => setIsActive(index), 100);
    }, []);

    useEffect(() => {
      if (isdirectorVisible) {
        setIsActiveOnScroll(8);
      }
    }, [isdirectorVisible]);

    const MyLoader = () => (
      <ContentLoader viewBox="0 0 350 40">
        <rect x="0" y="0" rx="5" ry="5" width="30" height="30" />
        <rect x="40" y="1" rx="4" ry="0" width="150" height="5" />
        <rect x="40" y="10" rx="3" ry="1" width="100" height="5" />
        <rect x="40" y="20" rx="2" ry="2" width="100" height="5" />
      </ContentLoader>
    );

    const { tableCompanyId: cmpId } = useContext(TableDataContext);

    const currentIndex = cmpId?.indexOf(Number(companyId));

    document.title = `${topProfile?.company_name ? topProfile?.company_name + " - " : ""}CFS - Venture Intelligence`;

    return (
    <>
      {isLoading && !isError ? (
        <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
          <table className="h-[10rem] w-full bg-[#FFFFFF] ">
            <center>
              <div role="status" className="bg-white  h-[16rem]">
                <svg
                  className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            </center>
          </table>
        </div>
      ) : !isError ? (
        <div className="overflow-y-auto details-screen">
          <div className="bg-[#FFFFFF] profile-detail relative">
            <nav
              className=" flex p-1 px-4 lg:px-1 border-b "
              aria-label="Breadcrumb"
            >
              <ol role="list" className="flex items-center space-x-1">
                <li className="flex items-center">
                  <div className="flex items-center">
                    <NavLink
                      to={"/cfs"}
                      className="ml-2 text-[11pt] lg:text-[12px]  leading-[14px] font-sans_book_body text-[#666666]"
                    >
                      CFS
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <span className=" text-[12px] text-[#666666] ">/</span>
                    <span
                      href="#"
                      className="px-1 text-[11pt] lg:text-[12px]  text-[#666666] leading-[14px] font-sans_book_body font-semibold"
                    >
                      {profileData?.short_name}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            {cmpId && 
              <>
                <Tooltip title="Prev">
                  <NavLink
                      className="absolute top-2 right-8"
                      to={routeNames.cfsCompanyPageById(cmpId[currentIndex - 1])}
                      >
                      {cmpId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                  </NavLink>
                </Tooltip>
                <Tooltip title="Next">
                    <NavLink
                        className="absolute top-2 right-4"
                        to={routeNames.cfsCompanyPageById(cmpId[currentIndex + 1])}
                        >
                        {cmpId.length - 1 === currentIndex ? null : (
                            <FaChevronRight size={15} color='grey'/>
                            )}
                    </NavLink>
                </Tooltip>
              </>
            }
            {/* <!-- BarRaiser--> */}
            <div className="px-5 ">
              <div className="pt-6 bg-[#FFFFFF]">
                <ul role="list">
                  <li className="w-full flex bg-[#FFFFFF]">
                    {profileData?.short_name && profileData.short_name ? (
                      <>
                        <div className=" rounded-md">
                          <CompanyLogo
                            name={profileData.short_name}
                            id={companyProfileId}
                            page="detail page"
                            menu='cfs'
                          />
                        </div>

                        <div className="ml-3 flex flex-1 flex-col">
                          <p className="text-[#333333] text-[22px] font-semibold leading-[24px]">
                            {profileData.short_name}{" "}
                            <span className="text-[18px] leading-[20px]">
                              {"(" + profileData.full_name + ")"}
                            </span>
                          </p>
                          <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body ">
                            {profileData?.industry}
                          </span>
                          <span className="text-[11pt] leading-[15pt]  text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px]">
                            {profileData?.sector}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="w-[75%]">
                        <MyLoader />
                      </div>
                    )}
                    <div className="hidden lg:grid grid-cols-8 gap-2">
                      <div className="col-span-3 mt-2">
                        <Tooltip title="Compare Company">
                          <button
                            className={
                              showModal
                                ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center"
                                : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                            }
                            type="button"
                            onClick={() => {
                              setShowModal(!showModal);
                              setCompanyDetail([]);
                              setCurrentPage("cfs");
                            }}
                          >
                            <img
                              className={
                                showModal
                                  ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                  : "w-[20px] h-[18px] pr-1 blur-btn"
                              }
                              src={compare}
                              alt=""
                            />
                            <span>Compare</span>
                          </button>
                        </Tooltip>
                      </div>

                      <div className="col-span-5 mt-[5px]">
                        <div className="grid grid-cols-5 gap-1 items-center">
                          <div className="col-span-1 ">
                            <AlertPopUp cid={companyProfileId} type_id={1} />
                          </div>
                          <div className="col-span-1">
                            <AddNotePopover
                              title="Save List"
                              companyId={companyProfileId}
                              companyName={profileData?.short_name ?? ""}
                              type_id={Constants.moduleIds.companiesId}
                              IsDetailed={true}
                              sub_type_master_id={
                                Constants.subCategoryIds.cfs_id
                              }
                            />
                              {showNote ? (
                                <AddNote
                                  module="cfs"
                                  page="cfsdetail"
                                  type_id={1}
                                  cname={profileData.short_name}
                                  cid={companyProfileId}
                                />
                              ) : (
                                ""
                              )}
                          </div>
                          <div className="col-span-1 mt-1">
                            <PENotesPopup
                              note={note}
                              cid={companyProfileId}
                              type_id={1}
                            />
                          </div>
                          <div className="col-span-1">
                            <SendDealPopUp data={profileData?.short_name} />
                          </div>
                          {isStudent == "true" ? (
                            <></>
                          ) : (
                            <div className="col-span-1">
                              {props?.page == "dealPage" ? (
                                <XLSPopup activeTab={props?.activeTab} />
                              ) : (
                                <PdfDownloader
                                  url={exportPdf}
                                  fileName={profileData?.short_name?.replaceAll(
                                    /\s/g,
                                    ""
                                  )}
                                  data={{
                                    module_type: "CFS",
                                    entity_id: companyProfileId,
                                    type_id: 1,
                                    type: 5,
                                    is_detailed: true,
                                    currency_cd: "INR",
                                    display_in: "C",
                                    sub_section: [
                                      "plstandard",
                                      "balancesheet",
                                      "cashflow",
                                      "ratio",
                                    ],
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="bg-[#FFFFFF]">
                <div>
                  <p className="text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                    {profileData?.business_description ? (
                      <ViewMoreViewLess>
                        {profileData.business_description}
                      </ViewMoreViewLess>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#EDEDED]">
            <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 py-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index">
              {director?.directorData?.length > 0 && location.pathname.includes("cfs") && (
              <Tab active={true} label={"Directors"} onClick={() => onTabClick(1)} />)}
            </div>
            <div className="h-full space-y-5 details-page">
              {director?.directorData?.length > 0 && location.pathname.includes("cfs") && (
                <section ref={directorEl} id="directors" className="scroll-mt-14 my-5">
                  <AssociateCompanies
                    companyName={profileData.short_name}
                    data={director}
                    cin={profileData.cin}
                  />
                </section>
              )}
            </div>
          </div>
          {showModal ? (
            <ComparePopUp
              id={companyProfileId}
              onClose={(e) => setShowModal(false)}
            />
          ) : null}
        </div>
      ) : (
        isError && (
          <div className="w-full h-full flex justify-center items-center">
            <h1>No Data Found for this company</h1>
          </div>
        )
      )}
    </>
  );
};
  
export default DirectorDetailComponents;