import { React, useContext, useState } from "react";
import "./Profile.css";
import linkedin from "../../../Utils/linkedin.png";
import { TableHeaderContext } from "../../../Contexts/TableHeaderContext";
import { useGetRatioHighlights, useLatestFinancialHighlights } from "../../DetailPage/FinancialComponents/ratios";
import OverflowToolTip from "../../shared/OverflowToolTip";
import Highlights from "../../DetailPage/Highlights";
import { Tooltip } from "antd";
import { UserContext } from "../../../Contexts/UserContext";
import Constants from "../../../Constants";
import HeaderButton from "../../DetailPage/FinancialComponents/HeaderButton";
import { X } from 'lucide-react';
import FormMCSite from "./CFSFinancial/FormMCSite";
import MCAHeaderButton from "../../DetailPage/FinancialComponents/MCAHeaderButton";

function ProfileHighlight({ profile, financials, menu }) {
  const { cfsProfileKeys } = useContext(TableHeaderContext);
  const { isTrialExport, isStudent, isDirectoryUser } = useContext(UserContext);
  const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
  const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
  const [openTab, setOpenTab] = useState(6);
  const [selected, setSelected] = useState(false);

  return (
    <>
      {/* Modal */}
      {selected && (
        <div className="fixed inset-0 z-10 ">
          {/* Backdrop with blur effect */}
          <div 
            className="fixed inset-0 bg-black/30 backdrop-blur-sm"
            onClick={() => setSelected(false)}
          />
          
          {/* Modal container with padding for scrolling */}
          <div className="fixed inset-0 flex items-center justify-center p-4">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg overflow-hidden">
              {/* Close button */}
              <div className="absolute top-0 right-0 p-4">
                <button
                  onClick={() => setSelected(false)}
                  className="rounded-full p-1 hover:bg-gray-100 transition-colors"
                >
                  <X className="h-5 w-5 text-gray-500" />
                </button>
              </div>
              
              {/* Form content with scrollable area */}
              <div className="overflow-y-auto max-h-[85vh] mt-6 mr-4">
                <FormMCSite tab={1} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main content */}
      <div className="lg:mt-4 px-4 py-4 w-[100%]">
        <div className="lg:bg-white rounded-[6px]" x-show="tab === 1">
          <div className="w-full lg:grid lg:grid-cols-5 rounded-[6px] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className="mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-3 lg:border-r bg-white">
              <div className="mx-5 py-4">
                <div className="flex justify-between items-center">
                  <p className="text-[14pt] flex leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                    Profile
                    {profile?.linkedin && (
                      <a
                        href={profile.linkedin}
                        target="_blank"
                        className="mt-[0.20rem] ml-2"
                        rel="noopener noreferrer"
                      >
                        <img src={linkedin} width={16} alt="linkedin logo" />
                      </a>
                    )}
                  </p>
                  {/* <MCAHeaderButton
                    isSelected={!selected}
                    onClick={() => setSelected(!selected)}
                  >
                    From MCA Site
                  </MCAHeaderButton> */}
                </div>

                <div className="mt-2">
                  {profile?.length === 0 ? (
                    <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
                      <table className="h-[10rem] w-full bg-[#FFFFFF]">
                        <center>
                          <div role="status" className="bg-white h-[16rem]">
                            <svg
                              className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        </center>
                      </table>
                    </div>
                  ) : (
                    <div className="divide-y overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
                      <div className="w-full bg-[#FFFFFF]">
                        <div className="grid grid-cols-3">
                          {cfsProfileKeys.map((k, index) => {
                            const key = k.accessor;
                            const label = k.label;
                            let valueToRender = null;

                            if (key === "contact_name") {
                              const contactName = profile["contact_name"];
                              const contactDesignation = profile["contact_designation"];
                              if (contactName && contactDesignation) {
                                valueToRender = `${contactName}, ${contactDesignation}`;
                              } else if (contactName) {
                                valueToRender = contactName;
                              } else if (contactDesignation) {
                                valueToRender = contactDesignation;
                              }
                            } else if (key === "city_data") {
                              const city = profile[key];
                              if (city && city.name) {
                                valueToRender = `${city.name}, ${city.state}, ${city.region}, ${city.country}`;
                              }
                            } else {
                              valueToRender = profile[key] && profile[key] !== "" ? profile[key] : null;
                            }

                            if (valueToRender !== null) {
                              return (
                                <div key={index} className="pr-[1rem]">
                                  <div className="leading-[21px] font-sans_book_body">
                                    <p className="py-1 text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto">
                                      {label}
                                    </p>
                                    {valueToRender.length > 30 ? (
                                      <Tooltip
                                        title={
                                          <div className="pr-[1rem]">
                                            <div className="leading-[21px] font-sans_book_body">
                                              {label === 'Website' ? (
                                                <a
                                                  href={valueToRender}
                                                  className="mb-2 text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] hover:text-[#BC8B20] cursor-pointer font-semibold"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {valueToRender}
                                                </a>
                                              ) : (
                                                <p className="mb-2 text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold">
                                                  {valueToRender}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        }
                                        color="white"
                                        placement="topLeft"
                                      >
                                        {label === "Website" ? (
                                          <a
                                            href={valueToRender}
                                            className="overflow-ellipsis overflow-hidden whitespace-nowrap mb-2 text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {valueToRender.slice(0, 30) + "..."}
                                          </a>
                                        ) : (
                                          <p className="overflow-ellipsis overflow-hidden whitespace-nowrap mb-2 text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold">
                                            {valueToRender.slice(0, 30) + "..."}
                                          </p>
                                        )}
                                      </Tooltip>
                                    ) : (
                                      label === "Website" ? (
                                        <a
                                          href={valueToRender}
                                          className="overflow-ellipsis overflow-hidden whitespace-nowrap mb-2 text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {valueToRender}
                                        </a>
                                      ) : (
                                        <p className="overflow-ellipsis overflow-hidden whitespace-nowrap mb-2 text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold">
                                          {valueToRender}
                                        </p>
                                      )
                                    )}
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Highlights financials={financials} menu={menu} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileHighlight;