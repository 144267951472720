import { React, useState, useMemo, useEffect, useContext } from 'react'
import axios from 'axios'
import { useQuery } from '@apollo/client'
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import xls from '../../../images/download-file-icon.png'
import sorting from '../../../images/up-down-arrow.png'
import ExcelPopup from '../../ExcelPopup'
import moment from 'moment'
import CompanyLogo from '../../CompanyLogo';
import RandomLogo from '../../RandomLogo';
import CustomNavigation from '../../../Utils/CustomNavigation';
import { routeNames } from '../../../routeSegments';
import DataTable from '../../DataTables/DataTable';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel, invIncInvestorDetail } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import { useCallback } from 'react';
import useModule from '../../../hooks/useModule';
import Constants from '../../../Constants';
import { UserContext } from '../../../Contexts/UserContext';
import ConfirmPopup from '../../../Utils/ConfirmPopup';
import NewLogo from '../../NewLogo';
import { Tooltip } from 'antd';


const InvestorAngelPortfolioCompanies = (props) => {
    const [showXlDownload, setshowXlDownload] = useState(false);
    const location = useLocation();
    const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, location?.state?.prevPath ?? "")
    const { investorId } = useParams();
    const {primaryMasterId} = useModule();
    const {isStudent, isDirectoryUser ,isTrialExport} = useContext(UserContext);

   /* The `customStringSortMemo` constant is a memoized function that is used for sorting string values
   in a DataTable component. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? 'A' : 'Z';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

   /* The `dealDateSort` constant is a memoized function that is used for sorting the "Deal Date"
   column in the DataTable component. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

    console.log("Investments", props);

   /* The `columns` constant is an array of objects that define the columns for the DataTable
   component. Each object represents a column and has properties such as `id`, `Header`, `accessor`,
   `className`, `Cell`, and `sortType`. */
    const columns = useMemo(() => [
        {
            id: "companyName",
            Header: "Company Name",
            accessor: "companyName",
            className:"w-[16%]",
            Cell: ({ value }) => {
                return <div className='flex items-center'>
                    <NewLogo
                        name={value.name}
                        id={value?.id}
                        menu="pe"
                    />
                    <Link className='hover:text-[#BC8B20] '
                        state={{ cameFrom: location.state?.prevPath }}
                        to={isVcCompanyMatch != null ? routeNames.vcCompanyFundById(value?.id, ) 
                        : routeNames.peCompanyFundById(value?.id, )}>
                        {value.name ?? "--"}
                    </Link>
                </div>
            },
            sortType: customStringSortMemo,
        },
        {
            id: "dealDate",
            Header: "Deal Date",
            accessor: "dealDate",
            className:"w-[14%]",
            Cell: (cell) => {
                const date = cell.value?.date ? moment(cell.value.date).format('MMM YYYY') : ""; // Check if date is not null

                return <Link 
                target='_blank'
                state={{ cameFrom: location.state?.prevPath ?? "" }}
                 onClick={(e) => {
                    if (isDirectoryUser) {
                        e.preventDefault();
                        e.stopPropagation();
                        onBlurColumnClick();
                    }
                 }}
                 to={isVcCompanyMatch != null ? routeNames.vcCompanyInvestmentDealById(cell.value?.id, cell.value?.dealId) : routeNames.peCompanyInvestmentDealById(cell.value?.id, cell.value?.dealId)}>
                    {date}
                    {cell.value?.agghide && (
                        <Tooltip
                            placement="right" 
                            color="#ffffff"
                            key="#ffffff"
                            overlayStyle={{ maxWidth: '30%'  }}
                            title={<span style={{ color: '#000000' }}>Not Included for Aggregate calculation as it does not meet the Venture Intelligence definition of PE</span>}
                        >
                            <span className="rounded-md bg-[#f3e8ff] px-2 py-[3px] ml-2 text-xs font-sans_book_body text-[#333333]">
                            NIA
                            </span>
                        </Tooltip>
                    )}
                </Link>
            },
            sortType: dealDateSort
        },
        {
            Header: "VC Funded",
            accessor: "vcfunded",
            className:"pr-2 w-[15%]",
            
           
        },
        {
            Header: "Multiple Angel Rounds",
            accessor: "angelFunded",
            className:"pr-2 w-[12%]"
           
        },
        {
            Header: "Exit Status",
            accessor: "status",
            className:"w-[2%]"
           
        }
    ], [customStringSortMemo, location.state?.prevPath]);

   /* The `rowData` constant is using the `useMemo` hook to memoize the data for the DataTable
   component. It iterates over the `props.investmentsData` array and creates a new array of objects,
   where each object represents a row in the DataTable. Each object has properties such as
   `companyName`, `dealDate`, `status`, `angelFunded`, and `vcfunded`, which are derived from the
   corresponding properties in the `props.investmentsData` array. */
    const rowData = useMemo(() => {
        const rows = [];

        props.investmentsData?.forEach(inv => {
            rows.push({
                companyName: { name: inv?.company?.name, dealId: inv?.id, id: inv?.company?.id },
                dealDate: { date: inv?.date, dealId: inv?.id, id: inv?.company?.id, agghide: inv?.is_agg_hide  },
                status: inv?.is_exited ? "Yes" : "No",
                angelFunded: inv?.is_multiple_round ? "Yes" : "No",
                vcfunded: inv?.is_follow_on_vc_fund ? "Yes" : "No",
            })
        });
        setshowXlDownload(rows.length !== 0)

        return rows;
    }, [props.investmentsData])

    /* The `initialSort` constant is using the `useMemo` hook to memoize an array of sorting options
    for the DataTable component. */
    const initialSort = useMemo(() => [
        {
            id: "dealDate",
            desc: true
        }
    ], []);

    const [alert, setalert] = useState({show: false, message:""})

    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message with a subscription message and displays
     * it.
     */
    const onBlurColumnClick = (cell) => {
        setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }


    return (
        <>
            <div className="space-y-6 pt-4 px-4 bg-[#EDEDED]">
                <div className=" bg-white rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                        <div className="flex justify-between mb-2">
                            <p className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  mb-2 lg:leading-[22px]">Portfolio Companies - Investments</p>
                            {isStudent =='false' && isTrialExport !='false' && showXlDownload && <ExcelDownloader
                                url={exportExcel}
                                fileName={Constants.exportNames.portfolio_companies_investments(props.investorName)}
                                data={{
                                    "type": primaryMasterId,
                                    "export_from": "detail",
                                    "module_type": "investors",
                                    "category": "angel",
                                    "section": "company_investments",
                                    "investor_id": [investorId]
                                }} />}
                        </div>
                    </div>

                    <div className="space-y-6">
                        <div className="bg-white pb-2 rounded-[6px]">
                            <DataTable columns={columns} data={rowData} sortBy={initialSort} />

                        </div>

                    </div>
                </div>
            </div>
        {<ConfirmPopup {...alert} onChange={onConfirmation} />}
        </>
    )
}

export default InvestorAngelPortfolioCompanies