import React, { useState } from "react";
import { logoUrl } from "../Config/config";
import RandomLogo from "./RandomLogo";

const NewLogo = ({ id, menu = "pe", page, name }) => {

  const [isError, setIsError] = useState(false);

    if (isError) {
      return <RandomLogo name={name} page={page} />;
    }


  const getLogoPath = () => {
    const paths = {
      cfs: `${logoUrl}/cprofile/`,
      csf: `${logoUrl}/fundseekcompany/`,
      re: `${logoUrl}/recompany/`,
      investor: `${logoUrl}/peinvestor/`,
      advisor: `${logoUrl}/advisor/`,
      pe: `${logoUrl}/pecompany/`
    };

    const basePath = paths[menu] || paths.pe;
    return `${basePath}${id}.png`;
  };

  const logo = getLogoPath();

  const handleError = () => {
    setIsError(true);
  };

  if (page === "detail page") {
    return (
      <div className="h-16 w-16 p-0.5 rounded-md bg-white flex items-center justify-center">
        <img
          alt={`${name || 'Company'} logo`}
          onError={handleError}
          className="max-w-full max-h-full bg-black object-contain"
          src={logo}
        />
      </div>
    );
  }

  return (
    <div className="inline-block float-left">
      <img
        alt={`${name || 'Company'} logo`}
        onError={handleError}
        className="border-2 border-solid rounded-full mr-2 w-8 h-8 cursor-pointer bg-black object-cover"
        src={logo}
      />
    </div>
  );
};

export default NewLogo;
