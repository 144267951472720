import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react'
import image1 from '../../../src/images/Sixdot/Group15.png'
import './dashboarcd.css'
import axios from 'axios';
import { savedFilterListApi , csfListApi } from '../../Config/config';
import { moduleValues, primaryMasterSubValues, primaryMasterValues } from '../../Constants/values';
import { useFilters, usePagination, useTable } from 'react-table';
import DashboardPagination from '../shared/DashboardPagination';
import OverflowToolTip from '../shared/OverflowToolTip';
import { GrView } from 'react-icons/gr'
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import routePathSegments, { getRoutePathById} from '../../routeSegments';
// import { PeFilterContext } from '../../Contexts/PeFilterContext';
import Loader from '../Loader';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import { UserContext } from '../../Contexts/UserContext';
import moment from "moment";
import Constants from '../../Constants';
import DashBoardTableTwo, { getPath } from './DashBoardTableTwo'
import useModule from '../../hooks/useModule';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { FaExternalLinkAlt, FaFilePdf } from 'react-icons/fa';

/**
 * The function `getSectionLabelById` takes an ID as input and returns the corresponding label for that
 * ID, based on predefined values and constants.
 * @returns The function `getSectionLabelById` returns the label of a section based on its ID.
 */
const getSectionLabelById = (id) => {
    if(id == null){
        return null
    }
    const primaryMasterSub = primaryMasterSubValues.find(option=>parseInt(option.value) == parseInt(id))
    if(primaryMasterSub){
        return primaryMasterSub.title;
    }

    const key = Object.keys(Constants.primaryMasterSubIds)
        .find(subIdKey => Constants.primaryMasterSubIds[subIdKey] == id) ?? ""

    const label = key.split("_").filter(value => value != "id")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return label;
}

/* The above code is a React component called `FilterListDataTable`. It is responsible for rendering a
table that displays a list of saved filters. The component uses various hooks such as `useState`,
`useEffect`, and `useContext` to manage state and perform side effects. */
function FilterListDataTable() {
    const [savedFilterList, setSavedFilterList] = useState([]);
    const [combinedFilterList, setCombinedFilterList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const { applyFilterFrom } = useContext(PeFilterContext);
    const [selectedPrimaryModule, setselectedPrimaryModule] = useState('All');
    const [selectedCategory, setSelectedCategory] = useState("VI Filters")
    const {getToken, getUser} = useContext(UserContext);
    const navigate = useNavigate();
    const [savedGroupList,setSavedGroupList] =useState([])
    const {allowedModulesList} = useModule()
    const [moduleOptions,setModuleOptions] = useState([])
    const [fundingData, setFundingData] = useState([]);
    const [fundingTotalRecords, setFundingTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoadingFunding, setIsLoadingFunding] = useState(false);
    const itemsPerPage = 10;

    // const getSavedList = () => {
    //     setIsLoading(true);
    //     axios.post(savedFilterListApi, {
    //         "user_id": getUser().id,
    //         // "module_id": 1,
    //         "primary_type_master_id": selectedPrimaryModule,
    //         "filter_type": selectedCategory,
    //         // "sub_type_master_id": []
    //     },{
    //         headers:{
    //             "Authorization": getToken()
    //         }
    //     }).then(response => {
    //         setIsLoading(false);
    //         if (response.status === 200) {
    //             setSavedFilterList(response.data?.listviewfilter ?? [])
    //             setSavedGroupList(response?.data?.groupList ?? [])
    //         }
    //     })
    // }
    // console.log(savedGroupList,'savedGroupList');

    useEffect(() => {
        setIsLoading(true);

        if(selectedCategory !== 'Companies Seeking Funding'){
            axios.post(savedFilterListApi, {
                "user_id": getUser().id,
                "primary_type_master_id": selectedPrimaryModule,
                "filter_type": selectedCategory,
            }, {
                headers: {
                    "Authorization": getToken()
                }
            }).then(response => {
                setIsLoading(false);
                if (response.status === 200) {
                    if (selectedCategory === 'My Filters') {
                        setSavedFilterList(response.data?.listviewfilter ?? []);
                    } else {
                        const listViewFilters = response.data?.listviewfilter ?? [];
                        const groupViewFilters = response.data?.grpviewfilter ?? [];
                        const combined = [...listViewFilters, ...groupViewFilters.map(item => ({...item, isGroupView: true}))];
                        setCombinedFilterList(combined);
                    }
                    setSavedGroupList(response?.data?.groupList ?? [])
                }
            });
        }
    }, [selectedCategory, selectedPrimaryModule]);

    // useEffect(()=>{
    //     (async () => {
    //         const payload = {
    //             per_page: 10,
    //             order_by: {
    //                 sort_by: "industry",
    //                 order: "desc"
    //             }
    //         };
    
    //         try {
    //             const response = await fetch(csfListApi, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': getToken()
    //                 },
    //                 body: JSON.stringify(payload)
    //             });
    
    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! Status: ${response.status}`);
    //             }
    
    //             const data = await response.json();
    //             console.log(data.data, "csf");
    
    //             setFundingData(data?.data?.map(item => ({
    //                 company: item.registered_name,
    //                 industry: item.industry,
    //                 fundingSought: item.amount_sought,
    //                 revenue: item.revenue,
    //                 pitchDeck: item.pitch_doc_url
    //             })));
    
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     })(); 
    // },[])


    const fetchFundingData = async (page = 1) => {
        setIsLoadingFunding(true);
        const payload = {
            page: page,
            per_page: itemsPerPage,
            order_by: {
                sort_by: "industry",
                order: "desc"
            }
        };
    
        try {
            const response = await fetch(csfListApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getToken()
                },
                body: JSON.stringify(payload)
            });
    
                if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
    
            setFundingData(data?.data?.map(item => ({
                company: item.registered_name,
                industry: item.industry,
                fundingSought: item.amount_sought,
                revenue: item.revenue,
                fund_req_id:item.fund_req_id,
                pitchDeck: item.pitch_doc_url
            })) || []);
            setFundingTotalRecords(data?.total || 0);
        } catch (error) {
            console.error('Error fetching funding data:', error);
            toast.error('Failed to fetch funding data');
        } finally {
            setIsLoadingFunding(false);
        }
    };

    // Call fetchFundingData when the category changes to 'Companies Seeking Funding'
    useEffect(() => {
        if (selectedCategory === 'Companies Seeking Funding') {
            fetchFundingData(currentPage);
        }
    }, [selectedCategory, currentPage]);

    // Handle pagination change for funding data
    const onFundingPaginationChange = (page) => {
        setCurrentPage(page);
    };


    const generateId = ()=>nanoid()
    const queryId = generateId()

   /* The above code is defining a function called `onViewClick` in JavaScript React. This function
   takes an `id` parameter and is defined using the `useCallback` hook. */
   const onViewClick = useCallback(async (id, isGroupView) => {
    const clickedFilter = selectedCategory === 'My Filters' ? savedFilterList.find(filter => filter.id === id) : combinedFilterList.find(filter => filter.id === id);
    if (!clickedFilter) {
        toast.error('Filter not found');
        return;
    }
    if (isGroupView) {
        navigate(getPath(clickedFilter),{state:{myList:{myList:[{id:0,group_id:clickedFilter?.id,name:clickedFilter?.group_name,type:clickedFilter?.type_name}]},monthRange:['1998-01-01', moment().endOf("month").format("YYYY-MM-DD")]}})
    } else {
        // Existing logic for list view filters
        const moduleId = parseInt(clickedFilter.module_id);
        const primaryId = parseInt(clickedFilter.primary_type_master_id);
        const subPrimaryId = parseInt(clickedFilter.sub_type_master_id);
        const request_json = JSON.parse(clickedFilter?.request_json);

        if(request_json.advance_search){
            localStorage.setItem(queryId, JSON.stringify(request_json?.advance_search));
            if(moduleId === 1){
                navigate(`/${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.companies}?id=${queryId}`)
            } else if(moduleId === 2){
                navigate(`/${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.investors}?id=${queryId}`)
            }else if (moduleId === 3){
                navigate(`/${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.advisors}?id=${queryId}`)
            } else navigate(`/${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.transaction_advisors}?id=${queryId}`)
        }  else {
            const filter = JSON.parse(clickedFilter?.request_json)??{}
            // console.log("clickedFilterclickedFilter", filter);
            navigate(getRoutePathById({ moduleId, primaryId, subPrimaryId }),{state:{filterData:filter?.filterData,monthRange:[filter?.date_range?.from_date, moment().startOf("second").format("YYYY-MM-DD")]}})
        }
    }
}, [selectedCategory, savedFilterList, combinedFilterList, navigate]);

    const columns = useMemo(() => [
        {
            Header: "List Name",
            accessor: "searchName",
            // className:"flex justify-center",
            Cell: ({ value }) => {
                return <OverflowToolTip title="Refine Search Name" className="max-w-[18rem]"
                    component={value} />
            }
        },
        {
            Header: "Module",
            accessor: "module",
            className: "text-center",
            Cell: ({ value }) => {
                return primaryMasterValues.find(data => data.value == value)?.title
            },
        },
        {
            Header: "Type",
            accessor: "type",
            className: "text-center "
        },
        {
            Header: "Sub Type",
            accessor: "subType",
            className: "text-center ",
            Cell: ({ value, row }) => {
                if (row.original.isGroupView) return "--";
                return value ? value : "N/A";
            }
        },
        {
            Header: "Created Date",
            accessor: "createdDate",
        },
        
        {
            Header: "Action",
            accessor: "actions",
            className: "flex justify-center pt-[0.7rem]",
            Cell: ({ value }) => {
                return (
                    <Tooltip className='text-center' title="view">
                        <GrView onClick={() => onViewClick(value.id, value.isGroupView)} className='cursor-pointer' />
                    </Tooltip>
                );
            }
        },
    ], [onViewClick])

    const data = useMemo(() => {
        const filtersToProcess = selectedCategory === 'My Filters' ? savedFilterList : combinedFilterList;
        
        return filtersToProcess.map(filter => {
            if (filter.isGroupView && selectedCategory !== 'My Filters') {
                return {
                    module: filter.primary_type_master_id,
                    type: filter.type_name,
                    subType: "--",
                    searchName: filter.name,
                    createdDate: moment(filter.created_at).format('DD MMM YYYY'),
                    actions: { id: filter.id, isGroupView: true }
                };
            } else {
                // List view filter
                const filters = JSON.parse(filter.request_json) ?? {};
                let keys = new Set();
                Object.keys(filters)?.forEach(key => {
                    if (Array.isArray(filters[key])) {
                        if (filters[key].length > 0) {
                            keys.add(key.toUpperCase().replace('_', " "));
                        }
                    } else if (typeof filters[key] === "object") {
                        keys.add(key.toUpperCase().replace('_', " "));
                    }
                });
    
                return {
                    module: filter.primary_type_master_id,
                    type: moduleValues.find(data => data.value == filter.module_id)?.title,
                    subType: getSectionLabelById(filter.sub_type_master_id),
                    searchName: filter.name,
                    appliedFilter: Array.from(keys),
                    createdDate: moment(filter.created_at).format('DD MMM YYYY'),
                    actions: { id: filter.id, isGroupView: false }
                };
            }
        });
    }, [selectedCategory, savedFilterList, combinedFilterList]);

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageSize: 10 }
    },
        useFilters,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setFilter,
        state: { pageIndex, pageSize },
    } = tableInstance

   /**
    * The function `onPaginationChange` is used to handle pagination changes in a React component.
    */
    const onPaginationChange = (page, pageSize) => {
        gotoPage(page - 1)
    }

    /**
     * The function `onModuleChange` updates the value of `selectedPrimaryModule` based on the value of
     * the target element in an event.
     */
    const onModuleChange = (e) => {
        setselectedPrimaryModule(e.target.value);
    }

    /**
     * The function `onCategoryChange` updates the selected category based on the value passed as an
     * argument.
     */
    const onCategoryChange = (value)=>{
        // alert(value)
        setSelectedCategory(value)
    }

    useEffect(() => {
        setFilter('module', selectedPrimaryModule === "All" ? "" : selectedPrimaryModule)
    }, [selectedPrimaryModule])

    // const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
    var allowedModulesData =[] 

    useEffect (()=>{
        
        /* The above code is checking if certain modules are included in the `allowedModulesList`
        array. If a module is included, it adds an object with a `title` and `value` property to the
        `allowedModulesData` array. After checking all the modules, if there is more than one module
        in the `allowedModulesData` array, it adds an additional object with a `title` of "All" and
        a `value` of "All" at the beginning of the array. Finally, it sets the state of
        `moduleOptions` to the `allowedModulesData` array. */
        if(allowedModulesList.includes('pe')){
            allowedModulesData.push({title: "PE", value: "1"},)
        }
        if(allowedModulesList.includes('vc')){
            allowedModulesData.push({title: "VC", value: "2"},)
        }
        if(allowedModulesList.includes('re')){
            allowedModulesData.push({title: "RE", value: "3"},)
        }
        if(allowedModulesList.includes('ma')){
            allowedModulesData.push({title: "M & A", value: "4"},)
        }
        if(allowedModulesList.includes('cfs')){
            allowedModulesData.push({title: "CFS", value: "5"},)
        }
        if(allowedModulesData.length > 1 ){
            allowedModulesData.splice(0,0,{title: "All", value: "All"},)
        }
        setModuleOptions(allowedModulesData)

    },[]) 
    

    const fundingColumns = useMemo(() => [
        {
            Header: "Companies looking to raise funding",
            accessor: "company",
            Cell: ({ value }) => {
                return <OverflowToolTip title="Company Name" className="max-w-[18rem]"
                    component={value} />
            }
        },
        {
            Header: "Industry",
            accessor: "industry",
        },
        {
            Header: "Funding Sought (₹Cr)",
            accessor: "fundingSought",
            className: "text-center",
        },
        {
            Header: "Revenue (₹Cr)",
            accessor: "revenue",
            className: "text-center",
        },
        {
            Header: "Pitch Deck",
            accessor: "pitchDeck",
            Cell: ({ value }) => (
                <div className="flex justify-center">
                    {value ? (
                        <a href={value} download target="_blank" rel="noopener noreferrer">
                            <FaFilePdf className="cursor-pointer" size={20} color="#d6b15f" />
                        </a>
                    ) : (
                        <span>No File</span>
                    )}
                </div>
            )
        }
    ], []);


    const fundingTableInstance = useTable(
        {
            columns: fundingColumns,
            data: fundingData,
            initialState: { pageSize: 10 }
        },
        useFilters,
        usePagination
    );
    const {
        getTableProps: getFundingTableProps,
        getTableBodyProps: getFundingTableBodyProps,
        headerGroups: fundingHeaderGroups,
        page: fundingPage,
        prepareRow: prepareFundingRow,
    } = fundingTableInstance;


    return (        
        <div className="border border-[#CECECE] rounded-[4px] m-2 lg:p-2 lg:py-3 bg-white lg:w-[70%]  lg:h-[35rem]">
                    <div className="sm:flex flex justify-between items-center px-2 lg:py-2 h-[4rem] lg:h-[2rem]">
                        <div className="flex space-x-3 items-center">
                            <div className='pl-4 flex space-x-5 lg:space-x-12 items-center'>
                                <p className={`text-[#333333] font-sans_medium_body cursor-pointer ${selectedCategory==='VI Filters' ? 'font-semibold text-[18px] leading-[19px]':'text-[16px] leading-[17px]'}`} onClick={()=>onCategoryChange('VI Filters')}>VI Filters</p>                        
                                <p className={`text-[#333333] font-sans_medium_body cursor-pointer ${selectedCategory==='My List' ? 'font-semibold text-[18px] leading-[19px]':'text-[16px] leading-[17px]'}`} onClick={()=>onCategoryChange('My List')}>My List</p>                        
                                <p className={`text-[#333333] font-sans_medium_body cursor-pointer ${selectedCategory==='My Filters' ? 'font-semibold  text-[15px] pb-1 lg:pb-0 lg:text-[18px] leading-[19px]':'text-[16px] leading-[17px]'}`} onClick={()=>onCategoryChange('My Filters')}>Saved Search</p>   
                                {/* <p 
                                    className={`text-[#333333] font-sans_medium_body cursor-pointer flex items-center space-x-2 ${selectedCategory==='Companies Seeking Funding' ? 'font-semibold  text-[15px] pb-1 lg:pb-0 lg:text-[18px] leading-[19px]':'text-[16px] leading-[17px]'}`} 
                                    onClick={()=>onCategoryChange('Companies Seeking Funding')}
                                >
                                    <span>Companies Seeking Funding</span>
                                    <a href={routePathSegments.csf}>

                                        <FaExternalLinkAlt 
                                            className="cursor-pointer text-[#d6b15f] hover:text-[#bc8b20]"
                                            size={14}
                                        />
                                        </a>
                                </p>  */}
                            </div>                     
                        </div> 
                        {moduleOptions.length > 0 && selectedCategory !== 'Companies Seeking Funding' && 
                        <select onChange={onModuleChange}
                                value={selectedPrimaryModule}
                                className=" py-0.5 text-[13px] font-sans_book_body text-[#645326] leading-[16px] rounded-[10.5px] block px-2.5 bg-[#FFFFFF] border border-[#BC8B20]  focus:outline-none ">
                                {moduleOptions.map((primaryValue,i) => (
                                    <option value={primaryValue.value} key={i}>
                                        {primaryValue.title}
                                    </option>
                                ))}
                        </select>}
                    </div>
                    {/* <!-- table --> */}              
                    <div className=' overflow-x-auto lg:thin-scrollbar scrollbar-remove'>
                    {selectedCategory==='My List' ? 
                     
                            <DashBoardTableTwo savedWishList={savedGroupList} isLoading={isLoading}/>
                          
                        :  selectedCategory === 'Companies Seeking Funding' ? (
                            <>
                                <div className="overflow-x-auto lg:thin-scrollbar scrollbar-remove">
                                    <table className="w-full h-full p-1 rounded-[4px] border-separate text-center border-spacing-y-1" {...getFundingTableProps()}>
                                        <thead className="bg-[#c3c3c3] font-sans_medium_body text-sm leading-[16px] text-[#273746] h-[2.3rem]">
                                            {fundingHeaderGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps([
                                                            { className: `whitespace-nowrap px-5 ${column.headerClassName}` }
                                                        ])}>
                                                            {column.render('Header')}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody className="text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body text-center" {...getFundingTableBodyProps()}>
                                            {isLoadingFunding ? (
                                                <tr>
                                                    <td colSpan={fundingColumns.length} className="h-[27.5rem]">
                                                        <div className='bg-white w-full flex items-center justify-center h-full'>
                                                            <Loader />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : fundingData.length === 0 ? (
                                                <tr>
                                                    <td colSpan={fundingColumns.length} className="h-[27.5rem]">
                                                        <div className='bg-white w-full flex items-center justify-center h-full'>
                                                            <NoDataPlaceholder showButton={false} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                fundingData.map((row, index) => (
                                                    <tr key={index} className="px-2 even:bg-[#E8E8E8] even:h-[2.3rem] odd:bg-white odd:h-8" style={{ height: '2.3rem' }} >
                                                        <td className="px-5 py-2 cursor-pointer" onClick={() => window.location.href = `/csf/company/${row.fund_req_id}`}>{row.company}</td>
                                                        <td className="px-5 py-2">{row.industry}</td>
                                                        <td className="px-5 py-2 text-center">{row.fundingSought}</td>
                                                        <td className="px-5 py-2 text-center">{row.revenue}</td>
                                                        <td className="px-5 py-2">
                                                            <div className="flex justify-center">
                                                                {row.pitchDeck ? (
                                                                    <a href={row.pitchDeck} download target="_blank" rel="noopener noreferrer">
                                                                        <FaFilePdf className="cursor-pointer" size={20} color="#d6b15f" />
                                                                    </a>
                                                                ) : (
                                                                    <span>No File</span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {fundingData.length > 0 && !isLoadingFunding && (
                                    <div className='text-center mt-2 mb-2'>
                                        <DashboardPagination
                                            current={currentPage}
                                            total={fundingTotalRecords}
                                            pageSize={itemsPerPage}
                                            onChange={onFundingPaginationChange}
                                        />
                                    </div>
                                )}
                            </>
                        ):       <div className=" overflow-x-auto lg:thin-scrollbar scrollbar-remove">                  
                            <table className="w-full h-full p-1 rounded-[4px] border-separate text-center border-spacing-y-1 " {...getTableProps()}>
                                <thead className="bg-[#c3c3c3] font-sans_medium_body text-sm leading-[16px]  text-[#273746] h-[2.3rem]">
                                    {
                                        headerGroups.map(headerGroup => (

                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map(column => (

                                                        <th {...column.getHeaderProps([
                                                            { className: `whitespace-nowrap px-5  ${column.headerClassName}` }
                                                        ])}>
                                                            {
                                                                column.render('Header')}
                                                        </th>
                                                    ))}
                                            </tr>
                                        ))}
                                </thead>
                                {!isLoading && (selectedCategory === 'My Filters' ? savedFilterList : combinedFilterList).length > 0 && page.length > 0 ? (
                                    <tbody className="text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body text-center" {...getTableBodyProps()}>
                                        {page.map(row => {
                                            prepareRow(row);
                                            return (
                                                <tr className="px-2 even:bg-[#E8E8E8] even:h-[2.3rem] odd:bg-white odd:h-8" {...row.getRowProps()} style={{ height: '2.3rem' }}>
                                                    {row.cells.map(cell => (
                                                        <td {...cell.getCellProps([{ className: `lg:whitespace-nowrap px-5 py-2 ${cell.column.className}` }])}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody> ) : 
                                    <tbody className='h-[27.5rem]'>
                                    <td colSpan={headerGroups.map(headerGroup => { return headerGroup?.headers?.length })} className=''>
                                    {isLoading
                                        ? <div className='bg-white w-full flex items-center justify-center h-full'>
                                            <Loader />
                                        </div>
                                        : combinedFilterList.length < 1 || page.length < 1
                                            ? <div className='bg-white w-full flex items-center justify-center h-full'>
                                                <NoDataPlaceholder showButton={false} />
                                            </div>
                                            : <></>}
                                            </td>
                                    </tbody>
                                }                           
                            </table> 
                            </div>                        
                        }                    
                    </div>                             
                    {rows.length > 0 && !isLoading &&
                        <div className='text-center mt-2 mb-2'>
                            <DashboardPagination
                                current={pageIndex + 1}
                                total={rows.length}
                                pageSize={pageSize}
                                onChange={onPaginationChange}
                            />
                        </div>
                    }  
            </div>                     
    )
}

export default FilterListDataTable