import React, { useState } from 'react';
import CompanyLogo from '../CompanyLogo';
import RandomLogo from '../RandomLogo';
import { Link } from 'react-router-dom';
import useModule from '../../hooks/useModule';
import { routeNames } from '../../routeSegments';
import { Tooltip } from "antd";
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';



/**  It renders a table that displays investor
information. It receives data as a prop and maps over the data to render each row in the table. If
there is no data available, it displays a message saying "No data available". The table has columns
for the investor name, amount in INR, amount in USD, and stake. The investor name is a link that
navigates to the investor's page. The component also uses a custom hook called useModule to
determine if the user is a PE investor or not. */
const InvestorInfo = (props) => {
    const { isPE } = useModule();
    const [expandedRow, setExpandedRow] = useState(new Set()); 
    const [open, setOpen] = useState(false);

    const toggleRow = (index) => {
        setExpandedRow(prev => {
        const newSet = new Set(prev);
        if (newSet.has(index)) {
            newSet.delete(index);
        } else {
            newSet.add(index);
        }
        return newSet;
        });
    };
    
    function Icon({ open }) {
        return (
            <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        );
    }

    const handleOpen = (op) =>{
        setOpen(op ? true : false);
    }
    
    

    return (
            <div
                className="lg:grid lg:grid-rows-8 gap-6">
                <div className="w-full lg:grid lg:grid-rows-8 lg:grid-flow-col overflow-x-auto scrollbar ">
                    <div
                        className="row-span-3">
                        <div className="">
                            <div className="row-span-1">
                                    {props?.data?.length === 0 ? (
                                    <div className="flex items-center justify-center w-full px-5">
                                        <p className="p-3 my-3 mx-auto text-[14px] text-[#333333] text-center leading-[16px] font-sans_book_body w-full">No data available</p>
                                    </div>
                                    ) : (
                                    <table
                                        className="w-full mx-auto mt-2 flex-shrink-0">
                                        <thead 
                                            className=" text-[12pt] leading-[15pt] bg-[#F3F3F3] lg:text-[13px] lg:leading-[15px] font-bold text-[#666666] font-semibold whitespace-nowrap">
                                            <tr>
                                                <th className="py-2 flex px-5 text-left whitespace-nowrap">
                                                    Name
                                                </th>
                                                <th className="whitespace-nowrap py-2 px-8 w-[10%]">INR Cr</th>
                                                <th className="whitespace-nowrap py-2 px-8 w-[10%]">US$ M</th>
                                                <th className="whitespace-nowrap py-2 px-8 w-[15%]">Stake</th>
                                            </tr>
                                        </thead>
                                        {props?.data ?
                                            <tbody
                                                className="text-[12pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333]">
                                                {props?.data?.length > 0 &&
                                                    props?.data.map((data, i) => (
                                                    <React.Fragment key={i}>
                                                        <tr className="border-b">
                                                            <td className="w-[54%] whitespace-nowrap font-medium py-4 px-5 text-left">
                                                                <div className='leading-8 flex items-center'>
                                                                    
                                                                    {data?.logo_path === "default.png" || !data?.logo_path ? (
                                                                        <RandomLogo name={data?.investor_name} />
                                                                    ) : (
                                                                        <CompanyLogo name={data?.investor_name} id={data?.investor_id} page="company page" logo={data?.logo_path} />
                                                                    )}
                                                                    {data?.investor_name === "Others" ? (
                                                                        <span>{data?.investor_name}</span> 
                                                                    ) : (
                                                                        <Link to={isPE ? routeNames.peInvestorById(data?.investor_id) : routeNames.vcInvestorById(data?.investor_id)}>
                                                                            {data?.investor_name}
                                                                        </Link>
                                                                    )}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {data?.investor_name !== "Others" && data?.investor_type && (
                                                                        <Tooltip title={data?.investor_type === "N" ? 'New Investor' : data?.investor_type === "L" ? 'Lead Investor' : 'Existing Investor'} placement="top" color="white" overlayInnerStyle={{ color: '#333333', fontWeight: '500', whiteSpace: 'nowrap', width: 'fit-content' }}>
                                                                            <span className={`inline-flex items-center rounded-full ${data?.investor_type === "N" ? 'bg-red-100 text-red-800' : data?.investor_type === "L" ? 'bg-green-100 text-green-800' : 'bg-purple-100 text-purple-800'} px-2.5 py-0.5 text-xs font-medium`}>
                                                                                {data?.investor_type}
                                                                            </span>
                                                                        </Tooltip>
                                                                    )}
                                                                    {data?.fund_details?.length > 0 && (
                                                                        <button onClick={() => toggleRow(i)} className="ml-2 text-lg">
                                                                            {expandedRow.has(i) ? '-' : '+'}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="whitespace-nowrap w-[10%] py-4 pr-[2.3rem] text-right">
                                                                {data?.amount_inr === 0 ? " " : data?.amount_inr}
                                                            </td>
                                                            <td className="whitespace-nowrap w-[13%] py-4 pr-[2.2rem] text-right">
                                                                {data?.amount_usd === 0 ? " " : data?.amount_usd}
                                                            </td>
                                                            <td className="whitespace-nowrap w-[10%] py-4 pr-[2.2rem] text-right">
                                                                {data?.stakepercentage == 0.00 ? "" : `${data?.stakepercentage}%`}

                                                            </td>
                                                        </tr>

                                                        {/* Conditionally render fund details if expanded */}
                                                        {expandedRow.has(i) && data?.fund_details?.length > 0 && (
                                                            data?.fund_details?.map((fund, idx) => (
                                                                <tr key={idx} className="border-b">
                                                                    <td className="w-[54%] whitespace-nowrap font-medium py-4 px-5 text-left">
                                                                        <div className="leading-8 flex items-center ml-12">
                                                                            {/* {data?.logo_path === "default.png" || !data?.logo_path ? (
                                                                                <RandomLogo name={fund.fundName} />
                                                                            ) : (
                                                                                <CompanyLogo name={data?.investor_name} id={data?.investor_id} page="company page" logo={data?.logo_path} />
                                                                            )} */}
                                                                            <Link to={isPE ? routeNames.peInvestorById(data?.investor_id) : routeNames.vcInvestorById(data?.investor_id)}>
                                                                                {fund.fundName}
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                    <td className="whitespace-nowrap w-[10%] py-4 pr-[2.3rem] text-right">
                                                                        {fund.Amount_INR === 0 ? " " : fund.Amount_INR}
                                                                    </td>
                                                                    <td className="whitespace-nowrap w-[13%] py-4 pr-[2.2rem] text-right">
                                                                        {fund.Amount_M === 0 ? " " : fund.Amount_M}
                                                                    </td>
                                                                    <td className="whitespace-nowrap w-[10%] py-4 pr-[2.2rem] text-right">
                                                                        {" "}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )}
                                                    </React.Fragment>
                                                ))} 
                                            </tbody>
                                            :
                                            <div className="w-full bg-[#FFFFFF]">
                                                <center>
                                                    <div role="status" className=" h-[8rem]">
                                                        <svg className="relative top-[4rem] left-[17rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                        </svg>
                                                    </div>
                                                </center>
                                            </div>

                                        }
                                    </table>)}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

    )
}

export default InvestorInfo