import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import rightArrow from '../images/Group 53/png/Group 53.png';
import leftArrow from '../images/Group 53 Copy/png/Group 53 Copy.png';
import './pagination.css';
import { NavLink } from 'react-router-dom';
import useModule from '../hooks/useModule';
import { routeNames } from '../routeSegments';

/** The code defines a React functional component called `Paginate`. It takes several props as input,
including `onPageChange`, `totalCount`, `siblingCount`, `currentPage`, `pageSize`, `className`, and
`selectdButtonClassName`. It is responsible for
    rendering a pagination component with previous and next buttons, page numbers, and dots for
    indicating skipped pages. */
const Paginate = ({
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    selectdButtonClassName = "bg-[#EDEDED]",
    from = null,
    investorId
}) => {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    /**
     * The `onNext` function increments the `currentPage` state by 1 and calls the `onPageChange`
     * function.
     */
    const onNext = () => {
        onPageChange(currentPage + 1);
    };

   /**
    * The function `onPrevious` is used to navigate to the previous page by calling the `onPageChange`
    * function with the current page number decreased by 1.
    */
    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    console.log(currentPage,
        totalCount,
        siblingCount,
        pageSize, "paginationRange")

    let lastPage = paginationRange[paginationRange?.length - 1];

    const { isPE, isVC } = useModule(); 
    
    const determineRoute = (id) => {
        if(from == "investments"){
            return isPE ? routeNames.peInvestorPortfolioById(id) : routeNames.vcInvestorPortfolioById(id);
        } else if(from == "exits"){
            return isPE ? routeNames.peInvestorPortfolioExitsById(id) : routeNames.vcInvestorPortfolioExitsById(id);
        }
    }

    return (
        <center>
            <ul
                className={classnames('pagination-container ', { [className]: className })}
            >
                {currentPage === 1 ?
                    <li
                        className={"cursor-not-allowed pagination-item"}
                    >
                        <img src={leftArrow} className="arrow-diable" alt='' />
                    </li>
                    :
                    <li
                        className={"cursor-pointer pagination-item"}
                        onClick={onPrevious}
                    >
                        <img src={leftArrow} className="arrow-default" alt='' />
                    </li>
                }
                {paginationRange.map(pageNumber => {
                    // alert(currentPage)
                    console.log(currentPage)
                    if (pageNumber === DOTS) {
                        return <li className="pagination-item dots">&#8230;</li>;
                    }

                    return (
                        <li
                            className={classnames('pagination-item', {
                                selected: pageNumber === currentPage
                            }) + "m-1 py-1 px-2 cursor-pointer " + (currentPage === pageNumber ? selectdButtonClassName : "")}

                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </li>
                    );
                })}
                {from !== null && (
                    <li
                        className="pt-1 px-2 cursor-pointer"
                    >
                        <NavLink to={determineRoute(investorId)} target='_blank'>
                            All
                        </NavLink>
                    </li>
                )}
                {currentPage === lastPage ?
                    <li
                        className={"cursor-not-allowed pagination-item"}
                    >
                        <img src={rightArrow} className="arrow-diable" alt='' />
                    </li>
                    :
                    <li
                        className={"cursor-pointer pagination-item"}
                        onClick={onNext}
                    >
                        <img src={rightArrow} className="right-arrow-default" alt='' />
                    </li>
                }
            </ul>
        </center>
    );
};

export default Paginate;
