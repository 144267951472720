import React, { useState, useMemo, useEffect, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import { RaiseRequestContext } from "../../Contexts/RaiseRequestContext";
import { UserContext } from "../../Contexts/UserContext";
import Paginate from "../Paginate";
import ConfirmPopup from "../../Utils/ConfirmPopup";
import { useSortBy, useTable, usePagination } from "react-table";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import SortIcon from "mdi-react/SortIcon";
import Loader from "../Loader";
import useModule from "../../hooks/useModule";
import Constants from "../../Constants";
import ExcelDownloader from "../shared/ExcelDownloader";
import { directorsExport, exportExcel } from "../../Config/config";
import FormMCSite from "../CFS/DetailsPage/CFSFinancial/FormMCSite";

const AssociateCompanies = ({ companyName, data, cin }) => {
    const [directors, setDirectors] = useState([]);
    const [mcaRoles, setMcaRoles] = useState([]);
    const [directorFullName, setDirectorFullName] = useState("");
    const { din } = useParams();
    
    useEffect(() => {
        if (data?.directorData) {
            setDirectors(data.directorData);
            const director = data.directorData.find(d => d.DIN === din || d.din === din);
            if (director) {
                const fullName = [director.FirstName, director.MiddleName, director.LastName]
                    .filter(Boolean)
                    .join(" ");
                setDirectorFullName(fullName);
                
                if (director.MCAUserRole) {
                    setMcaRoles(director.MCAUserRole);
                }
            }
        }
    }, [data, din]);

    const [isExport, setIsExport] = useState();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const { openRequestPopup } = useContext(RaiseRequestContext);
    const [isLoading, setIsLoading] = useState(false);
    const { companyId } = useParams();
    const { isTrialExport, isStudent } = useContext(UserContext);
    const [alert, setalert] = useState({ message: "", show: false });
    const [selected, setSelected] = useState(false);
    const { primaryMasterId } = useModule();

    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" });
    };

    const formatDate = (date) => {
        if (!date) return "-";
        return moment(date).format("DD/MM/YY"); 
    };

    const columns = useMemo(
        () => [
            {
                Header: "Sr.no",
                accessor: (row, index) => index + 1,
                className: "w-[4%] text-center",
                headerClassName: "text-center",
            },
            {
                Header: "CIN/FCRN",
                accessor: "cin",
                className: "w-[15%]",
                headerClassName: "",
            },
            {
                Header: "Company Name",
                accessor: "companyName",
                className: "w-[31%]",
                headerClassName: "",
            },
            {
                Header: "Designation",
                accessor: "designation",
                className: "w-[20%]",
                headerClassName: "",
            },
            {
                Header: () => (
                    <div>
                        Original Date of<br />Appointment
                    </div>
                ),
                accessor: "roleEffectiveDate",
                className: "w-[10%] text-center",
                headerClassName: "text-center",
                Cell: ({ value }) => formatDate(value)
            },
            {
                Header: () => (
                    <div>
                        Date of Appointment at<br />Current Designation
                    </div>
                ),
                accessor: "currentDesignationDate",
                className: "w-[10%] text-center",
                headerClassName: "text-center",
                Cell: ({ value }) => formatDate(value)
            },
            {
                Header: () => (
                    <div>
                        Date of Cessation<br />(if applicable)
                    </div>
                ),
                accessor: "cessationDate",
                className: "w-[10%] text-center",
                headerClassName: "text-center",
                Cell: ({ value }) => formatDate(value) || "-"
            }
        ],
        []
    );

    const rowData = useMemo(() => {
        const rows = mcaRoles || [];
        setshowXlDownload(rows.length > 0);
        return rows;
    }, [mcaRoles]);

    const tableInstance = useTable(
        {
            columns,
            data: rowData,
            initialState: {
                pageSize: Constants.noOfRowPerPage,
                pageIndex: 0,
            },
        },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize },
    } = tableInstance;

    const NodataComponent = () => {
        return (
            <div className="flex flex-col items-center justify-center h-14">
                <p className="px-5 text-[14px] mb-2">
                    No MCA roles found for this director.{"  "}
                    <button
                        className="text-[#A5711A] hover:underline"
                        onClick={() => openRequestPopup("director", "director")}
                    >
                        Click Here
                    </button>
                    {" "}to double check with Venture Intelligence on this.
                </p>
            </div>
        );
    };

    return (
        <div className="mx-4">
            <div className="bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="px-5 pt-4">
                    <div className="flex flex-col mb-3">
                        <div className="flex justify-between items-center gap-4 ">
                            <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold lg:leading-[22px] mb-1">
                                {directorFullName} ({din})
                            </h3>
                            {isTrialExport !== "false" &&
                            isStudent === "false" &&
                            showXlDownload && (
                                <span>
                                    <ExcelDownloader
                                        url={directorsExport}
                                        fileName={`${companyName}_Director(${directorFullName})`}
                                        data={{
                                            "cin": cin,
                                            "is_din":true,
                                            "din" : din
                                        }}
                                    />
                                </span>
                            )}
                        </div>
                        <h4 className="text-[12pt] leading-[16pt] text-[#666666] lg:text-[14px]">
                            List of associated companies
                        </h4>
                    </div>
                </div>
                <div className="space-y-6">
                    <div className="bg-white">
                        <>
                            <div className="overflow-x-auto scrollbar relative">
                                {rows?.length > 0 ? (
                                    <table className="datatable w-full" {...getTableProps()}>
                                        <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-bold py-4 px-0 text-center">
                                            {headerGroups.map((headerGroup) => (
                                                <tr
                                                    key={headerGroup.id}
                                                    {...headerGroup.getHeaderGroupProps()}
                                                >
                                                    {headerGroup.headers.map((column) => (
                                                        <th
                                                            {...column.getHeaderProps([
                                                                {
                                                                    className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                                    style: column.headerStyle,
                                                                },
                                                                column.getSortByToggleProps(),
                                                            ])}
                                                            title=""
                                                        >
                                                            <div className="flex">
                                                                <span className={`${column.headerspanClassName || ''}`}>
                                                                    {column.render("Header")}
                                                                </span>
                                                                {column.disableSortBy ? (
                                                                    ""
                                                                ) : column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <SortAscendingIcon
                                                                            size={16}
                                                                            className="mt-[0.15rem] lg:mt-0"
                                                                        />
                                                                    ) : (
                                                                        <SortDescendingIcon
                                                                            size={16}
                                                                            className="mt-[0.15rem] lg:mt-0"
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <SortIcon
                                                                        size={16}
                                                                        className="mt-[0.15rem] lg:mt-0"
                                                                    />
                                                                )}
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody
                                            {...getTableBodyProps()}
                                            className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left"
                                        >
                                            {page.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()} className="border-b">
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps([
                                                                        {
                                                                            className: `data-td-animate px-5 py-4 ${cell.column.className}`,
                                                                            style: cell.column.style,
                                                                        },
                                                                    ])}
                                                                >
                                                                    {cell.render("Cell")}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {isLoading ? (
                                <div className="flex flex-col items-center justify-center h-40">
                                    <Loader />
                                </div>
                            ) : rows?.length <= 0 ? (
                                <NodataComponent />
                            ) : (
                                <></>
                            )}
                            {rows?.length <= Constants.noOfRowPerPage ? (
                                <></>
                            ) : (
                                <Paginate
                                    currentPage={pageIndex + 1}
                                    totalCount={rows?.length}
                                    pageSize={pageSize}
                                    onPageChange={(page) => {
                                        gotoPage(page - 1);
                                    }}
                                />
                            )}
                            {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssociateCompanies;