import React from 'react'
import CSFDetailInfo from '../../Components/CompanySeekingFunding/CSFDetailInfo'
import RefineSearchBar from '../../Components/RemovedArrowRefineSearch'

const CSFDetailPage = () => {
  return (
    <div className='bg-gray-100'>
          <div className="flex min-h-screen h-screen flex-col">
            <div className="flex min-h-0 flex-1 overflow-hidden">
              <main className="min-w-0 flex-1 border-t border-gray-200 flex">
                <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                    <CSFDetailInfo />
                </section>
                {/* <aside className="order-first block flex-shrink-0">
                  <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                    <RefineSearchBar />
                  </div>
                </aside> */}
              </main>
            </div>
            <br />
          </div>
      </div>
  )
}

export default CSFDetailPage