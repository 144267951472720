import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../../Contexts/UserContext";
import { getSubscriptionDetails } from "../../Config/config"
import Constants from '../../Constants';
import axios from 'axios';
import { RaiseRequestContext } from '../../Contexts/RaiseRequestContext'
import Loader from '../Loader';

/* The code snippet is defining a functional component named `SubscriptionPages`. */
export default function SubscriptionPages() {
    const [subscriptionData, setSubscriptionData] = useState([])
    const { getToken, logout } = useContext(UserContext);
    const { setOpen, openRequestPopup } = useContext(RaiseRequestContext)
    const [allowedModulesList, setAllowedModulesList] = useState([]);
    const [pointOfContact, setPointOfContact] = useState([])
    const _ = require('lodash');

    /* The above code is using the useEffect hook in React to make an API call using the axios library.
    It sends a POST request to the `getSubscriptionDetails` endpoint with the necessary headers,
    including an authorization token obtained from the `getToken()` function. */
    useEffect(() => {
        axios(`${getSubscriptionDetails}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                if (response?.data?.status == 200) {
                    console.log(response, 'user profile response');
                    setSubscriptionData(response?.data)
                    setPointOfContact(response?.data?.point_of_contact)
                }
            })
            .catch((error) => {
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                }
                console.log("API not working", error);
            });

    }, [])

    /* The `useEffect` hook in React is used to perform side effects in functional components. In this
    case, the `useEffect` hook is used to retrieve data from the browser's local storage and update
    the state variable `allowedModulesList` with the retrieved data. */
    useEffect(() => {
        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        setAllowedModulesList(JSON.parse(allowedModulesStr) ?? []);
    }, []);
    const filteredPointOfContact = pointOfContact?.map((contact, index) => {
        const contactType = Object.keys(contact)[0];
        const contactData = contact[contactType];
    
        
        if (contactType === "cfs") {
            const peData = pointOfContact.find(item => Object.keys(item)[0] === "pe")?.pe || [];
            return {
                [contactType]: contactData.map(group =>
                    group.filter(item =>
                        !peData.some(peGroup =>
                            peGroup.some(peItem => 
                                peItem.label === item.label && peItem.value === item.value
                            )
                        )
                    )
                ).filter(group => group.length > 0) 
            };
        }
    
        return contact;
    }).filter(contact => {
        const contactType = Object.keys(contact)[0];
        return contact[contactType].length > 0; 
    });

    
    return (
    
        <div className='lg:mt-5'>
            <div className="bg-white shadow rounded-[4px] "> 
                <div className="grid grid-cols-12 py-3 px-4 border-b border-[#E3E3E3]">
                    <div className=" col-span-2">
                        <p
                            className="  font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                            Name</p>
                    </div>
                    <div className="col-span-5 ">
                        <p
                            className="whitespace-nowrap  font-sans_book_body  text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                            <span className="text-[#333333] font-sans_medium_body">{subscriptionData?.user?.name}</span> 
                        </p>
                    </div>
                </div>
                <div className="lg:grid lg:grid-cols-12 px-4 pt-4 space-y-3 lg:space-y-0 border-b border-[#E3E3E3]">
                    <div className="lg:col-span-2">
                        <p className="font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px] whitespace-nowrap">Your Plan</p>
                    </div>
                    <div className="lg:col-span-6">
                        <div className="">
                            <p
                                className="  font-sans_book_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                Databases included in Subscription</p>
                            <div className="py-2 space-x-4">
                                {subscriptionData?.primary_type?.map((type) => {
                                    return (
                                        <span className="px-3 py-0.5 font-sans_medium_body border border-[#CCCCCC] inline-flex items-center  rounded-xl text-[#333333] leading-[16px] text-[13px] uppercase bg-[#F1CA75]">                                            
                                            {type}
                                        </span>
                                    )

                                })}
                            </div>


                        </div>
                    </div>
                    <div className="lg:col-span-4">
                        <div className="lg:mt-6 lg:text-right text-center">
                            <span className="font-SequelSans text-[#BC8B20] text-[14px] leading-[17px] tracking-[-0.3px] ">
                                <span
                                    className="text-[#666666] text-[14px] leading-[17px]">Upgrade
                                    Subscription, </span><span className='cursor-pointer' onClick={() => { setOpen(true); openRequestPopup('subscription', 'sales') }}>Raise Request</span> </span>
                        </div>
                    </div>
                </div>

                {/* <div className="border-t border-[#E3E3E3] mt-4 lg:mb-2"></div> */}

                {/* <div className="grid grid-cols-6 py-3 px-4">
                    <div className="grid-cols-3 ">
                        <p
                            className=" font-sans_book_body   text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                            Billing</p>
                    </div>
                    <div className=" col-span-3 ">
                        <p
                            className="  font-sans_book_body  text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                            Subscription Period: <span className="text-[#333333] font-sans_medium_body">
                            {subscriptionData?.subscription_period ? subscriptionData?.subscription_period:"--"}</span>
                            <p
                                className="py-2  font-sans_book_body  text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px] ">
                                Next Payment Due: <span className="text-[#333333] font-sans_medium_body">{subscriptionData?.subscription_period ? subscriptionData?.subscription_period:"--"}</span></p>
                        </p>
                    </div>
                    <div
                        className=" col-span-2 ">
                        <div className='text-right'>
                            <a href="#"
                                className=" font-sans_book_body text-[#BC8B20] text-[14px] leading-[17px] tracking-[-0.3px]">Update
                                Subscription</a>
                        </div>
                    </div>
                </div>
                <div className="border-t"></div> */}
            {
                allowedModulesList.includes("cfs") && (
                    <div className="grid grid-cols-12 py-3 px-4 border-b border-[#E3E3E3]">
                        <div className=" col-span-2">
                            <p
                                className="  font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                Custom Request</p>
                        </div>
                        <div className="col-span-5">
                            <p
                                className="whitespace-nowrap  font-sans_book_body  text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                Request left <span className="text-[#333333] font-sans_medium_body">{subscriptionData?.request_left ? subscriptionData?.request_left: 0} / {subscriptionData?.request_limit ? subscriptionData?.request_limit: 0}.</span> Do you want to increase the request limit?
                            </p>
                        </div>
                        <div
                            className=" col-span-5">
                            <div className='text-right'>
                                <span className=" font-sans_book_body text-[#BC8B20] text-[14px] leading-[17px] tracking-[-0.3px] cursor-pointer"
                                    onClick={() => { setOpen(true); openRequestPopup('subscription', 'sales') }}>
                                    Raise Request</span>
                            </div>
                        </div>
                    </div>
                )
            }
            </div>
            {pointOfContact?.length > 0 &&
            <div className="bg-white shadow p-6">
                <p className="text-[16px] text-[#2B2A29] leading-[19px] tracking-[-0.34px] font-sans_medium_body">Point of Contact (s)</p>
                <dl className="space-y-10 md:space-y-0 overflow-x-auto scrollbar-remove">
                    <div className="min-w-full align-middle">
                        {/* <p className="text-[16px] text-[#2B2A29] leading-[19px] tracking-[-0.34px] font-sans_medium_body">Point of Contact (s)</p> */}
                        <div className='lg:w-full md:w-[150%] w-[200%]'>
                        <div className='overflow-x-auto overflow-y-hidden lg:grid lg:grid-cols-12 w-full flex divide-x mt-2'>
                        {allowedModulesList.includes("pe") && allowedModulesList.includes("cfs") ? (
                            // Show both PE and CFS if both modules are allowed
                            pointOfContact?.length > 0 ? (
                                filteredPointOfContact.map((contact, contactIndex) => {
                                    const contactType = Object.keys(contact)[0];
                                    const contactData = contact[contactType];

                                    return (
                                        <div 
                                            key={contactIndex} 
                                            className={` 
                                                ${filteredPointOfContact?.length === 2 ? 'lg:col-span-6 w-[100%] lg:w-full' : 'lg:col-span-12 w-full'} 
                                                ${contactIndex === 1 ? 'pl-10' : ''}
                                            `}
                                        >
                                            {/* Contact Type Header */}
                                            {/* <div className="text-lg font-bold mb-4 uppercase">{contactType}</div> */}
                                            <div className="lg:grid lg:grid-cols-6 px-4 py-4 space-y-3 lg:space-y-0 ">
                                                <div className="lg:col-span-2">
                                                    <p className="font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px] whitespace-nowrap">Product</p>
                                                </div>
                                                <div className=" space-x-4 flex ">
                                                {
                                                    subscriptionData?.primary_type?.map((type) => {
                                                        // Check the contactType and filter accordingly
                                                        if (contactType === 'pe' && (type === 'pe' || type === 'vc' || type === 're' || type === 'm&a')) {
                                                            return (
                                                                <span className="px-3 py-0.5 font-sans_medium_body border border-[#CCCCCC] inline-flex items-center rounded-xl text-[#333333] leading-[16px] text-[13px] uppercase bg-[#F1CA75]" key={type}>
                                                                    {type}
                                                                </span>
                                                            );
                                                        }
                                                        if (contactType === 'cfs' && type === 'cfs') {
                                                            return (
                                                                <span className="px-3 py-0.5 font-sans_medium_body border border-[#CCCCCC] inline-flex items-center rounded-xl text-[#333333] leading-[16px] text-[13px] uppercase bg-[#F1CA75]" key={type}>
                                                                    {type}
                                                                </span>
                                                            );
                                                        }
                                                        return null; // Return null for types that shouldn't be displayed
                                                    })
                                                }
                                                </div>
                                            </div>

                                            {/* Render Groups */}
                                            {contactData.map((contactGroup, groupIndex) => (
                                                <div 
                                                    key={groupIndex} 
                                                    className={`max-h-[18rem] min-w-fit ${groupIndex > 0 ? 'mt-6' : ''}`}
                                                >
                                                    <div className="flex flex-col gap-4 bg-gray-50 p-4 rounded-md shadow-sm">
                                                        {/* Render Items */}
                                                        {contactGroup.map((contactItem, itemIndex) => (
                                                            <div 
                                                                key={itemIndex} 
                                                                className="flex py-1 grid grid-cols-3  gap-3 lg:gap-0"
                                                            >
                                                                <div className=" font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px] whitespace-nowrap">
                                                                    {contactItem.label}
                                                                </div>
                                                                <div className=" font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                                                    {contactItem.value}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="bg-white col-span-12 flex items-center justify-center h-40">
                                    <Loader />
                                </div>
                            )
                        ) : allowedModulesList.includes("pe") ? (
                            // Show only PE if PE is allowed but CFS is not
                            pointOfContact?.length > 0 ? (
                                filteredPointOfContact.filter(contact => Object.keys(contact)[0] === "pe").map((contact, contactIndex) => {
                                    const contactType = Object.keys(contact)[0];
                                    const contactData = contact[contactType];

                                    return (
                                        <div 
                                            key={contactIndex} 
                                            className={` 
                                                ${filteredPointOfContact?.length === 2 ? 'lg:col-span-6 w-[100%] lg:w-full' : 'lg:col-span-12 w-full'} 
                                                ${contactIndex === 1 ? 'pl-10' : ''}
                                            `}
                                        >
                                            {/* Contact Type Header */}
                                            {/* <div className="text-lg font-bold mb-4 uppercase">{contactType}</div> */}

                                            {/* Render Groups */}
                                            {contactData.map((contactGroup, groupIndex) => (
                                                <div 
                                                    key={groupIndex} 
                                                    className={`max-h-[18rem] min-w-fit ${groupIndex > 0 ? 'mt-6' : ''}`}
                                                >
                                                    <div className="flex flex-col gap-4 bg-gray-50 p-4 rounded-md shadow-sm">
                                                        {/* Render Items */}
                                                        {contactGroup.map((contactItem, itemIndex) => (
                                                            <div 
                                                                key={itemIndex} 
                                                                className="flex py-1 grid grid-cols-3  gap-3 lg:gap-0"
                                                            >
                                                                <div className="font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px] whitespace-nowrap">
                                                                    {contactItem.label}
                                                                </div>
                                                                <div className="font-sans_medium_body  text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                                                    {contactItem.value}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="bg-white col-span-12 flex items-center justify-center h-40">
                                    <Loader />
                                </div>
                            )
                        ) : allowedModulesList.includes("cfs") ? (
                            // Show only CFS if CFS is allowed but PE is not
                            pointOfContact?.length > 0 ? (
                                filteredPointOfContact.filter(contact => Object.keys(contact)[0] === "cfs").map((contact, contactIndex) => {
                                    const contactType = Object.keys(contact)[0];
                                    const contactData = contact[contactType];

                                    return (
                                        <div 
                                            key={contactIndex} 
                                            className={` 
                                                ${filteredPointOfContact?.length === 2 ? 'lg:col-span-6 w-[100%] lg:w-full' : 'lg:col-span-12 w-full'} 
                                                ${contactIndex === 1 ? 'pl-10' : ''}
                                            `}
                                        >
                                            {/* Contact Type Header */}
                                            {/* <div className="text-lg font-bold mb-4 uppercase">{contactType}</div> */}

                                            {/* Render Groups */}
                                            {contactData.map((contactGroup, groupIndex) => (
                                                <div 
                                                    key={groupIndex} 
                                                    className={`max-h-[18rem] min-w-fit ${groupIndex > 0 ? 'mt-6' : ''}`}
                                                >
                                                    <div className="flex flex-col gap-4 bg-gray-50 p-4 rounded-md shadow-sm">
                                                        {/* Render Items */}
                                                        {contactGroup.map((contactItem, itemIndex) => (
                                                            <div 
                                                                key={itemIndex} 
                                                                className="flex py-1 grid grid-cols-6  gap-3 lg:gap-0"
                                                            >
                                                                <div className="font-sans_book_body text-[#666666] text-[14px] tracking-[-0.3px] leading-[17px]">
                                                                    {contactItem.label}:
                                                                </div>
                                                                <div className="font-sans_medium_body text-[#333333] text-[14px] tracking-[-0.3px] leading-[17px]">
                                                                    {contactItem.value}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="bg-white col-span-12 flex items-center justify-center h-40">
                                    <Loader />
                                </div>
                            )
                        ) : null}
                        </div>
                        </div>
                    </div>
                </dl>
            </div>
            }
        </div>        
    )
}