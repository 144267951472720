import { Badge, Tooltip } from 'antd'
import React, { useState, useContext } from 'react'
import { FaInfoCircle } from 'react-icons/fa';
import { BsArrowRightSquareFill } from "react-icons/bs";
import DefinitionModal from '../DefinitionModal';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import { useLocation } from "react-router";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { IoClose } from 'react-icons/io5';



/**
 * The `BasedFilterWrapper` function is a React component that renders a button with a label, count, and an
 * arrow icon that can be rotated based on the `open` prop.
 * @returns a button element with a label and an icon. The appearance of the button depends on the
 * values of the `open`, `count`, and `isSelect` variables. If `open` is true, the button displays a
 * downward arrow icon. If `open` is false, the button displays a count badge (if `count` is greater
 * than 0 and `isSelect`
 */
function BasedFilterWrapper({ label,open,  count, isSelect, onClick , disabled, definitionInfo,customPathname, menu}) {
    // const {dropdown,setDropdown} = useContext(PeFilterContext);
    // const [open, setOpen] = useState(true);
    const [dropdown,setDropdown] = useState(false);
  const { refineSearchFilters, open : btnOpen, setOpen, isMobile, setIsMobile } = useContext(PeFilterContext);

    
    const [showDefModal, setShowDefModal] = useState(false);
    
    const openModal = () =>{
        setShowDefModal(true);
    }
    
    const closeModal = () =>{
        setShowDefModal(false);
    }

    const {SwitchState , setSwitchState} = useContext(PeFilterContext);

    const toggleSwitchState = () => {
        setSwitchState(prevState => prevState === "and" ? "or" : "and");
    };
    const handleToggle = () => {
        setDropdown(!dropdown);
        console.log(dropdown , "jgjhg")
    };
    const location = useLocation().pathname;

    const condn = location.includes("/pe/") && label === "Investor" && !location.includes("/pe/investors") || location.includes("/vc/")  && label === "Investor" && !location.includes("/vc/investors")

    return (
        <>
            <button
                type="button"
                className="w-full text-left"
                disabled={disabled}
                onClick={condn ? undefined : onClick}
            >
                <div className="flex items-center justify-between">
                <div className="flex items-center pl-1 pr-3 py-1 justify-between">
                        <h2 className="text-xl font-bold text-white">
                            <div style={{display:!open?"flex":"flex"}} className="text-[16px] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer  flex-row" onClick={handleToggle}>
                                <span className="mt-[2px]">
                                    {dropdown  ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                </span>
                                <span className="ml-1">{label}</span>
                            </div>
                        </h2>
                        {menu === "ioc" && (

                        <button onClick={() => setOpen(!btnOpen)}>
                        {!isMobile && (
                                <BsArrowRightSquareFill
                                    style={{
                                        transform: !btnOpen ? "rotate(180deg)" : "none",
                                        height: 24,
                                        width: 24,
                                        color: "#bc8b20",
                                        borderRadius: 4,
                                    }}
                                />
                        )}
                        {!btnOpen && isMobile && <IoClose color="#bc8b20" size={25} className="" />}
                            </button>
                        )}

                    </div>
            </div>
            </button>
            {showDefModal && <DefinitionModal label={label} definitionInfo={definitionInfo} onClose={closeModal}/>}
        </>
    )
}

export default BasedFilterWrapper