import React, { useContext, useState } from 'react'
import Logo from '../../images/vi-logo.png';
import Laptop from '../../images/laptop.png';
import RingTop from '../../images/ring1.png';
import RingBottom from '../../images/ring3.png';
import Mail from '../../images/mailicon.png';
import Lock from '../../images/lock-icon.png';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../../routeSegments';
import axios from 'axios';
import { submitDeviceAuthUrl } from '../../Config/config';
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';

const DeviceAuthorization = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState();
    const { setUser, setAccessData } = useContext(UserContext);
    const [authCode,setAuthCode] = useState('')
    const redirectTo = useNavigate();

    const location = useLocation();

    //useForm
    const { register, handleSubmit,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        defaultValues: {
            email: location.state?.email,
            // authCode: location.state?.authCode
            authCode: ''
        }
    });

    /**
     * The function `onSubmit` is used to submit device authentication data and handle the response.
     * @param data - An object containing the form data, including the email and authCode.
     * @returns The function `onSubmit` returns nothing.
     */
    const onSubmit = (data) => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        axios.post(submitDeviceAuthUrl, {
            "email": data.email,
            "password": location.state?.password,
            "loginFrom": location.state?.loginFrom,
            "device_auth_id": location.state?.deviceId,
            "authCode": data.authCode
            // "authCode": authCode

        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                if (response.data?.success === true) {
                    const responseData = response.data?.data;
                    setAccessData({
                        email: data.email,
                        accessToken: responseData?.access_token,
                        userId: responseData?.user_id,
                        allowedModules: responseData?.allowed_modules
                    })
                    localStorage.setItem(Constants.localStorageKeys.loggedInEmail, data?.email)
                    localStorage.setItem(Constants.localStorageKeys.accessToken, response.data.data.access_token)
                    localStorage.setItem(Constants.localStorageKeys.userId, response.data.data.user_id)
                    localStorage.setItem(Constants.localStorageKeys.allowedModules, JSON.stringify(response.data.data.allowed_modules))
                    localStorage.setItem(Constants.localStorageKeys.studentLogin, response.data.data.studentLogin)
                    localStorage.setItem(Constants.localStorageKeys.isExport, response.data.data.isExport)
                    localStorage.setItem(Constants.localStorageKeys.isDirectoryUser, response.data.data.isDirectoryUser);
                    setUser((user) => ({
                        name: data?.email,
                        isAuth: true,
                    }));
                    redirectTo(response.data?.data?.redirectUrl)
                } else {
                    setLoginErrorMsg(response.data?.message)
                }
            }
        }).catch(error => {
            setIsLoading(false);
        })
    }



    // if (location.state?.deviceId == null) {
    //     return <Navigate to={routeNames.login} replace />
    // }



    return (
        <div>
            <div className="inset-0 h-full w-full object-cover">
                <div className="h-max ">
                    {/* <!-- left side--> */}
                    <div className="grid grid-cols-2">
                        <div className=" lg:col-span-1 hidden lg:block h-screen  lg:bg-[#DBB25B]">
                            <div className="relative">
                                <img src={RingTop} className="absolute w-[130px] ml-[62%] xl:ml-[65.25%] xl:mt-[10%] mt-[9%]" alt="" />
                                <img src={RingBottom} className="absolute mt-[48%] ml-[12.5%] w-[60px]" alt="" />
                                <img src={Laptop} className="absolute m-[16%] " alt="" />
                            </div>
                        </div>
                        {/* <!--right side--> */}
                        <div className="lg:col-span-1 col-span-2 mt-[3rem] px-6 md:px-12 lg:px-22 xl:px-24">
                            <div className="mx-auto w-full max-w-screen-sm lg:w-96 lg:px-8">
                                <div className="">
                                    <img className="w-60 mx-auto" src={Logo} alt="vi" />
                                    <p className="text-center text-[#333333] mt-6 text-[18px] lg:text-[12px] tracking-[-0.24pt] lg:text-[#969696] font-sans_book_body ">
                                        Please await email from Venture Intelligence (in your registered email id) containing your authorization code.
                                        Please check your spam/junk folder also.</p>
                                    <h2 className="mt-4 text-[24px] lg:text-[16px] text-center tracking-[-0.31pt] font-sans_book_body font-semibold leading-[26.4px] text-[#333333]">
                                        Device Authorization
                                    </h2>
                                </div>

                                {/* <!--form start--> */}
                                <div className="mt-8">
                                    <div className="mt-6">
                                        <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="space-y-1">
                                                <div className="mb-2 ">
                                                    <label htmlFor="email" className="block font-sans_book_body text-[14px] text-[#2B2A29] tracking-[-0.3px] leading-[17px]"> Email address </label>

                                                </div>
                                                <div  >
                                                    <label className="relative block" >
                                                        <input
                                                            {...register("email", {
                                                                required: "Please enter your email address",
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                    message: "Invalid email address"
                                                                }
                                                            })}
                                                            id="email"
                                                            type="email"
                                                            autoComplete="off"
                                                            className="form-input shadow-sm  bg-[#FDF7E9] focus:outline-none rounded-[5px] focus:ring-[#BC8B20] border border-[#BC8B20] py-1.5 px-2 w-full block " />
                                                        <img alt='' src={Mail} className="  absolute top-1/2 transform -translate-y-1/2 right-3" />

                                                    </label>
                                                    <p className="text-[red] text-[12px]">{errors.email?.message}</p>
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <div className="flex items-center mb-2 justify-between">
                                                    <label htmlFor="password" className="block font-sans_book_body  text-[14px] text-[#2B2A29] tracking-[-0.3px] leading-[17px]"> Authorization Code </label>
                                                </div>
                                                <div  >
                                                    <label className="relative block">
                                                        <input  {...register("authCode", {
                                                            required: "Please enter your authorization code",
                                                        })}
                                                            type="text" autoComplete="off"
                                                            onChange={(e)=>setAuthCode(e.target.value)}
                                                            className="form-input shadow-sm  bg-[#FDF7E9] focus:outline-none rounded-[5px] focus:ring-[#BC8B20] border border-[#BC8B20] py-1.5 px-2 w-full block  " />
                                                        <img alt='' src={Lock} className=" absolute top-1/2 transform -translate-y-1/2 right-3" />
                                                    </label>
                                                    <p className="text-[red] text-[12px]">{errors.authCode?.message}</p>
                                                </div>
                                            </div>
                                            {loginErrorMsg && <p className="text-[red] text-[16px]">{loginErrorMsg}</p>}
                                            <div>

                                                <button type="submit"
                                                    disabled={isLoading}
                                                    className="w-full flex justify-center text-left py-2.5 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] tracking-[-0.3px] leading-[17px] bg-[#A5711A] hover:bg-[#BC8B20]  focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                                                    {isLoading ? "Authorizing..." : "Authorize"}</button>

                                            </div>
                                        </form>
                                        {/* <!--form end--> */}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default DeviceAuthorization

