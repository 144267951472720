import React, { useState, useEffect, useContext } from "react"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MonthPickerComponent from '../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../Contexts/TableDownloadContext'
import moment from "moment"
import line from "../../images/line.png"
import xls from "../../images/xls/png/Group 47.png"
import FileIcon from "../../images/file.png"
import FileIconBlur from "../../images/fileblur.png"
import ExcelPopup from "../../Components/ExcelPopup";
import '../MainMenu.css'
import { useLocation } from "react-router-dom";
import { routeNames } from "../../routeSegments";
import Switch from '../../Components/CFS/DetailsPage/CFSFinancial/ToggleSwitch'
import ToggleButton from "../RefineSearchComponents/ToggleSwitch";

import ExportPopup from "../../Components/ExportPopup/FundsExcel";
import { data, limitedPartnerProfileExportData, limitedPartnerexitExportData } from "../../Components/ExportPopup/ExportData"
import { cfsExcelExport, exportLPExcel } from "../../Config/config";
import { TableDataContext } from '../../Contexts/TableDataContext'
import PdfDownloader from "../shared/PdfDownloader";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import Constants from "../../Constants";
import { UserContext } from "../../Contexts/UserContext";
import { FaFilter } from "react-icons/fa";
import { IoClose  } from "react-icons/io5";

/**
 * The MainMenu function is a React component that renders a menu with various options and buttons,
 * including a switch for currency, a download button for exporting data to Excel, and a button for
 * downloading PDF files.
 * @param props - An object containing the properties passed to the MainMenu component.
 * @returns The MainMenu component is returning a JSX element.
 */
function MainMenu(props) {
    const { handleDownloadExcel } = useContext(TableDownloadContext)
    const {totalNoOfCompanies, setTotalNoOfCompanies,tableCompanyId,currency, setCurrency, globalSearchResult } = useContext(TableDataContext);
    const { monthPickerValue, refineSearchFilters,groupId, isMobile,setOpen,open,setIsMobile } = useContext(PeFilterContext);
    
    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ]);
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])

    const {isStudent} = useContext(UserContext)
    const { pathname } = useLocation();
    const download = () => {
        handleDownloadExcel()
    }
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const handleToggle = (isOn) => {
        setCurrency(isOn=='and' ? 'USD' :'INR')
      };
      useEffect(()=>{
        if (window.innerWidth < 800) {
            setOpen(true);
            setIsMobile(true);
        }    },[])
    return (
        <div className="pt-0 pl-3 md:pr-4 pr-3">
            <div className="scrollbar rounded">
                <div className="py-2 px-5 items-center rounded-b-none bg-[#FFFFFF] block lg:flex flex-row justify-between shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    {props.menu === "cfs" || props.menu === "ioc" ? (
                        <div className="flex flex-row space-x-5">
                            <div className="text-[14pt] lg:text-[16px] leading-[14px] font-sans_book_body text-[#666666]">
                                Total no. of companies: {isFromGlobalSearch() ? globalSearchResult?.cfs_company?.length : totalNoOfCompanies}
                            </div>
                        </div>
                    ) : null}

                    <div className="flex justify-between py-2 lg:py-0 space-x-2 items-center">
                        {props.menu !== "ioc" && (
                            <div className="pt-[.5rem] md:pt-0 lg:pt-0">
                                <Switch />
                                {/* <ToggleButton onLabel="USD" offLabel="INR" onToggle={handleToggle} /> */}
                            </div>
                        )}
                        
                        <div className="flex justify-between items-center space-x-2">
                            {isMobile && (
                                <>
                                    <button
                                        className="cursor-pointer lg:ml-7 ml-4"
                                        onClick={() => setOpen(!open)}
                                    >
                                        <FaFilter color="#bc8b20" size={21} />
                                    </button>
                                    <a>
                                        <img
                                            className="h-[24px] max-w-[2px] mt-1"
                                            src={line}
                                            alt="Separator"
                                        />
                                    </a>
                                </>
                            )}

                            <span>
                                {isStudent === 'true' || groupId?.myList?.length > 0 ? null : (
                                    <PdfDownloader
                                        url={cfsExcelExport}
                                        type="xlsx"
                                        fileName={Constants.exportNames.cfs_list}
                                        responseType="json"
                                        data={{
                                            date_range: {
                                                from_date: monthPickerValue[0],
                                                to_date: monthPickerValue[1],
                                            },
                                            company_id: tableCompanyId,
                                            is_detailed: true,
                                            currency_cd: currency,
                                            display_in: currency === 'INR' ? 'C' : 'M',
                                        }}
                                    >
                                        <img src={xls} className="h-[24px] mt-1 w-[21px] cursor-pointer" alt="Export" />
                                    </PdfDownloader>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainMenu