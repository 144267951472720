import { React, useState, createContext } from 'react'

export const TableHeaderContext = createContext('');

const TableHeaderProvider = ({ children }) => {
    const [selectedPage, setSelectedPage] = useState(1)

    const [orderBy, setOrderBy] = useState("desc")
    const [OrderByVCTableHeader, setOrderByVCTableHeader] = useState("date")
    // const [orderBya, setOrderBya] = useState("desc")
    
    
    const [OrderByPEVCExitMATableHeader, setOrderByPEVCMAExitTableHeader] = useState("date")

        
    const [OrderByPEVCExitPMTableHeader, setOrderByPEVCPMExitTableHeader] = useState("desc")
    const [OrderByVCExitMATableHeader, setOrderByVCExitMATableHeader] = useState("desc")
    const [OrderByVCExitPMTableHeader, setOrderByVCExitPMTableHeader] = useState("desc")
    const [OrderByPEVCBackedIposTableHeader, setOrderByPEVCBackedIposTableHeader] = useState("desc")
    const [OrderByVCBackedIposTableHeader, setOrderByVCBackedIposTableHeader] = useState("desc")
    const [OrderByAngelInvestmentsTableHeader, setOrderByAngelInvestmentsTableHeader] = useState("desc")
    const [OrderByIncubationTableHeader, setOrderByIncubationTableHeader] = useState("desc")
    const [orderByLP, setOrderByLP] = useState("asc")

    const [orderByInvestor, setOrderByInvestor] = useState("asc")

    const [OrderByInvestorVCTableHeader, setOrderByInvestorVCTableHeader] = useState("asc")
    // const [orderByInvestorExits, setOrderByInvestorExits] = useState("asc")
    const [OrderByInvestorPEVCExitMATableHeader, setOrderByInvestorPEVCMAExitTableHeader] = useState("last_investment_made_sort")
    const [OrderByInvestorPEVCExitPMTableHeader, setOrderByInvestorPEVCPMExitTableHeader] = useState("last_investment_made_sort")
    const [OrderByInvestorVCExitMATableHeader, setOrderByInvestorVCExitMATableHeader] = useState("asc")
    const [OrderByInvestorVCExitPMTableHeader, setOrderByInvestorVCExitPMTableHeader] = useState("asc")
    const [OrderByInvestorPEVCBackedIposTableHeader, setOrderByInvestorPEVCBackedIposTableHeader] = useState("asc")
    const [OrderByInvestorVCBackedIposTableHeader, setOrderByInvestorVCBackedIposTableHeader] = useState("asc")
    const [OrderByInvestorAngelInvestmentsTableHeader, setOrderByInvestorAngelInvestmentsTableHeader] = useState("last_investment_made_sort")
    const [OrderByInvestorIncubationTableHeader, setOrderByInvestorIncubationTableHeader] = useState("asc")

    const [orderByAdvisor, setOrderByAdvisor] = useState("asc")
    const [orderByTAdvisor, setOrderByTAdvisor] = useState("asc")
    const [orderingColumn, setOrderingColumn] = useState("date")
    const [reOrderingColumn, setreOrderingColumn] = useState("deal_date")
    const [reIpoOrderingColumn, setIporeOrderingColumn] = useState("ipo_date")
    const [exitOrderingColumn, setexitOrderingColumn] = useState("date")
    
    const [orderBya, setOrderBya] = useState("date")
    const [investororderingColumn, setInvestorOrderingColumn] = useState("investor_sort")
    
    const [advisororderingColumn, setAdvisorOrderingColumn] = useState("asc")
    const [maAdvisororderingColumn, setmaAdvisorOrderingColumn] = useState("latest_deal_date_sort")
    const [limitedPartnerderingColumn, setLimitedPartnerderingColumn] = useState("lp_name_sort")
    
    const [incubatorOrderingColumn, setIncubatorOrderingColumn] = useState("Incubator_sort")
    
    const [orderByInvestorExits, setOrderByInvestorExits] = useState("last_investment_made_sort")
    // const [orderByInvestorExits, setOrderByInvestorExits] = useState("last_investment_made_sort")
    
    
    const [cfsOrderingColumn, setCfsOrderingColumn] = useState("fy")
    const [maOrderingColumn, setmaOrderingColumn] = useState("deal_date")
    const [maAcquirerOrderingColumn,setMaAcquirerOrderingColumn] = useState("acquirer_name")

    const [PEVCTableHeader, setPEVCTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'total_funding', label: 'Total Funding (US$ M)' },
            { accessor: 'latest_round', label: 'Round' },
            { accessor: 'date', label: 'Deal Date' },
            // { accessor: 'latest_deal_type', label: 'Deal Type' },
            { accessor: 'latest_deal_amount', label: 'Amount (US$ M)' },
            { accessor: 'city_sort', label: 'City' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCDealsTableHeader, setPEVCDealsTableHeader] = useState([
        {accessor: 'company_name', label: 'Company'},
        {accessor: 'sector', label:'Sector'},
        {accessor: 'investor_name_sort', label: 'Investor'},
        {accessor: 'date', label: 'Deal Date'},
        {accessor: 'exit_status', label: 'Exit Status'},
        { accessor: 'amount', label: 'Amount (US$ M)' },
        { accessor: 'add', label: 'Add' },
    ])

    const [VCTableHeader, setVCTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'total_funding', label: 'Total Funding (US$ M)' },
            { accessor: 'latest_round', label: 'Round' },
            { accessor: 'date', label: 'Deal Date' },
            // { accessor: 'latest_deal_type', label: 'Deal Type' },
            { accessor: 'latest_deal_amount', label: 'Amount (US$ M)' },
            { accessor: 'city_sort', label: 'City' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCExitTableHeader, setPEVCExitTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCExitDealsTableHeader, setPEVCExitDealsTableHeader] = useState([
        {accessor: 'company_name', label: 'Company'},
        {accessor: 'sector', label:'Sector'},
        {accessor: 'investor_name_sort', label: 'Investor'},
        {accessor: 'date', label: 'Deal Date'},
        {accessor: 'exit_status', label: 'Exit Status'},
        { accessor: 'amount', label: 'Amount (US$ M)' },
        { accessor: 'add', label: 'Add' },
    ]);

    const [PEVCExitMATableHeader, setPEVCMAExitTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCExitMADealsTableHeader, setPEVCMAExitDealsTableHeader] = useState(
        [
            { accessor: 'company_name', label: 'Company' },
            { accessor: 'sector', label: 'Sector' },
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCExitPMTableHeader, setPEVCPMExitTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCExitPMDealsTableHeader, setPEVCPMExitDealsTableHeader] = useState(
        [
            { accessor: 'company_name', label: 'Company' },
            { accessor: 'sector', label: 'Sector' },
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [VCExitMATableHeader, setVCExitMATableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [VCExitMADealsTableHeader, setVCExitMADealsTableHeader] = useState(
        [
            { accessor: 'company_name', label: 'Company' },
            { accessor: 'sector', label: 'Sector' },
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [VCExitPMTableHeader, setVCExitPMTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    
    const [VCExitPMDealsTableHeader, setVCExitPMDealsTableHeader] = useState(
        [
            { accessor: 'company_name', label: 'Company' },
            { accessor: 'sector', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'amount', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCBackedIposTableHeader, setPEVCBackedIposTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'ipo_size', label: 'IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCBackedIposDealsTableHeader, setPEVCBackedIposDealsTableHeader] = useState(
        [
            { accessor: 'company_name', label: 'Company' },
            { accessor: 'sector', label: 'Sector' },
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'ipo_size', label: 'IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [VCBackedIposTableHeader, setVCBackedIposTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'ipo_size', label: 'IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AngelInvestmentsTableHeader, setAngelInvestmentsTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Investee' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [IncubationTableHeader, setIncubationTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Incubatee' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'incubator_sort', label: 'Incubator' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    // Investor Section

    const [InvestorPEVCTableHeader, setInvestorPEVCTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'portfolio_companies', label: 'Portfolio (Cos / Deals)' },
            { accessor: 'top_portfolio_companies_sort', label: 'Top portfolio cos based on investment size' },
            { accessor: 'funds_count', label: 'No. of Funds' },
            { accessor: 'total_invested', label: 'Total invested (US$ M)' },
            { accessor: 'add', label: 'Add' }

        ]
    );
    const [InvestorVCTableHeader, setInvestorVCTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'portfolio_companies', label: 'Portfolio (Cos / Deals)' },
            { accessor: 'top_portfolio_companies_sort', label: 'Top portfolio cos based on investment size' },
            { accessor: 'funds_count', label: 'No. of Funds' },
            { accessor: 'total_invested', label: 'Total invested (US$ M)' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorPEVCExitTableHeader, setInvestorPEVCExitTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorPEVCExitMATableHeader, setInvestorPEVCExitMATableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorPEVCExitPMTableHeader, setInvestorPEVCExitPMTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorVCExitMATableHeader, setInvestorVCExitMATableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );

    const [InvestorVCExitPMTableHeader, setInvestorVCExitPMTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorPEVCBackedIposTableHeader, setInvestorPEVCBackedIposTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'ipos', label: 'IPOs' },
            { accessor: 'latest_ipo', label: 'IPO' },
            { accessor: 'latest_ipo_size', label: 'IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorVCBackedIpos, setInvestorVCBackedIpos] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'ipos', label: 'IPOs' },
            { accessor: 'latest_ipo', label: 'IPO' },
            { accessor: 'latest_ipo_size', label: ' IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' }
        ]
    );

    const [InvestorAngelInvestmentsTableHeader, setInvestorAngelInvestmentsTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'portfolio', label: 'Portfolio (Cos / Deals)' },
            { accessor: 'latest_investments_sort', label: 'Investments' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorIncubationTableHeader, setInvestorIncubationTableHeader] = useState(
        [
            { accessor: 'Incubator_sort', label: 'Incubator' },
            { accessor: 'Incubatees', label: 'Incubatees' },
            { accessor: 'latest_incubated', label: 'Recently Incubated/Graduated/Assisted' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [AdvisorPEVCTableHeader, setAdvisorPEVCTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );
    const [AdvisorVCTableHeader, setAdvisorVCTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AdvisorPEVCExitMATableHeader, setAdvisorPEVCMAExitTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AdvisorPEVCExitPMTableHeader, setAdvisorPEVCPMExitTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AdvisorVCExitMATableHeader, setAdvisorVCExitMATableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AdvisorVCExitPMTableHeader, setAdvisorVCExitPMTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [InvestorLimitedPartner, setInvestorLimitedPartner] = useState(
        [
            { accessor: 'lp_name_sort', label: 'Institution Name' },
            { accessor: 'TypeOfInstitution', label: 'Type' },
            { accessor: 'investor_names_sort', label: 'Limited Partners in' },
            { accessor: 'add', label: 'Add' },

        ]
    );

    const [NewInvestor, setNewInvestor] = useState(
        [
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            // { accessor: 'add', label: 'Add' },

        ]
    );

    const [ActiveInvestor, setActiveInvestor] = useState(
        [
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            { accessor: 'newPCos', label: "New Portfolio Cos" },
            // { accessor: 'add', label: 'Add' },
        ]
    );

    const [AngelActiveInvestor, setAngelActiveInvestor] = useState(
        [
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            // { accessor: 'newPCos', label: "New Portfolio Cos" },
            // { accessor: 'add', label: 'Add' },
        ]
    );

   const  IncubatorNewInvestor=[
            { accessor: 'investor', label: 'Incubator' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            // { accessor: 'add', label: 'Add' },

        ]

    const IncubatorActiveInvestor=[
            { accessor: 'investor', label: 'Incubator' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            // { accessor: 'newPCos', label: "New Portfolio Cos" },
            // { accessor: 'add', label: 'Add' },
        ];

    const [cfsTableHeader, setCfsTableHeader] = useState(
        [
            { accessor: 'SCompanyName', label: 'Company' },
            { accessor: 'OptnlIncome', label: 'Revenue' },
            { accessor: 'EBITDA', label: 'EBITDA' },
            { accessor: 'PAT', label: 'PAT' },
            { accessor: 'fy', label: 'Latest FY' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [iocTableHeader, setIocTableHeader] = useState(
        [
            { accessor: 'company', label: 'Company' },
            { accessor: 'holdername', label: 'Latest Charge Holders Name' },
        ]
    );

    const [PERETableHeadder,setPERETableHeadder]=useState([
        { accessor: 'company', label: 'Company'},
      { accessor: 'city', label: 'City'},
      { accessor: 'investor_sort ', label: 'Investor' },
      { accessor: 'deal_date', label: 'Deal Date' },
      { accessor: 'exit_status', label: 'Exit Status' },
      { accessor: 'amount_m', label: 'Amount (US$ M)' },
      { accessor: 'add', label: 'Add' },

    ])
    const [PEReExitTableHeader,setPEReExitTableHeader]=useState([
        { accessor: 'company', label: 'Company'},
      { accessor: 'city', label: 'City'},
      { accessor: 'investor_sort', label: 'Investor' },
      { accessor: 'deal_date', label: 'Deal Date' },
      { accessor: 'exit_status', label: 'Exit Status' },
      { accessor: 'deal_amount', label: 'Amount (US$ M)' },
      { accessor: 'add', label: 'Add' },

    ])
    const [PEREBackedIposHeader,setPEREBackedIposHeader]=useState([
        { accessor: 'company', label: 'Company'},
      { accessor: 'city', label: 'City'},
    //   { accessor: 'investor_sort', label: 'Investor' },
      { accessor: 'sector', label: 'Sector' },
      { accessor: 'ipo_date', label: 'Deal Date' },
      { accessor: ' ipo_size_m ', label: 'Deal Size (US$ M)' },
      { accessor: 'exit_status', label: 'Exit Status' },
      { accessor: 'add', label: 'Add' },

    ])
    const [OtherMATableHeader,setOtherMATableHeader]=useState([
        { accessor: 'company', label: 'Company'},
      { accessor: 'city', label: 'City'},
      { accessor: 'acquirer_name', label: 'Acquirer' },
      { accessor: 'deal_date', label: 'Deal Date' },
      { accessor: 'amount', label: 'Amount (US$ M)' },

    //   { accessor: 'exit_status', label: 'Exit Status' },
      { accessor: 'add', label: 'Add' },

    ])

    const [PEREAdvisorTableHeadder,setPEREAdvisorTableHeadder]=useState([
        { accessor: 'advisor_name_sort', label: 'Advisor' },
        { accessor: 'to_company_investments', label: 'To Company (Investments)' },
        { accessor: 'to_company_exits', label: 'To Company (Exits)' },
        { accessor: 'to_investors', label: 'To Investors' },
        { accessor: 'to_acquirers', label: 'To Acquirers' },
        { accessor: 'add', label: 'Add' },

    ])

    

    const [PEReAdvisorExitTableHeader,setPEReAdvisorExitTableHeader]=useState([
        { accessor: 'advisor_name_sort', label: 'Advisor' },
        { accessor: 'to_company_investments', label: 'To Company (Investments)' },
        { accessor: 'to_company_exits', label: 'To Company (Exits)' },
        { accessor: 'to_investors', label: 'To Investors' },
        { accessor: 'to_acquirers', label: 'To Acquirers' },
        { accessor: 'add', label: 'Add' },
    ])

    const [AdvisorOtherMATableHeader,setAdvisorOtherMATableHeader]=useState([
        { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },

    ])
    const [maTableHeader, setMaTableHeader] = useState(
        [  
        { accessor: 'target_company', label: 'Target'},          
        { accessor: 'target_sector', label: 'Sector'},
        { accessor: 'acquirer_name', label: 'Acquirer'},
        { accessor: 'deal_date', label: 'Deal Date'},
        { accessor: 'deal_amount', label: 'Amount (US$ M)' },
        { accessor: 'add', label: 'Add' }
        ]
    );
    const [maAdvisorTableHeader, setMaAdvisorTableHeader] = useState(
        [  
            { accessor: 'advisor_name_sort', label: 'Advisors'},
            { accessor: 'to_company_investments', label: 'To Company (Investments)'},
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'latest_transaction_sort', label: 'Transaction'},
            { accessor: 'latest_deal_date_sort', label: 'Transaction Deal Date'},
            { accessor: 'add', label: 'Add' }
        ]
    );

    const [maAcquirerTableHeader, setMaAcquirerTableHeader] = useState(
        [
            { accessor: 'acquirer_name', label: 'Acquirer' },
            { accessor: 'acquisitions', label: 'Acquisitions' },
            { accessor: 'top_acquistion_names', label: 'Top Acquisitions based on amount' },
            { accessor: 'last_acquired', label: 'Last Acquired' },
            { accessor: 'total_acquistions', label: 'Total Acquisitions (US$ M)' },
            { accessor: 'add', label: 'Add' }
        ]

    )

    const [profilekeys, setProfileKeys] = useState([
        {accessor: "cin", label: "CIN"},
        {accessor: "management_info", label: "Top Management"},
        {accessor: "city", label: "Location"},
        {accessor: "location", label: "Location"},
        {accessor: "email", label: "Email"},
        {accessor: "website", label: "Website"},
        {accessor: "telephone", label: "Telephone"},
        {accessor: "listing_status", label: "Type"},
        {accessor: "board_info", label: "Investor Board Member"},
        {accessor: "year_founded", label: "Year Founded"},
    ])

    const [cfsProfileKeys, setCfsProfileKeys] = useState([
        {accessor: "cin", label: "CIN"},
        {accessor:"previous_name", label: "Formerly Called"},
        {accessor: "contact_name", label: "Contact Name, Designation"},
        {accessor: "transacted_status", label: "Transaction Status"},
        {accessor: "city_data", label: "Location"},
        {accessor: "email", label: "Email"},
        {accessor: "website", label: "Website"},
        {accessor: "phone", label: "Telephone"},
        {accessor: "listing_status", label: "Type"},
        {accessor: "auditor_name", label: "Auditor Name"},
        {accessor: "incorp_year", label: "Year Founded"},
        {accessor: "company_status", label: "Company Status"},
        {accessor: "address", label: "Address"},
    ])

    const USDinM = 1000000;
    const INRinL = 100000;
    const INRinC = 10000000;

    const [plStandardKeys, setPlStandardKeys] = useState([
        { accessor: "operational_income", label: "Operational income", USDinM, INRinC, INRinL },
        { accessor: "other_income", label: "Other income", USDinM, INRinC, INRinL },
        { accessor: "total_income", label: "Total income", USDinM, INRinC, INRinL, style: 'font-bold'},
        {
        accessor: "cost_of_materials_consumed",
        label: "Cost of materials consumed",
         USDinM, INRinC, INRinL
        },
        {
        accessor: "purchases_of_stock_in_trade",
        label: "Purchases of stock-in-trade", USDinM, INRinC, INRinL
        },
        { accessor: "changes_in_inventories", label: "Changes in inventories", USDinM, INRinC, INRinL },
        { accessor: "employee_benefit_expense", label: "Employee benefit expense", USDinM, INRinC, INRinL },
        { accessor: "csr_expenditure", label: "CSR expenditure", USDinM, INRinC, INRinL },
        { accessor: "other_expenses", label: "Other expenses", USDinM, INRinC, INRinL },
        {
        accessor: "operational_admin_other_expenses",
        label: "Operational, Admin & Other expenses", USDinM, INRinC, INRinL
        },
        { accessor: "operating_profit", label: "Operating profit", USDinM, INRinC, INRinL },
        { accessor: "ebitda", label: "EBITDA", USDinM, INRinC, INRinL, style: 'font-bold'},
        { accessor: "interest", label: "Interest", USDinM, INRinC, INRinL },
        { accessor: "ebdt", label: "EBDT", USDinM, INRinC, INRinL },
        { accessor: "depreciation", label: "Depreciation", USDinM, INRinC, INRinL },
        {
        accessor: "ebt_before_exceptional_items",
        label: "EBT before exceptional items", USDinM, INRinC, INRinL
        },
        {
        accessor: "prior_period_exceptional_extra_ordinary_items",
        label: "Prior period/Exceptional/Extra ordinary items", USDinM, INRinC, INRinL
        },
        { accessor: "ebt", label: "EBT", USDinM, INRinC, INRinL },
        { accessor: "current_tax", label: "Current tax", USDinM, INRinC, INRinL },
        { accessor: "deferred_tax", label: "Deferred tax", USDinM, INRinC, INRinL },
        { accessor: "tax", label: "Tax", USDinM, INRinC, INRinL },
        { accessor: "pat", label: "PAT", USDinM, INRinC, INRinL, style: 'font-bold'},
        {
        accessor: "pat_of_minority_interest",
        label: "Profit(loss) of minority interest", USDinM, INRinC, INRinL
        },
        { accessor: "total_pat", label: "Total PAT", USDinM, INRinC, INRinL },
        { accessor: "", label: "EPS", style: 'font-bold' },
        { accessor: "basic_in_inr", label: "Basic earnings per share", "USDinM":1, "INRinC": 1, "INRinL":1 },
        { accessor: "diluted_in_inr", label: "Diluted earnings per share", "USDinM":1, "INRinC":1, "INRinL":1 },
        {
        accessor: "foreign_exchange_earning_and_outgo",
        label: "Foreign exchange earning and outgo", USDinM, INRinC, INRinL, 
        style: 'font-bold'
        },
        {
        accessor: "earning_in_foreign_exchange",
        label: "Earnings in foreign exchange", USDinM, INRinC, INRinL
        },
        {
        accessor: "outgo_in_foreign_exchange",
        label: "Outgo in foreign exchange", USDinM, INRinC, INRinL
        },
    ]);

    const [nonDetailedPLKeys, setNonDetailedPL] = useState([
        { accessor: "total_income", label: "Revenue", USDinM, INRinC, INRinL},
        { accessor: "ebitda", label: "EBITDA", USDinM, INRinC, INRinL},
        { accessor: "pat", label: "PAT", USDinM, INRinC, INRinL},
    ]);

    const [balanceSheetKeys, setBalanceSheetKeys] = useState([
        
                {accessor:"", label: "Shareholders' funds [Abstract]", style: 'font-bold'},
                {accessor:"share_capital", label: "Share capital", USDinM, INRinC, INRinL},
                {accessor:"reserves_and_surplus", label: "Reserves and surplus", USDinM, INRinC, INRinL},
                {accessor:"total_shareholders_funds", label: "Total shareholders funds", USDinM, INRinC, INRinL, style: 'font-bold'},
                {accessor:"share_application_money_pending_allotment", label: "Share application money pending allotment", USDinM, INRinC, INRinL},
                {accessor:"minority_interest", label: "Minority interest", USDinM, INRinC, INRinL},
                {accessor:"non_current_liabilities_abstract", label: "Non-current liabilities [Abstract]", USDinM, INRinC, INRinL, style: 'font-bold'},
                {accessor:"long_term_borrowings", label: "Long term borrowings", USDinM, INRinC, INRinL},
                {accessor:"deferred_tax_liabilities_net", label: "Deferred tax liabilities (net)", USDinM, INRinC, INRinL},
                {accessor:"other_long_term_liabilities", label: "Other long term liabilities", USDinM, INRinC, INRinL},
                {accessor:"long_term_provisions", label: "Long term provisions", USDinM, INRinC, INRinL},
                {accessor:"total_non_current_liabilities", label: "Total non-current liabilities", USDinM, INRinC, INRinL, style: 'font-bold'},
                {accessor:"", label: "Current liabilities [Abstract]", style: 'font-bold'},
                {accessor:"short_term_borrowings", label: "Short term borrowings", USDinM, INRinC, INRinL},
                {accessor:"trade_payables", label: "Trade payables", USDinM, INRinC, INRinL},
                {accessor:"other_current_liabilities", label: "Other current liabilites", USDinM, INRinC, INRinL},
                {accessor:"short_term_provisions", label: "Short term provisions", USDinM, INRinC, INRinL},
                {accessor:"total_current_liabilities", label: "Total current liabilities", USDinM, INRinC, INRinL, style:'font-bold'},
                {accessor:"total_equity_and_liabilities", label: "Total equity and liabilites", USDinM, INRinC, INRinL, style:'font-bold'},
                {accessor:"assets_abstract", label: "Assets [Abstract]", USDinM, INRinC, INRinL, style:'font-bold'},
                {accessor:"non_current_assets_abstract", label: "Non current assets [Abstract]", USDinM, INRinC, INRinL, style:'font-bold'},
                {accessor:"fixed_assets_abstract", label: "Fixed assets [Abstract]", USDinM, INRinC, INRinL, style:'font-bold'},
                {accessor:"tangible_assets", label: "Tangible assets", USDinM, INRinC, INRinL},
                {accessor:"intangible_assets", label: "Intangible assets", USDinM, INRinC, INRinL},
                {accessor:"total_fixed_assets", label: "Total fixed assets", USDinM, INRinC, INRinL},
                {accessor:"non_current_investments", label: "Non current investments", USDinM, INRinC, INRinL},
                {accessor:"deferred_tax_assets_net", label: "Deferred tax assets (net)", USDinM, INRinC, INRinL},
                {accessor:"long_term_loans_and_advances", label: "Long term loans and advances", USDinM, INRinC, INRinL},
                {accessor:"other_non_current_assets", label: "Other non current assets", USDinM, INRinC, INRinL},
                {accessor:"total_non_current_assets", label: "Total non-current assets", USDinM, INRinC, INRinL, style:'font-bold'},
                {accessor:"current_assets_abstract", label: "Current assets [Abstract]", USDinM, INRinC, INRinL, style:'font-bold'},
                {accessor:"current_investments", label: "Current investments", USDinM, INRinC, INRinL},
                {accessor:"inventories", label: "Inventories", USDinM, INRinC, INRinL},
                {accessor:"trade_receivables", label: "Trade receivables", USDinM, INRinC, INRinL},
                {accessor:"cash_and_bank_balances", label: "Cash and bank balances", USDinM, INRinC, INRinL},
                {accessor:"short_term_loans_and_advances", label: "Short-term loans and advances", USDinM, INRinC, INRinL},
                {accessor:"other_current_assets", label: "Other current assets", USDinM, INRinC, INRinL},
                {accessor:"total_current_assets", label: "Total current assets", USDinM, INRinC, INRinL, style:'font-bold'},
                {accessor:"total_assets", label: "Total assets", USDinM, INRinC, INRinL, style:'font-bold'},
    ]);

    const [nonDetailedBSKeys, setNonDetailedBSKeys] = useState([
        {accessor:"total_shareholders_funds", label: "Total shareholders funds", USDinM, INRinC, INRinL},
        {accessor:"total_non_current_liabilities", label: "Non current liabilities", USDinM, INRinC, INRinL},
        {accessor:"total_current_liabilities", label: "Current liabilities", USDinM, INRinC, INRinL},
        {accessor:"total_equity_and_liabilities", label: "Total equity and liabilites", USDinM, INRinC, INRinL, style:'font-bold'},
        {accessor:"total_non_current_assets", label: "Non current assets", USDinM, INRinC, INRinL},
        {accessor:"total_current_assets", label: "Current assets", USDinM, INRinC, INRinL},
        {accessor:"total_assets", label: "Total assets", USDinM, INRinC, INRinL, style:'font-bold'},
    ]);

    const [cashFlowKeys, setCashFlowKeys] = useState([
                    {accessor:"", label: "Statement of cash flows", style: 'font-bold'},
                    {accessor: "net_pl_before", label : "Net profit/loss before extraordinary items and tax", USDinM, INRinC, INRinL, style: 'font-bold'},
                    {accessor: "cashflow_from_operation", label : "Net cashFlow from operating activities", USDinM, INRinC, INRinL},
                    {accessor: "cashflow_from_investment", label : "Net cash used in investing activities", USDinM, INRinC, INRinL},
                    {accessor: "cashflow_from_finance", label : "Net cash used from financing activities", USDinM, INRinC, INRinL},
                    {accessor: "net_inc_dec_cash", label : "Net inc/dec in cash and cash equivalents", USDinM, INRinC, INRinL, style: 'font-bold'},
                    {accessor: "end_year_cash_equivalent", label : "Cash and cash equivalents end of year", USDinM, INRinC, INRinL},
                ]);
                
    const [nonDetailedCFKeys, setNonDetailedCFKeys] = useState([
        {accessor: "cashflow_from_operation", label : "Operating activities", USDinM, INRinC, INRinL},
        {accessor: "cashflow_from_investment", label : "Investing activities", USDinM, INRinC, INRinL},
        {accessor: "cashflow_from_finance", label : "Financing activities", USDinM, INRinC, INRinL}
    ]);

    const [ratioKeys, setRatioKeys] = useState([
        {accessor: 'current_ratio', label: "Current ratio", tooltip:"Total current assets / Total current liabilities"},
        {accessor: 'quick_ratio', label: "Quick ratio", tooltip:"(Total current assets - Inventories) / Total current liabilities"},
        {accessor: 'debt_equity_ratio', label: "Debt equity ratio", tooltip:"(Long-term borrowings + Short-term borrowings) / Total shareholders' funds"},
        {accessor: 'roe', label: "RoE", tooltip:"PAT / Total shareholder's funds"},
        {accessor: 'roa', label: "RoA", tooltip:"PAT / Total assets"},
        {accessor: 'roce', label: "ROCE", tooltip:"EBIT / (Total shareholders funds + Total non-current liabilities)"},
        // {accessor: 'asset_turnover_ratio', label: "Asset turnover ratio", tooltip: "(Total income / ((Total assets of prev year + Total assets of current year) / 2)"},
        {accessor: 'operating_margin', label: "Operating Margin", tooltip:"EBIT / Total income"},
        {accessor: 'ebitda_margin', label: "EBITDA Margin", tooltip:"EBITDA / Total income"},
        {accessor: 'pat_margin', label: "PAT Margin", tooltip:"PAT / Total income"},
        {accessor: 'contribution_margin', label: "Contribution Margin", tooltip:"(Total income - Cost of materials consumed - Purchases of stock in trade - Changes in inventories) / Total income"},
        
    ])
    
    const [nonDetailedRatioKeys, setNonDetailedRatioKeys] = useState([
        {accessor: "current_ratio", label:"Current ratio", tooltip:"Total current assets / Total current liabilities"},
        {accessor: 'debt_equity_ratio', label: "Debt equity ratio", tooltip:"(Long-term borrowings + Short-term borrowings)/Total shareholders' funds"},
        {accessor: 'asset_turnover_ratio', label: "Asset turnover ratio", tooltip: "(Total income/((Total assets of prev year + Total assets of current year)/2))"},
    ])

    const [investorProfileKeys, setInvestorProfileKeys] = useState([
        {accessor: "management_info", label: "Management"},
        {accessor: "email", label: "Email"},
        {accessor: "telephone", label: "Telephone"},
        {accessor: "website", label: "Website"},
        {accessor: "address", label: "Address"},
        {accessor: "city", label: "City"},
        {accessor: "country", label: "Country (Headquarters)"},
        {accessor: "zip", label: "Zipcode"},
        {accessor: "other_location", label: "Other Location"},
        {accessor: "year_founded", label: "In India Since"},
        {accessor: "invested_stages", label: "Stage of Funding"},
        {accessor: "no_of_funds", label: "Number of Funds"},
        {accessor: "assets_under_mgmt", label: "Assets Under Management(US$ M)"},
        {accessor: "already_invested", label: "Already Invested (US$ M)"},
        {accessor: "exit_amount", label: "Already Exited (US$ M)"},
        {accessor: "dry_powder", label: "Dry Powder (US$ M)"},
        {accessor: "limited_partners_fks", label: "Limited Partners"},
    ])

    const [angelInvestorProfileKeys] = useState([
        {accessor: "management_info", label: "Management"},
        {accessor: "email", label: "Email"},
        {accessor: "telephone", label: "Telephone"},
        {accessor: "website", label: "Website"},
        {accessor: "address", label: "Address"},
        {accessor: "city", label: "City"},
        {accessor: "zip", label: "Zipcode"},
        {accessor: "linkedin", label: "LinkedIn"}
    ])

    const [incubationProfileKeys, setIncubationProfileKeys] = useState([
        {accessor: "management", label: "Management"},
        {accessor: "email", label: "Email"},
        {accessor: "website", label: "Website"},
        {accessor: "address", label: "Address"},
        {accessor: "city", label: "City"},
        {accessor: "zipcode", label: "Zipcode"},
        {accessor: "linkedin", label: "LinkedIn"}
    ])

    const headerMenu = {
        PEVCTableHeader, PEVCDealsTableHeader, VCTableHeader, PEVCExitTableHeader,PEVCExitDealsTableHeader, PEVCExitMATableHeader,PEVCExitMADealsTableHeader, PEVCExitPMTableHeader,PEVCExitPMDealsTableHeader, VCExitMATableHeader,VCExitPMDealsTableHeader , VCExitPMTableHeader, PEVCBackedIposTableHeader,PEVCBackedIposDealsTableHeader, VCBackedIposTableHeader, AngelInvestmentsTableHeader, IncubationTableHeader,
        InvestorPEVCTableHeader,VCExitMADealsTableHeader, InvestorVCTableHeader, InvestorPEVCExitTableHeader, InvestorPEVCExitMATableHeader, InvestorPEVCExitPMTableHeader, InvestorVCExitMATableHeader, InvestorVCExitPMTableHeader, InvestorPEVCBackedIposTableHeader, InvestorVCBackedIpos, InvestorAngelInvestmentsTableHeader, InvestorIncubationTableHeader, InvestorLimitedPartner,
        AdvisorPEVCTableHeader, AdvisorVCTableHeader, AdvisorPEVCExitMATableHeader, AdvisorPEVCExitPMTableHeader, AdvisorVCExitMATableHeader, AdvisorVCExitPMTableHeader,
        selectedPage, setSelectedPage, orderBy, setOrderBy, orderingColumn, setOrderingColumn, investororderingColumn, setInvestorOrderingColumn, advisororderingColumn, setAdvisorOrderingColumn, incubatorOrderingColumn, setIncubatorOrderingColumn, cfsOrderingColumn, setCfsOrderingColumn,
        orderByInvestor, setOrderByInvestor, orderByAdvisor, setOrderByAdvisor, orderByTAdvisor, setOrderByTAdvisor, orderBya, setOrderBya,
        OrderByVCTableHeader, OrderByPEVCExitMATableHeader,
        OrderByPEVCExitPMTableHeader, OrderByVCExitMATableHeader, OrderByVCExitPMTableHeader, OrderByPEVCBackedIposTableHeader,
        OrderByVCBackedIposTableHeader, OrderByAngelInvestmentsTableHeader, OrderByIncubationTableHeader,
        limitedPartnerderingColumn, setLimitedPartnerderingColumn, orderByLP, setOrderByLP,
        OrderByInvestorVCTableHeader, orderByInvestorExits, OrderByInvestorPEVCExitMATableHeader,
        OrderByInvestorPEVCExitPMTableHeader, OrderByInvestorVCExitMATableHeader,
        OrderByInvestorVCExitPMTableHeader, OrderByInvestorPEVCBackedIposTableHeader,
        OrderByInvestorVCBackedIposTableHeader, OrderByInvestorAngelInvestmentsTableHeader,
        OrderByInvestorIncubationTableHeader, cfsTableHeader,iocTableHeader, setIocTableHeader, NewInvestor, setNewInvestor, ActiveInvestor, setActiveInvestor,AngelActiveInvestor, setAngelActiveInvestor,
        IncubatorNewInvestor,IncubatorActiveInvestor,
        OtherMATableHeader,setOtherMATableHeader,PEREBackedIposHeader,setPEREBackedIposHeader,
        PEReExitTableHeader,setPEReExitTableHeader,PERETableHeadder,setPERETableHeadder,PEREAdvisorTableHeadder,setPEREAdvisorTableHeadder,
        PEReAdvisorExitTableHeader,setPEReAdvisorExitTableHeader,AdvisorOtherMATableHeader,setAdvisorOtherMATableHeader,
        maTableHeader, setMaTableHeader,maOrderingColumn, setmaOrderingColumn,maAdvisorTableHeader, setMaAdvisorTableHeader,
        maAcquirerTableHeader, setMaAcquirerTableHeader,maAcquirerOrderingColumn,setMaAcquirerOrderingColumn,reOrderingColumn, setreOrderingColumn,reIpoOrderingColumn, setIporeOrderingColumn,exitOrderingColumn, setexitOrderingColumn,maAdvisororderingColumn, setmaAdvisorOrderingColumn,
        plStandardKeys, balanceSheetKeys, cashFlowKeys, ratioKeys, cfsProfileKeys, profilekeys, setProfileKeys, nonDetailedPLKeys, nonDetailedBSKeys, nonDetailedCFKeys, nonDetailedRatioKeys, investorProfileKeys, angelInvestorProfileKeys, incubationProfileKeys
    }

    return (
        <TableHeaderContext.Provider value={headerMenu}>
            {children}
        </TableHeaderContext.Provider>
    )
}

export default TableHeaderProvider
