import {React, useState, createContext} from 'react'

export const TableDataContext = createContext('');

/**
 * The `TableDataProvider` component is a wrapper that provides state and functions related to table
 * data to its children components.
 * @returns The TableDataProvider component is being returned.
 */
const TableDataProvider = ({children}) => {
    const [advancedSearchCurrentPage, setAdvancedSearchCurrentPage] = useState(1)
    const [rows, setRows] = useState([]);
    const [dealRows, setDealRows] = useState([]);
    const [tableCompanyId, setTableCompanyId] = useState([]);
    const [allDealIds, setAllDealIds] = useState([]);
    const [tableInvestorId, setTableInvestorId] = useState([]); 
    const [tableActiveInvestorId, setTableActiveInvestorId] = useState([])
    const [tableNewInvestorId, setTableNewInvestorId] = useState([])
    const [currentActiveInvestorId,setCurrentActiveInvestorId] = useState([])
    const [tableAcquirerId,setTableAcquirerId] =useState([])
    const [currentPage, setCurrentPage] = useState(1)       
    const [tableAdvisorId, setTableAdvisorId] = useState([]);
    const [tableLimitedPartnersId, setTableLimitedPartnersId] = useState([]);
    const [companiesid, setCompaniesId] = useState([])
    const [data, setData] = useState({})
    const [director, setDirectors] = useState({})
    const [updatedOn, setUpdatedon] = useState("")
    const [isLoad, setIsLoad] = useState(false)
    const [isDataLoad, setIsDataLoad] = useState(false)
    const [currentFilter, setCurrentFilter] = useState();
    const [activeTabDeal, setActiveTabDeal] = useState("")
    const [activeTabs, setActiveTabs] = useState("")
    const [dealIdTab, setDealIdTab] = useState("")
    const [totalNoOfCompanies, setTotalNoOfCompanies] =useState(0)
    const [currency, setCurrency] =useState('INR')
    const [mainMenu,setMainMenu]=useState(1)
    const [globalSearchResult, setGlobalSearchResult] = useState([]);
    const [forExRate, setForExRate] = useState();
    const [allId,setAllId] = useState([]);
    const [forExMonthlyRate, setForExMonthlyRate] = useState();
    const tableData = { activeTabs, setActiveTabs ,dealIdTab, setDealIdTab, rows, setRows, data, setData, director, setDirectors, updatedOn, setUpdatedon, isLoad, setIsLoad, isDataLoad, setIsDataLoad,companiesid, setCompaniesId, tableCompanyId, setTableCompanyId,tableAcquirerId,setTableAcquirerId,
        tableInvestorId, setTableInvestorId, tableAdvisorId,setTableAdvisorId, tableLimitedPartnersId, setTableLimitedPartnersId, currentFilter, setCurrentFilter, activeTabDeal, setActiveTabDeal, 
        totalNoOfCompanies, setTotalNoOfCompanies, currency, setCurrency, currentPage, setCurrentPage,mainMenu,setMainMenu, advancedSearchCurrentPage, setAdvancedSearchCurrentPage, tableActiveInvestorId, setTableActiveInvestorId,tableNewInvestorId, setTableNewInvestorId,currentActiveInvestorId,setCurrentActiveInvestorId,
        allDealIds, setAllDealIds, dealRows, setDealRows,allId,setAllId, globalSearchResult, setGlobalSearchResult, forExRate, setForExRate, forExMonthlyRate, setForExMonthlyRate
    }

    return (
        <TableDataContext.Provider value={tableData}>
            {children}
        </TableDataContext.Provider>
    )
}

export default TableDataProvider
