import { useContext, useState, useEffect } from "react";
import BasedFilterWrapper from './BasedFilterWrapper';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ChargeBasedSearch from "../RefineSearchComponents/ChargeBasedSearch";
import StateFilter from "../RefineSearchComponents/StateFilter";
import CityFilter from "../RefineSearchComponents/CityFilter";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import useScrollIntoView from './useScrollIntoView';
import { BsArrowRightSquareFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";


/**
 * The ChargesBasedFilter component is a filter component that allows users to filter data based on
 * charge-related criteria such as charge holder, company name, charge date, charge amount, charge
 * state, and charge city.
 * @returns The component is returning a JSX element.
 */
const ChargesBasedFilter = ({ open, accordionClick, customPathname, label = "CHARGE BASED",
  ChargeStateOptions, setChargeStateOptions,
  ChargeCityOptions, setChargeCityOptions,
  ChargeBasedSelected, setChargeBasedSelected,
  minChargeAmount, setMinChargeAmount,
  maxChargeAmount, setMaxChargeAmount,
  startDate, setStartDate,
  endDate, setEndDate,
  radioValue, setRadioValue,
  onChargeStateChange, menu
}) => {

  const [select, setSelected] = useState(0);
  const { refineSearchFilters, open : btnOpen, setOpen, isMobile, setIsMobile } = useContext(PeFilterContext);
  const listItemRef = useScrollIntoView(open);

/**
 * The function `innerAccordionClick` updates the selected value based on the input value.
 * @param val - The `val` parameter is a value that is passed to the `innerAccordionClick` function. It
 * is used to determine whether the accordion should be selected or deselected.
 */
  const innerAccordionClick = (val) => {
    if (val === select) {
      setSelected(0);
    } else {
      setSelected(val);
    }
  };

  useEffect(() => {
    const selectedChargeAmt = refineSearchFilters[customPathname]?.chargeAmount?.at(0)?.value;
    const selectedChargeDate = refineSearchFilters[customPathname]?.chargeDate?.at(0)?.value;
    const selectedChargeCompanyName = refineSearchFilters[customPathname]?.chargeCompanies;
    const selectedChargeHolderName = refineSearchFilters[customPathname]?.chargeHolders;

    setMaxChargeAmount(selectedChargeAmt?.to_amt ?? "");
    setMinChargeAmount(selectedChargeAmt?.from_amt ?? "");

    setRadioValue(selectedChargeCompanyName != null && selectedChargeCompanyName?.length !== 0
      ? 'company'
      : selectedChargeHolderName != null && selectedChargeHolderName?.length !== 0
        ? "charge_holder"
        : null)


    setStartDate(selectedChargeDate?.from_date ? new Date(selectedChargeDate?.from_date) : null)
    setEndDate(selectedChargeDate?.to_date ? new Date(selectedChargeDate?.to_date) : null)

    setChargeBasedSelected(selectedChargeCompanyName != null && selectedChargeCompanyName?.length !== 0 ? selectedChargeCompanyName : selectedChargeHolderName)

  }, [customPathname, refineSearchFilters, setChargeBasedSelected, setEndDate, setMaxChargeAmount, setMinChargeAmount, setStartDate, setRadioValue])


/**
 * The function `handleRadioChange` updates multiple state variables based on the selected radio button
 * value.
 * @param value - The value parameter represents the new value that is selected for the radio button.
 */
  const handleRadioChange = (value) => {
    setRadioValue(prev => prev === value ? null : value);
    setStartDate(null)
    setEndDate(null)
    setChargeBasedSelected([])
    setMinChargeAmount("")
    setMaxChargeAmount("")
  };

/**
 * The function `isChargeAmountChange` updates the minimum or maximum charge amount based on the
 * provided value and type.
 * @param value - The value parameter represents the new charge amount that needs to be set.
 * @param type - The `type` parameter is a string that specifies the type of charge amount to be
 * changed. It can have two possible values: 'min' or 'max'.
 */
  const isChargeAmountChange = (value, type) => {

    if (type == 'min') {
      setMinChargeAmount(value);
    }
    if (type == 'max') {
      setMaxChargeAmount(value);
    }

  }
  useEffect(()=>{
    if (window.innerWidth < 800) {
        setOpen(true);
        setIsMobile(true);
    }    },[])

  return (

    <div className="border-b-[3px] border-[#E8E6E1] space-y-3">
      <li className={
        open
          ? "bg-white relative  py-1"
          : "relative  py-1"
      }
      ref={listItemRef}
      >
      {menu === "ioc" ? <></> : <BasedFilterWrapper
          isSelect={true}
          label={label}
          onClick={accordionClick}
          open={open}
          btnOpen={btnOpen}
          menu={menu}
        />}      
        <div
          className="overflow-hidden scroll-mt-24 transition-all max-h-0 duration-700 no-scrollbar  overflow-y-auto px-3"
          style={{ maxHeight: open ? "18rem" : menu == "ioc" ? "100%" : "0rem" }}
        >
          {/* {open === true ? */}
            <>
              <div className="flex items-center justify-between py-1 mb-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="charge_holder"
                    className="cursor-pointer mr-1"
                    value="charge_holder"
                    checked={radioValue === "charge_holder"}
                    onClick={() => handleRadioChange('charge_holder')}
                  />

                  <label htmlFor="charge_holder" className="text-xs cursor-pointer whitespace-nowrap">Charge holder</label>
                </div>
                <div className="flex items-center ">
                  <input
                    id="company"
                    type="radio"
                    value="company"
                    className="cursor-pointer mr-1"
                    checked={radioValue === "company"}
                    onClick={() => handleRadioChange('company')}
                  />
                  <label htmlFor="company" className="text-xs cursor-pointer whitespace-nowrap">Company name</label>
                </div>
              </div>
              {radioValue &&
                <>

                  <div className="bg-white mb-2 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                    <label className=" flex  rounded ">
                      <ChargeBasedSearch
                        ChargeBasedSelected={ChargeBasedSelected}
                        customPathname={customPathname}
                        setChargeBasedSelected={setChargeBasedSelected}
                        category={radioValue}
                        placeholder={radioValue === "company" ? "Company Name" : "Holder Name"}
                      />
                    </label>
                  </div>
                </>
              }
              <div className="text-[#333333] font-sans_book_body text-[13px] leading-[17px] tracking-[-0.3px]">
                <label>Charge Date</label>
                <div className="flex gap-3 w-full py-2">
                  <div className="w-1/2">
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      // placeholderText="Start date"
                      // isClearable={true}
                      fixedHeight
                      maxDate={endDate}
                      dateFormat="yyyy-MM-dd"
                      className="p-1.5 focus:outline-none w-full border border-[#D9BD7F] rounded "
                      hideDaysFromOtherMonths={true}
                    />
                  </div>
                  <div className="w-1/2">
                    <DatePicker
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      minDate={startDate}
                      fixedHeight
                      hideDaysFromOtherMonths={true}
                      className="p-1.5 focus:outline-none w-full border border-[#D9BD7F] rounded"
                    />
                  </div>
                </div>
              </div>

              <div className="text-[#333333] font-sans_book_body text-[13px] leading-[17px] tracking-[-0.3px]">
                <label>Charge Amount (in Rs. Cr)</label>
                <div className="flex justify-between gap-3 w-full py-2">
                  <input type="number" id="minChargeAmount" name="minChargeAmount" min={0} className="border w-2/4 p-1.5 border-[#D9BD7F] rounded focus:outline-none" value={minChargeAmount} onChange={(e) => isChargeAmountChange(e.target.value, 'min')}></input>
                  <input type="number" id="maxChargeAmount" name="maxChargeAmount" min={1} className="border w-2/4 p-1.5 border-[#D9BD7F] rounded focus:outline-none" value={maxChargeAmount} onChange={(e) => isChargeAmountChange(e.target.value, 'max')}></input>
                </div>
              </div>
              <StateFilter
                StateOptions={ChargeStateOptions}
                setStateOptions={setChargeStateOptions}
                customPathname={customPathname}
                onChange={onChargeStateChange}
                filterPathkey="chargeState"
                label="Charge State"
                isCharge="true"
                accordionClick={() => innerAccordionClick(12)}
                open={select === 12}
                innerState={true} />
              <CityFilter
                CityOptions={ChargeCityOptions}
                setCityOptions={setChargeCityOptions}
                customPathname={customPathname}
                filterPathkey="chargeCity"
                label="Charge City"
                isCharge="true"
                accordionClick={() => innerAccordionClick(13)}
                open={select === 13}
                innerCity={true} />
            </>

            {/* <></>} */}

        </div>

      </li>

    </div>
  );
};
export default ChargesBasedFilter;

