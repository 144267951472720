import  CloseButton  from "../../images/CloseBtn.svg";
/**
 * The `FilterTag` function is a React component that renders a label with a title and optional
 * subtitle, and a close button to remove the tag.
 * @returns a JSX element.
 */
function FilterTag({ title, onRemove, subTitle }) {
//    console.log("Vandhutandaaa 22",title)
    return (   
          
        <label
            htmlFor=""
            className="h-7 text-[12px] whitespace-nowrap text-gray-600 px-3 py-0.5 flex rounded-md ring-1 ring-gray-400 items-center hover: tag-animate"
        >
             <div className="group relative inline-block">                                 
                    <p className="text-[9px] text-[#666666]">{title}</p>
                    {
                        subTitle && <p className="text-[10px] text-[#333333]">{subTitle}</p>
                    }
            </div>                     
            <img src={CloseButton} className="h-3 w-3 ml-3 cursor-pointer" onClick={onRemove} key={title} name={title}/>
        </label>             
    )
}

export default FilterTag;