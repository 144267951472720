import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * The `StateFilter` function is a React component that renders a filter for selecting states, with
 * options for selecting all states or individual states.
 * @returns a JSX element.
 */
function StateFilter({ open, accordionClick, StateOptions, setStateOptions, isCharge, customPathname, onChange, filterPathkey = "state", label="State", innerState  }) {
    const { refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);

    useEffect(() => {
        const ids = refineSearchFilters[customPathname]?.[filterPathkey]?.map(value => value.id) ?? [];
        setStateOptions(StateOptions?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
    }, [customPathname, refineSearchFilters,filterPathkey])

    const handleStateChange = ((e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentStates = StateOptions.map((state) =>
                ({ ...state, isChecked: checked })
            );
            setStateOptions(currentStates);
            onChange(currentStates)
        } else {
            let currentStates = StateOptions.map((state) =>
                state.id === (isCharge ? id : parseInt(id)) ? { ...state, isChecked: checked } : state
        );
            setStateOptions(currentStates);
            onChange(currentStates)
        }
    });

    const len = StateOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? `bg-white relative px-${!innerState?'3':'0'} py-1`
                        : `relative px-${!innerState?'3':'0'} py-1`
                }
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={false}
                    label={label}
                    count={len}
                    onClick={accordionClick}
                    open={open}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleStateChange}
                        primaryEntity={StateOptions}
                    /> : <></>}

                </div>

            </li>

        </div>
    )
}

export default StateFilter